import React, { useState } from 'react'
import Navbar from '../components/nav/Navbar'
import Sidebar from '../components/nav/Sidebar'
import "./Company.scss"
import Business from '../components/tables/Business'

const Company = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

            <div className='company'>
                <Business />
            </div>
        </div>
    )
}

export default Company
