import React, { useEffect, useRef, useState } from 'react';
import "./Triagetable.scss";
import DataTable from 'react-data-table-component';
import useFetch from '../../hooks/useFetch';
import { MdOutlineAssignmentReturn, MdOutlineCancel, MdOutlineDownload } from 'react-icons/md';
import CancelTriage from '../actions/CancelTriage';
import { BsSearch, BsSend } from "react-icons/bs";
// import { FaAmbulance, FaWpforms } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Loading from '../loading/Loading';
import Select from 'react-select';
import DatePicker, { registerLocale } from "react-datepicker";
import { es } from 'date-fns/locale/es';
import moment from 'moment';
import { User } from '../../context/Context';
import ReqAmbulance from '../actions/ReqAmbulance';
import CustomDateInput from '../../utilities/CustomDateInput';
import { Socket } from 'socket.io-client';
import useWebSocket from '../../hooks/useWebsocket';
import { PiMicroscope } from "react-icons/pi";
import Button from '../../utilities/Button';
import { FaAmbulance } from 'react-icons/fa';
import AssignMedic from '../actions/AssignMedic';
import { RiMailSendLine } from 'react-icons/ri';
import { IoIosMore } from 'react-icons/io';
import { AiOutlineMinus } from 'react-icons/ai';
registerLocale('es', es);

interface Option {
    value: string,
    label: string;
}

const Triagetable = ({ setUploadAnal }: any) => {
    const [records, setRecords] = useState<any>([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    // const { data: triage, refetch } = useFetch(`${apiUrl}/callcenter/findConsults`);
    const { data: medics } = useFetch(`${apiUrl}/options/findDoctors`);
    const { currentUser } = User();
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const roleId = currentUser?.roleId;
    // const userId = currentUser?.id;
    const [socketConsults, setSocketConsults] = useState<any>(null);
    const socketRef = useRef<Socket | null>(null);
    const token = localStorage.getItem('token')!;
    const socketurl = process.env.REACT_APP_WEBSOCKET!;
    const { registerEvent, emitEvent } = useWebSocket(token, socketurl);

    console.log(records)
    useEffect(() => {
        const handleConsults = (data: any) => {

            const sortedData = data.sort((a: any, b: any) => {
                const numA = parseInt(a.identificator.replace(/[^\d]/g, ''), 10);
                const numB = parseInt(b.identificator.replace(/[^\d]/g, ''), 10);

                return numB - numA;
            });

            const uniqueSortedData = Array.from(
                new Map(sortedData.map((item: any) => [item.identificator, item]))
            ).map(([_, item]) => item);
            setSocketConsults(uniqueSortedData);
        };

        registerEvent('consults', handleConsults);

        const fetchData = async () => {
            emitEvent('consults', { consults: 'CKD4h5J6' });
        };

        fetchData();

        return () => {
            // Limpia el event listener cuando el componente se desmonta
            if (socketRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                socketRef.current.off('consults', handleConsults);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (socketConsults) {
            // if (roleId === 1 || roleId === 6 || roleId === 5) {
            // setStartDate(new Date());
            // setEndDate(new Date());
            // const filteredInvoices = socketConsults.filter((record: any) => {
            //     const invoiceDate = moment(record.triageCreatedAt).format('YYYY-MM-DD');
            //     if (startDate && endDate) {
            //         return invoiceDate >= moment(startDate).format('YYYY-MM-DD') && invoiceDate <= moment(endDate).format('YYYY-MM-DD');
            //     } else {
            //         return null;
            //     }
            // });
            if (currentUser && roleId === 6) {

                const medicsWithSameCompany = medics && medics.filter((medic: any) => medic.companyId === currentUser.data.companyId);

                const medicZones = medicsWithSameCompany && medicsWithSameCompany.map((medic: any) => medic.zoneNames);

                const filteredRecords = socketConsults.filter((record: any) => {
                    if (record.status === 0) {
                        const flattenedMedicZones = medicZones.flat();
                        return flattenedMedicZones.includes(record.hotelZone);
                    } else if (record.status !== 0 && record.doctor_companyId === currentUser.data.companyId) {
                        return true;
                    }
                    return false;
                });
                setRecords(filteredRecords);

            } else {

                setRecords(socketConsults);
            }
            // }
            // if (roleId === 5) setRecords(socketConsults.filter((record: any) => record.createdbyId === userId && moment(record.triageCreatedAt).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConsults]);

    const [cancel, setCancel] = useState(false);
    const [needAmbulance, setNeedAmbulance] = useState(false);
    const [rowData, setRowData] = useState<any>([]);
    const [assignTo, setAssignTo] = useState(false);

    const [selectionComplete, toggleSelectionComplete] = useState(false);
    const capitalizeWords = (str: string) => {
        return str.toLowerCase().replace(/(^\w|\s\w|^[áéíóúÁÉÍÓÚ]|\s[áéíóúÁÉÍÓÚ])/g, (match) => match.toUpperCase());
    } // To ensure that only the first letter of each word is capitalized, including those with tildes

    const getFlagClassByLanguage = (languageCode: string) => {
        switch (languageCode) {
            case 'cs': return 'fi fi-cz'; // Czech Republic
            case 'da': return 'fi fi-dk'; // Denmark
            case 'de': return 'fi fi-de'; // Germany
            case 'en': return 'fi fi-gb'; // Great Britain (English)
            case 'es': return 'fi fi-es'; // Spain
            case 'fr': return 'fi fi-fr'; // France
            case 'it': return 'fi fi-it'; // Italy
            case 'nb': return 'fi fi-no'; // Norway
            default: return 'fi fi-gl'; // Default (Globe)
        }
    };

    const [showMoreMap, setShowMoreMap] = useState<{ [key: string]: boolean }>({});
    const toggleShowMore = (guid: string) => {
        setShowMoreMap(prev => ({
            ...prev,
            [guid]: !prev[guid]
        }));
    };

    const columns = [
        {
            name: "ID",
            cell: (row: any) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {(row.ambulanceRequest === 1 && row.status !== 2) || row.ambulanceRequest === 2 ? (
                        <FaAmbulance
                            style={{
                                color: (row.ambulanceRequest === 1 && row.status !== 2) ? '#FF3D32' : (row.ambulanceRequest === 2) ? '#659D17' : 'lightgrey',
                                marginRight: '8px'
                            }}
                            title={row.ambulanceRequest === 2 ? "Ya solicitada" : "Pedir Ambulancia"}
                        />
                    ) : null}
                    {row.status !== 2 && row.status !== 0 && (row.auxReportsRequest === 1 || row.auxReportsRequest === 2) ? (
                        <PiMicroscope
                            style={{
                                color: (row.auxReportsRequest === 2) ? '#FF3D32' : (row.auxReportsRequest === 1) ? '#659D17' : 'lightgrey',
                                marginRight: '8px'
                            }}
                            title="Analítica"
                        />
                    ) : null}

                    {row.identificator}
                </div>
            ),
            width: "180px",
        },
        {
            name: "Zona",
            selector: (row: any) => row.hotelZone,
            // sortable: true,
            wrap: true,
            width: "160px",
        },
        {
            name: "Hotel",
            selector: (row: any) => row.hotelName,
            // sortable: true,
            wrap: true,
        },
        {
            name: "Paciente",
            selector: (row: any) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span
                        className={getFlagClassByLanguage(row.language)}
                        style={{ marginRight: '8px', display: 'inline-block', width: '24px', height: '24px' }}
                        title={row.language} //tooltip para mostrar codigo del idioma
                    ></span>
                    {`${capitalizeWords(row.userName + " " + row.userSurname)}`}
                </div>
            ),
            wrap: true,
            width: '220px',
        },
        {
            name: "Médico",
            selector: (row: any) => row.doctorName,
            // sortable: true,
            wrap: true,
            width: '200px',
        },
        {
            name: "Estado",
            cell: (row: any) => {
                let backgroundColor, color, borderRadius = "16px", padding = "8px 16px";

                switch (row.status) {
                    case 0:
                        backgroundColor = "#FFE680"; // Light Yellow
                        color = "#806600";           // Dark Yellow
                        return (
                            <div style={{ backgroundColor, color, borderRadius, padding }}>
                                Pendiente
                            </div>
                        );
                    case 1:
                        if (row.startAt === null) {
                            backgroundColor = "#FFE0B2"; // Light Orange for "En Camino"
                            color = "#FF6F00";           // Deep Orange
                            return (
                                <div style={{ backgroundColor, color, borderRadius, padding }}>
                                    En Camino
                                </div>
                            );
                        }
                        backgroundColor = "#B3E5FC"; // Light Blue for "En consulta"
                        color = "#0288D1";           // Dark Blue
                        return (
                            <div style={{ backgroundColor, color, borderRadius, padding }}>
                                En consulta
                            </div>
                        );
                    case 2:
                        backgroundColor = "#FF9E99"; // Light Red
                        color = "#801E19";           // Dark Red
                        return (
                            <div style={{ backgroundColor, color, borderRadius, padding }}>
                                Rechazada
                            </div>
                        );
                    case 3:
                        backgroundColor = "#C8E6C9"; // Light Green
                        color = "#388E3C";           // Dark Green
                        return (
                            <div style={{ backgroundColor, color, borderRadius, padding }}>
                                Finalizada
                            </div>
                        );
                    default:
                        return "N/A";
                }
            },
            // sortable: true,
            width: '160px',
        },
        {
            name: "Fecha Solicitud",
            selector: (row: any) => moment(row.triageCreatedAt).utc().format(('DD/MM/YYYY hh:mm:ss')),
            // sortable: true,
            width: '200px',
        },
        {
            name: "Acciones",
            cell: (row: any) => {
                const buttonsConfig = [
                    // {
                    //     condition: (roleId === 5 || roleId === 1) && row.ambulanceRequest !== 2,
                    //     title: row.ambulanceRequest === 2 ? "Ya solicitada" : "Pedir Ambulancia",
                    //     icon: <FaAmbulance />,
                    //     click: () => { setRowData(row); setNeedAmbulance(true); },
                    //     disabled: row.status === 2 || row.status === 0 || row.ambulanceRequest === 3 || row.ambulanceRequest === 0,
                    //     show: !showMoreMap[row.identificator]
                    // },
                    {
                        condition: (roleId === 5 || roleId === 1),
                        title: "Cancelar",
                        icon: <MdOutlineCancel />,
                        click: () => handleCancel(row),
                        disabled: row.status === 2 || row.status === 3 || (row.status === 1 && row.startAt !== null),
                        show: !showMoreMap[row.identificator]
                    },
                    {
                        condition: (roleId === 6 || roleId === 1),
                        title: "Asignar",
                        icon: <MdOutlineAssignmentReturn />,
                        click: () => handleAssign(row),
                        disabled: row.status !== 0,
                        show: !showMoreMap[row.identificator]
                    },
                    {
                        condition: (roleId === 5 || roleId === 1),
                        title: "Enviar Informe",
                        icon: <RiMailSendLine />,
                        click: () => handleSendReport(row),
                        disabled: row.status !== 3,
                        show: showMoreMap[row.identificator]
                    },
                    {
                        condition: (roleId === 5 || roleId === 1),
                        title: "Enviar Factura",
                        icon: <BsSend />,
                        click: () => handleSendInvoice(row),
                        disabled: row.status !== 3,
                        show: showMoreMap[row.identificator]
                    },
                    {
                        condition: (roleId === 6),
                        title: "Descargar Informe",
                        icon: <MdOutlineDownload />,
                        click: () => handleDownload(row),
                        disabled: row.status !== 3,
                        show: showMoreMap[row.identificator]
                    }
                ];

                // Filter buttons based on condition
                const filteredButtons = buttonsConfig.filter(button => button.condition);

                // Show first 3 buttons or all if "showMore" is active
                const visibleButtons = showMoreMap[row.identificator]
                    ? filteredButtons
                    : filteredButtons.slice(0, 3);

                const remainingButtons = filteredButtons.filter(button => !button.disabled).length - visibleButtons.filter(button => !button.disabled).length;


                return (
                    <div className='action-dropdown'>
                        {visibleButtons.map((button, index) => (
                            <Button
                                key={index}
                                click={button.click}
                                title={button.title}
                                icon={button.icon}
                                disabled={button.disabled}
                            />
                        ))}

                        {/* Show "Show More" button if there are more than 3 buttons */}
                        {filteredButtons.length > 3 && (
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                <Button
                                    click={() => toggleShowMore(row.identificator)}
                                    title={showMoreMap[row.identificator] ? "Menos" : `Más... (${remainingButtons})`}
                                    icon={showMoreMap[row.identificator] ? <AiOutlineMinus /> : <IoIosMore />}
                                />
                                {remainingButtons > 0 && !showMoreMap[row.identificator] && (
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '-5px',
                                            right: '-5px',
                                            backgroundColor: 'red',
                                            color: 'white',
                                            borderRadius: '50%',
                                            padding: '2px 6px',
                                            fontSize: '10px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {remainingButtons}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                );
            }
        }

    ]
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const handleFilter = (event: any) => {
        let newData = [];
        if (roleId === 6) {
            const medicsWithSameCompany = medics && medics.filter((medic: any) => medic.companyId === currentUser.data.companyId);
            const medicZones = medicsWithSameCompany && medicsWithSameCompany.map((medic: any) => medic.zoneNames);
            const beforenewData = socketConsults.filter((record: any) => {
                if (record.status === 0) {
                    return medicZones.flat().includes(record.hotelZone);
                } else if (record.status !== 0 && record.doctor_companyId === currentUser.data.companyId) {
                    return true;
                }
                return false;
            });
            newData = beforenewData.filter((row: any) => {
                return (row.userName + " " + row.userSurname + " " + row.hotelZone + " " + row.hotelName + " " + row.identificator).toLowerCase().includes(event.target.value.toLowerCase());
            });
        } else {
            newData = socketConsults.filter((row: any) => {
                return (row.userName + " " + row.userSurname + " " + row.hotelZone + " " + row.hotelName + " " + row.identificator).toLowerCase().includes(event.target.value.toLowerCase());
            });
        }
        setRecords(newData);
    }

    const handleCancel = (row: any) => {
        setRowData(row);
        setCancel(true);
    }

    const handleAssign = (row: any) => {
        setRowData(row);
        setAssignTo(true);
    }

    const [loading, setLoading] = useState(false);
    const [medicValue, setMediceValue] = useState<Option | null>(null);
    const [meansValue, setMeansValue] = useState<Option | null>(null);

    const handleSendReport = async (rowdata: any) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }


            setLoading(true);
            const response = await fetch(`${apiUrl}/report/sendmedicalreport/${rowdata.consult_guid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },

            });
            if (response.ok) {
                toast.success("Informe enviado con éxito");
            } else {
                toast.error('Error al enviar el informe médico');
            }

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSendInvoice = async (rowdata: any) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }

            setLoading(true);
            const response = await fetch(`${apiUrl}/sendInvoice/${rowdata.invoice_guid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });
            if (response.ok) {
                toast.success("Factura enviada con éxito");
            } else {
                toast.error('Error al enviar la factura');
            }

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleDownload = async (rowdata: any) => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }

            const response = await fetch(`${apiUrl}/user/report/medicalreport/${rowdata.consult_guid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                }
            });

            if (!response.ok) {
                throw new Error('Error en la descarga del informe médico.');
            }

            const blob = await response.blob();

            // Generate the URL and initiate the download
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Informe_${rowdata.identificator}.pdf`;

            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };


    let allusersOptions = [];
    if (roleId === 6) {
        const medicsWithSameCompany = medics && medics.filter((medic: any) => medic.companyId === currentUser.data.companyId);
        allusersOptions = medicsWithSameCompany
            .sort((a: any, b: any) => a.fullname.localeCompare(b.fullname))
            .map((item: any, index) => ({
                key: index,
                value: item.userId.toString(),
                label: item.fullname
            }));
    } else {
        allusersOptions = medics
            .sort((a: any, b: any) => a.fullname.localeCompare(b.fullname))
            .map((item: any, index) => ({
                key: index,
                value: item.userId.toString(),
                label: item.fullname
            }));
    }

    const handleSelect = () => {
        const selectElement = document.getElementById('selectstatus') as HTMLSelectElement;
        const invoicestatus = parseInt(selectElement.value, 10);
        if (!socketConsults) {
            setRecords([]);
            return;
        } else if (invoicestatus === 4) {
            if (roleId === 6) {
                const medicsWithSameCompany = medics && medics.filter((medic: any) => medic.companyId === currentUser.data.companyId);
                const medicZones = medicsWithSameCompany && medicsWithSameCompany.map((medic: any) => medic.zoneNames);
                const filteredRecords = socketConsults.filter((record: any) => {
                    if (record.status === 0) {
                        return medicZones.flat().includes(record.hotelZone);
                    } else if (record.status !== 0 && record.doctor_companyId === currentUser.data.companyId) {
                        return true;
                    }
                    return false;
                });
                setRecords(filteredRecords);
            } else {
                setRecords(socketConsults);
            }

        } else {
            if (roleId === 6) {
                if (invoicestatus === 0) {
                    const medicsWithSameCompany = medics && medics.filter((medic: any) => medic.companyId === currentUser.data.companyId);
                    const medicZones = medicsWithSameCompany && medicsWithSameCompany.map((medic: any) => medic.zoneNames);
                    setRecords(socketConsults.filter((record: any) => {
                        if (record.status === 0) {
                            return medicZones.flat().includes(record.hotelZone);
                        }
                        return false;
                    }));
                } else {
                    setRecords(socketConsults.filter((record: any) => record.status === invoicestatus && record.doctor_companyId === currentUser.data.companyId));
                }
            } else {
                setRecords(socketConsults.filter((record: any) => record.status === invoicestatus));
            }
        }
    };

    const handleDateChange = (date: any) => {
        if (!selectionComplete && !startDate) {
            setStartDate(date);
            return;
        }

        if (!selectionComplete && startDate && !endDate) {
            setEndDate(date);
            toggleSelectionComplete(true);

            const sd = moment(startDate).format('YYYY-MM-DD'); // startdate SQL formatted
            const ed = moment(date).format('YYYY-MM-DD'); // enddate SQL formatted

            const filtered = socketConsults.filter((record: any) => {
                const recordDate = moment(record.triageCreatedAt).format('YYYY-MM-DD');
                return (recordDate >= sd && recordDate <= ed);
            });
            setRecords(filtered);
            return;
        }

        if (selectionComplete && startDate && endDate) {
            setStartDate(date);
            setEndDate(undefined);
            toggleSelectionComplete(false);
            return;
        }
    };

    const handleSelectDate = (date: any) => {
        if (!selectionComplete && startDate && !endDate && sameDay(date, startDate)) {
            handleDateChange(date);
        }
    };

    const handleShortcutChange = (value: string) => {
        const today = moment();
        let start: moment.Moment | null = null;
        let end: moment.Moment | null = null;

        switch (value) {
            case 'today':
                start = today.startOf('day');
                end = today.endOf('day');
                break;
            case 'week':
                start = today.clone().startOf('week').add(1, 'day');
                end = today.clone().endOf('week').add(1, 'day');
                break;
            case 'month':
                start = today.clone().startOf('month');
                end = today.clone().endOf('month');
                break;
            case 'year':
                start = today.clone().startOf('year');
                end = today.clone().endOf('year');
                break;
            default:
                break;
        }

        if (start && end) {
            setStartDate(start.toDate());
            setEndDate(end.toDate());
            toggleSelectionComplete(true);

            const sd = start.format('YYYY-MM-DD');
            const ed = end.format('YYYY-MM-DD');

            const filtered = socketConsults.filter((record: any) => {
                const recordDate = moment(record.triageCreatedAt).format('YYYY-MM-DD');
                return recordDate >= sd && recordDate <= ed;
            });
            setRecords(filtered);
        }
    };

    const sameDay = (d1: Date, d2: Date) => {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    };

    const isSameRange = (start: Date, end: Date, rangeStart: moment.Moment, rangeEnd: moment.Moment) => {
        return moment(start).isSame(rangeStart, 'day') && moment(end).isSame(rangeEnd, 'day');
    };

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            paddingLeft: '2rem',
            backgroundColor: '#F8F8F8',
            width: '250px'
        }),
    };

    const bymeans = [
        { value: "0", label: "App" },
        { value: "1", label: "Panel Admin" },

    ];

    const handleSelectMedic = (selectedOption: Option | null) => {
        setMediceValue(selectedOption);
        if (selectedOption === null) {
            setRecords(socketConsults);
        } else {
            const filteredRecords = socketConsults.filter((record: any) => (record.doctorName).toLowerCase() === (selectedOption.label).toLowerCase());
            setRecords(filteredRecords);
        }
    }
    const handleSelectMeans = (selectedOption: Option | null) => {
        setMeansValue(selectedOption);
        if (selectedOption === null) {
            setRecords(socketConsults);
        } else if (selectedOption.label === "App") {
            const filteredRecords = socketConsults.filter((record: any) => (record.role).toLowerCase() === ("Paciente").toLowerCase() || (record.role).toLowerCase() === ("Medico").toLowerCase());
            setRecords(filteredRecords);
        } else if (selectedOption.label === "Panel Admin") {
            const filteredRecords = socketConsults.filter((record: any) => (record.role).toLowerCase() !== ("Paciente").toLowerCase() || (record.role).toLowerCase() !== ("Medico").toLowerCase());
            setRecords(filteredRecords);
        }

    }


    return (
        <div className="table-bill">
            {loading && <Loading />}
            <div className="table-container">

                <div className='newtablefilter'>

                    <div className='filterbyuser'>
                        <div className='selectstatus-triage'>
                            <label htmlFor="selectstatus">Estado:</label>
                            <select onChange={handleSelect} name="selectstatus" id="selectstatus" defaultValue="4">
                                <option value="4">Todos</option>
                                <option value="0">Pendiente</option>
                                <option value="1">En consulta</option>
                                <option value="3">Finalizado</option>
                                <option value="2">Rechazado</option>
                            </select>
                        </div>
                        {roleId === 1 && (
                            <div className='triagedate-filter'>
                                <label htmlFor="selectdate">Fecha:</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    onSelect={handleSelectDate}
                                    selectsEnd={Boolean(startDate)}
                                    startDate={startDate}
                                    endDate={endDate}
                                    shouldCloseOnSelect={false}
                                    dateFormat="dd/MM/yyyy"
                                    locale="es"
                                    customInput={<CustomDateInput startDate={startDate} endDate={endDate} />}
                                >
                                    <div className="date-range">
                                        {startDate ? moment(startDate).format('DD/MM/YYYY') : '??/??/????'} - {endDate ? moment(endDate).format('DD/MM/YYYY') : '??/??/????'}
                                    </div>
                                    <div className='datepicker-menu'>
                                        <p>Atajo de fechas:</p>
                                        <div className='date-option-zones'>
                                            <input type="radio" id="today" name="date-option" value="Hoy" onChange={() => handleShortcutChange('today')} checked={startDate && moment(startDate).isSame(moment(), 'day')} />
                                            <label htmlFor="today">Hoy</label>

                                            <input type="radio" id="week" name="date-option" value="Semana" onChange={() => handleShortcutChange('week')} checked={startDate && endDate && isSameRange(startDate, endDate, moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day'))} />
                                            <label htmlFor="week">Semana</label>

                                            <input type="radio" id="month" name="date-option" value="Mes" onChange={() => handleShortcutChange('month')} checked={startDate && endDate && isSameRange(startDate, endDate, moment().startOf('month'), moment().endOf('month'))} />
                                            <label htmlFor="month">Mes</label>

                                            <input type="radio" id="year" name="date-option" value="Año" onChange={() => handleShortcutChange('year')} checked={startDate && endDate && isSameRange(startDate, endDate, moment().startOf('year'), moment().endOf('year'))} />
                                            <label htmlFor="year">Año</label>
                                        </div>
                                    </div>
                                </DatePicker>
                            </div>
                        )}

                        <div className='triageselect-filter'>
                            <BsSearch className='triage_search_icon' />
                            <Select
                                value={medicValue}
                                onChange={handleSelectMedic}
                                options={allusersOptions}
                                placeholder="Buscar por medico"
                                isSearchable={true}
                                isClearable={true}
                                styles={customStyles}
                            />
                        </div>

                        {roleId === 1 && (
                            <div className='triageselect-filter'>
                                <BsSearch className='triage_search_icon' />
                                <Select
                                    value={meansValue}
                                    onChange={handleSelectMeans}
                                    options={bymeans}
                                    placeholder="Seleccionar medio"
                                    isSearchable={true}
                                    isClearable={true}
                                    styles={customStyles}
                                />
                            </div>
                        )}
                    </div>


                    <div className='inputfitler-details'>
                        <BsSearch className='zone_search_icon' />
                        <input type="text" placeholder='Buscar por campos' onChange={handleFilter} />
                    </div>

                </div>
            </div>
            <DataTable
                columns={columns}
                data={records}
                pagination paginationComponentOptions={paginationComponentOptions}
            />
            {cancel && (
                <CancelTriage
                    rowdata={rowData}
                    editModal={cancel}
                    setEditModal={setCancel}
                />
            )}
            {needAmbulance && (
                <ReqAmbulance
                    rowdata={rowData}
                    editModal={needAmbulance}
                    setEditModal={setNeedAmbulance}
                />
            )}
            {assignTo && (
                <AssignMedic
                    rowdata={rowData}
                    editModal={assignTo}
                    setEditModal={setAssignTo}
                    roleId={roleId}
                    mycompanyId={currentUser.data.companyId}
                />
            )}
        </div>
    )
}

export default Triagetable;
