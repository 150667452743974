// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  grid-area: header;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
  z-index: 1;
}

.menu-icon {
  display: inline;
}

.header-right {
  position: absolute;
  align-items: center;
  right: 0;
  margin-right: 2rem;
}

.header-right .icon {
  font-size: 1.5rem;
  line-height: 2.25rem;
  cursor: pointer;
}

.nav-modal {
  position: relative;
}

.nav-trans {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
}

.indicator {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  margin-right: 14px;
}

@media screen and (max-width: 992px) {
  .menu-icon {
    display: inline;
  }
}
@media screen and (max-width: 576px) {
  .header-left {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/nav/Navbar.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,8CAAA;EACA,UAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,oBAAA;EACA,eAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,wBAAA;EACA,wDAAA;EACA,0BAAA;AACJ;;AAEA;EACI,UAAA;EACA,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,+BAAA;EACA,kBAAA;AACJ;;AAGA;EACI;IACI,eAAA;EAAN;AACF;AAGA;EACI;IACI,aAAA;EADN;AACF","sourcesContent":[".header {\n    grid-area: header;\n    height: 60px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 30px;\n    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);\n    z-index: 1;\n}\n\n.menu-icon {\n    display: inline;\n}\n\n.header-right {\n    position: absolute;\n    align-items: center;\n    right: 0;\n    margin-right: 2rem;\n}\n\n.header-right .icon {\n    font-size: 1.5rem;\n    line-height: 2.25rem;\n    cursor: pointer;\n}\n\n.nav-modal {\n    position: relative;\n}\n\n.nav-trans {\n    transition-property: all;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 100ms;\n}\n\n.indicator {\n    width: 8px;\n    height: 8px;\n    background-color: red;\n    border-radius: 50%;\n    position: absolute;\n    top: 0;\n    right: 0;\n    transform: translate(50%, -50%);\n    margin-right: 14px;\n}\n\n\n@media screen and (max-width: 992px) {\n    .menu-icon {\n        display: inline;\n    }\n}\n\n@media screen and (max-width: 576px) {\n    .header-left {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
