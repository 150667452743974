// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zones-module {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: black;
}

.zonedatefilter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.zonedatefilter .transfer-button {
  display: block;
  width: 150px;
  height: 35px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #80E6D6;
  color: #006757;
  border: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 1em;
}

.zonedatefilter input {
  background: none;
  border: none;
  color: #006757;
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
  padding: 1rem 0;
}

.zones-module h2 {
  margin-bottom: 8px;
}

.zones-module h2 span {
  cursor: pointer;
}

.zone-breadcrumbs {
  display: flex;
  gap: 1rem;
  line-height: 40px;
  margin-top: 1rem;
}

.zonedetails-demand {
  border-radius: 16px;
  border: none;
  padding: 8px;
  width: 139px;
  height: 35px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 400;
  margin-left: 1.25rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Zones.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,yBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,cAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AACJ;;AAGA;EACI,gBAAA;EACA,YAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ;;AAGA;EACI,aAAA;EACA,SAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EAEA,eAAA;EACA,oBAAA;EACA,gBAAA;EACA,oBAAA;AADJ","sourcesContent":[".zones-module {\n    grid-area: main;\n    overflow-y: auto;\n    padding: 20px 20px;\n    color: black;\n}\n\n.zonedatefilter {\n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: 8px;\n}\n\n.zonedatefilter .transfer-button {\n    display: block;\n    width: 150px;\n    height: 35px;\n    padding: 8px 16px;\n    border-radius: 8px;\n    background-color: #80E6D6;\n    color: #006757;\n    border: none;\n    cursor: pointer;\n    font-weight: 400;\n    font-size: 1em;\n}\n\n\n.zonedatefilter input {\n    background: none;\n    border: none;\n    color: #006757;\n    font-size: 1.5em;\n    font-weight: bold;\n    cursor: pointer;\n    padding: 1rem 0;\n}\n\n.zones-module h2 {\n    margin-bottom: 8px;\n}\n\n.zones-module h2 span {\n    cursor: pointer;\n}\n\n.zone-breadcrumbs {\n    display: flex;\n    gap: 1rem;\n    line-height: 40px;\n    margin-top: 1rem;\n}\n\n.zonedetails-demand {\n    border-radius: 16px;\n    border: none;\n    padding: 8px;\n    width: 139px;\n    height: 35px;\n    text-transform: uppercase;\n\n    font-size: 16px;\n    line-height: 19.36px;\n    font-weight: 400;\n    margin-left: 1.25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
