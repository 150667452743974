import React, { useEffect, useState } from 'react';
import { FaUsers, FaEuroSign, FaChartBar, FaPlus, FaEdit } from 'react-icons/fa';
import useFetch from '../../hooks/useFetch';
import CreateCompany from '../actions/CreateCompany';
import './Business.scss';
import AssignService from '../actions/AssignService';
import CommissionInfo from '../actions/CommissionInfo';
import { toast } from 'react-toastify';
import Loading from '../loading/Loading';
import EditCompany from '../actions/EditCompany';

interface SelectedCompany {
    id: string;
    name: string;
    nif: string;
    address: string;
    type: string;
    userName: string;
    service?: Array<{
        id: string;
        code: string;
        name: string;
        price: number;
        tax: number;
        commissionID: number;
    }>;
    phone: string;
    dateStart: string;
    dateEnd: string;
    email: string;
}

const Business = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: companies, refetch: refetchCompanies } = useFetch(`${apiUrl}/info/company`)

    const [selectedCompany, setSelectedCompany] = useState<SelectedCompany | null>(null);
    const [rowdata, setRowdata] = useState<any>(null);
    const [createCompanyModal, setCreateCompanyModal] = useState(false);
    const [assignServiceModal, setAssignServiceModal] = useState(false);
    const [showCommission, setShowCommission] = useState(false);
    const [editCompany, setEditCompany] = useState(false);

    const [loading, setLoading] = useState(false);

    console.log(companies)

    useEffect(() => {
        if (companies && companies.length > 0) {
            const currentSelected = selectedCompany ? companies.find((company: SelectedCompany) => company.id === selectedCompany.id) : null;
            if (currentSelected) {
                setSelectedCompany(currentSelected);
            } else {
                setSelectedCompany(companies[0]);
            }
        }

    }, [companies, selectedCompany]);

    const handleCreateCompany = () => {
        setCreateCompanyModal(true);
    };


    const handleAssignService = () => {
        setAssignServiceModal(true);
    };

    const getCommission = async (rowdata: number) => {
        setLoading(true)
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/commission/info/${rowdata}`, {
                method: "GET",
                headers: {
                    'Authorization': token,
                }
            })

            if (!response.ok) {
                toast.error('Error en obtener comisión');
                return;
            } else {
                const jsonData = await response.json();
                setRowdata(jsonData)
                setShowCommission(true)
            }

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false)
        }
    }
    return (
        <div className="business-dashboard">
            {loading && <Loading />}
            {/* Left Sidebar - Company List */}
            <aside className="business-sidebar">
                <h2>Empresas</h2>
                <button className="business-create-company-btn" onClick={handleCreateCompany}>
                    <FaPlus /> Crear Empresa
                </button>
                <div className="business-company-list">
                    {companies && companies.map((company: any) => (
                        <button
                            key={company.id}
                            onClick={() => setSelectedCompany(company)}
                            className={`business-company-item ${selectedCompany && selectedCompany.id === company.id ? 'active' : ''}`}
                        >
                            {company.name}
                        </button>
                    ))}
                </div>
            </aside>

            {/* Main Content */}
            <main className="business-main-content">
                {/* Top Cards */}
                <div className="business-top-cards">
                    <div className="business-card">
                        <div className="business-card-header">
                            <h3>Usuarios Asociados</h3>
                            <FaUsers className="business-card-icon" />
                        </div>
                        <div className="business-card-content">
                            <div className="business-card-value"><span>Pendiente</span></div>
                            <p className="business-card-subtext">+10% from last month</p>
                        </div>
                    </div>
                    <div className="business-card">
                        <div className="business-card-header">
                            <h3>Ingresos totales</h3>
                            <FaEuroSign className="business-card-icon" />
                        </div>
                        <div className="business-card-content">
                            <div className="business-card-value"><span>Pendiente</span></div>
                            <p className="business-card-subtext">+20.1% from last month</p>
                        </div>
                    </div>
                    <div className="business-card">
                        <div className="business-card-header">
                            <h3>Saldo pendiente</h3>
                            <FaChartBar className="business-card-icon" />
                        </div>
                        <div className="business-card-content">
                            <div className="business-card-value">
                                <p>Datos pendientes</p>
                            </div>
                            <p className="business-card-subtext">Hazme el calculo jodeer</p>
                        </div>
                    </div>
                </div>

                {/* Company Information and Commission List */}
                <div className="business-info-section">
                    {/* Company Information */}
                    <div className="business-company-info">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h2>Información de la Empresa</h2>
                            <button className="business-assign-service-btn" onClick={() => { setRowdata(selectedCompany); setEditCompany(true) }}>
                                <FaEdit /> Editar
                            </button>
                        </div>
                        {selectedCompany && (
                            <div className="business-info-content">
                                <h3>{selectedCompany.name}</h3>
                                <p>ID de la Empresa: {selectedCompany.id}</p>
                                <hr />
                                <div className="business-info-grid">
                                    <div>
                                        <p className="business-info-label">NIF</p>
                                        <p>{selectedCompany.nif}</p>
                                    </div>
                                    <div>
                                        <p className="business-info-label">Dirección</p>
                                        <p>{selectedCompany.address}</p>
                                    </div>
                                    <div>
                                        <p className="business-info-label">Tipo</p>
                                        <p>{selectedCompany.type}</p>
                                    </div>
                                    <div>
                                        <p className="business-info-label">Cuenta Asociada</p>
                                        <p>{selectedCompany.userName}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Services List */}
                    <div className="business-services-list">
                        <div className="business-services-header">
                            <h2>Servicios que ofrece</h2>
                            <button className="business-assign-service-btn" onClick={handleAssignService}>
                                <FaPlus /> Asignar Servicio
                            </button>
                        </div>
                        {selectedCompany && selectedCompany.service && selectedCompany.service.length > 0 ? (
                            <table className="business-table">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Precio</th>
                                        <th>Impuesto</th>
                                        <th>Comisión</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedCompany.service.map((service: any) => (
                                        <tr key={service.id}>
                                            <td>{service.name}</td>
                                            <td>€{service.price.toFixed(2)}</td>
                                            <td>{service.tax}%</td>
                                            <td>
                                                <button className='businesss-moreinfo' onClick={() => getCommission(service.commissionID)}>
                                                    Más Info
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>Aún no se han asignado servicios a esta empresa.</p>
                        )}
                    </div>
                </div>
            </main>

            {createCompanyModal && (
                <CreateCompany
                    editModal={createCompanyModal}
                    setEditModal={setCreateCompanyModal}
                    refetch={refetchCompanies}
                />
            )}

            {assignServiceModal && (
                <AssignService
                    editModal={assignServiceModal}
                    setEditModal={setAssignServiceModal}
                    refetch={refetchCompanies}
                    selectedCompany={selectedCompany}
                />
            )}

            {showCommission && (
                <CommissionInfo
                    editModal={showCommission}
                    setEditModal={setShowCommission}
                    rowdata={rowdata}
                />
            )}

            {editCompany && (
                <EditCompany
                    editModal={editCompany}
                    setEditModal={setEditCompany}
                    initialData={rowdata}
                    refetch={refetchCompanies}
                />
            )}


        </div>
    );
};

export default Business