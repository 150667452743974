// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
  margin-top: 2.5rem;
  margin-right: 1.25rem;
  margin-bottom: 2rem;
  margin-left: 1.25rem;
  padding-left: 5rem;
  border-radius: 0.75rem;
  background-color: #fff;
  width: 80%;
}
.chart-container h2 {
  color: #202224;
}`, "",{"version":3,"sources":["webpack://./src/components/statistics/Fchart.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,qBAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,sBAAA;EACA,sBAAA;EACA,UAAA;AACJ;AAEI;EACI,cAAA;AAAR","sourcesContent":[".chart-container {\n    margin-top: 2.5rem;\n    margin-right: 1.25rem;\n    margin-bottom: 2rem;\n    margin-left: 1.25rem;\n    padding-left: 5rem;\n    border-radius: 0.75rem;\n    background-color: #fff;\n    width: 80%;\n    // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);\n\n    h2 {\n        color: #202224;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
