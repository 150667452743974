import Modal from '../../utilities/Modal';
import { BsExclamationCircle, BsTelephone } from 'react-icons/bs';
import logo from '../../assets/whitelogo.svg';


const Derive = ({ editModal, setEditModal, resetValues, levelzero, setLevelzero, setCurrentStep, backButton }: any) => {

    const handleReset = () => {
        setEditModal(false);
        resetValues();
    };
    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="popup-container">
                <div className="popup-header">
                    <BsExclamationCircle className="popup-exclamation2" />
                    {levelzero ? (
                        <h2 className="warning-title">Emergencia muy elevada</h2>
                    ) : (
                        <h2 className="warning-title">Emergencia detectada</h2>
                    )}
                </div>

                {!levelzero ? (
                    <div className="instructions-container">
                        <div className="instruction-block">
                            <div className="icon-wrapper-nobg">
                                <BsTelephone style={{ fontSize: '2.5rem' }} />
                            </div>

                            <h3 className="action-title">Llamar al 112</h3>
                            <p className="action-text">
                                Llame al 112 en nombre del paciente o indique al paciente que llame
                            </p>
                            <button className="action-button" onClick={handleReset}>Confirmado</button>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="divider">
                            </div>
                            <span className="divider-text">o</span>
                            <div className="divider">

                            </div>
                        </div>


                        <div className="instruction-block">
                            <div className="icon-wrapper">
                                <img style={{ width: 40, height: 40 }} src={logo} alt="logo" />
                            </div>
                            <h3 className="action-title">Seguir atendiendo</h3>
                            <p className="action-text2">
                                El paciente tendrá prioridad máxima
                            </p>
                            <button className="action-button" onClick={() => {
                                setEditModal(false); setLevelzero(false);
                                if (backButton) {
                                    setCurrentStep(1)
                                } else {
                                    setCurrentStep(3)
                                }
                            }}>Atender con DR2U</button>
                        </div>
                    </div>
                ) : (
                    <div className="instruction-block">
                        <div className="icon-wrapper-nobg">
                            <BsTelephone style={{ fontSize: '2.5rem' }} />
                        </div>

                        <h3 className="action-title">Llamar al 112</h3>
                        <p className="action-text">
                            Llame al 112 en nombre del paciente o indique al paciente que llame
                        </p>
                        <button className="action-button" onClick={handleReset}>Confirmado</button>
                    </div>
                )}
            </div>
        </Modal>



    )
}

export default Derive
