import React, { useRef, useState } from 'react';
import "./Update.scss";
import Modal from '../../utilities/Modal';
import { LiaTimesSolid } from 'react-icons/lia';
// import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import PhoneInput from 'react-phone-input-2';
import spanish from 'react-phone-input-2/lang/es.json';
import { toast } from 'react-toastify';
import { FaCheck } from 'react-icons/fa';
import { LANGUAGES } from '../../data/languages';
import Select from 'react-select';
interface FormData {
    email: string;

    fullname: string;
    zone: string;
    phone: string;
    status: string;
    documentid: string,
    address: string,
    gender: string,
    language: string,
    languages: string[],
    companyId: string,

}

const Update = ({ editModal, setEditModal, rowdata, refetch }: any) => {
    console.log(rowdata)
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: zoneOptionsData } = useFetch(`${apiUrl}/admin/zone`) as any;
    const { data: companyData } = useFetch(`${apiUrl}/info/company`) as any;

    console.log(rowdata.companyId)
    const [form, setForm] = useState<FormData>({
        email: rowdata.email,

        fullname: rowdata.fullname,
        zone: rowdata.zone,
        phone: rowdata.phone,
        status: rowdata.status === "Inhabilitado" ? "3" : rowdata.status === "Inactivo" ? "2" : "1",
        documentid: rowdata.documentid,
        address: rowdata.address,
        gender: rowdata.gender,
        language: rowdata.lenguage,
        languages: rowdata.languageSpoken.languages ? rowdata.languageSpoken.languages : [],
        companyId: rowdata.companyId,
        // newPswd: "",
    });

    // const [pswd, setPswd] = useState(false)

    // function generateNewPassword(length = 12) {
    //     const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    //     let password = "";
    //     for (let i = 0; i < length; ++i) {
    //         const randomIndex = Math.floor(Math.random() * charset.length);
    //         password += charset[randomIndex];
    //     }
    //     return password;
    // }

    // const handlePswd = () => {
    //     setPswd(true);
    //     const newPswd = generateNewPassword();
    //     setForm((prevForm) => ({
    //         ...prevForm,
    //         data: {
    //             ...prevForm.data,
    //             newPswd: newPswd
    //         }
    //     }))
    // }

    const splitFullName = (fullname: string) => {
        const nameParts = fullname.split(' ');
        let firstName = '';
        let surname = '';

        if (nameParts.length > 1) {
            firstName = nameParts.slice(0, nameParts.length - 1).join(' ');
            surname = nameParts[nameParts.length - 1];
        } else {
            firstName = nameParts[0];
        }

        return { firstName, surname };
    };

    console.log(form.languages)
    const { firstName, surname } = splitFullName(form.fullname);
    const handleUpdate = async (e: any) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const zoneId = zoneOptionsData.find((zone: any) => zone.zone === form.zone)?.id;
            const response = await fetch(`${apiUrl}/admin/users/update/${rowdata.userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    email: form.email,
                    name: firstName,
                    surname: surname,
                    status: parseInt(form.status),
                    zone: rowdata.roleId === "Medico" ? zoneId : null,
                    address: form.address ? form.address : null,
                    idcard: form.documentid,
                    phone: form.phone,
                    signature: pdfFile ? pdfFile : null,
                    genre: form.gender,
                    language: form.language,
                    languages: form.languages?.length > 0 ? form.languages : null,
                    companyId: form.companyId ? parseInt(form.companyId) : null,
                }),
            });

            if (!response.ok) {
                throw new Error('Error en actualizar los datos');
            }

            setEditModal(false); // Cierra el modal despues de enviar
            navigate('/usuarios');
            refetch();
            toast.success("Usuario editado correctamente");

        } catch (error) {
            console.error('Error:', error);
        }
    };
    const [pdfFile, setPdfFile] = useState<string | null>();
    const fileRef = useRef<any>(null);
    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        fileRef.current.click();

    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result as string;
                setPdfFile(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className='update-form'>
                <div className='update-form-header'>
                    <h3>Editar a: {rowdata.fullname}</h3>
                    <button onClick={() => setEditModal(false)} className='close-modal'>
                        <LiaTimesSolid />
                    </button>
                </div>

                <div className='formform'>
                    <form className='updateuserform_edit' onSubmit={handleUpdate}>
                        <div className={`${rowdata.roleId === "Medico" ? 'update-form-container_medic' : 'update-form-container'}`}>
                            <div className='update-form-data'>

                                <label htmlFor="">Nombre completo</label>
                                <input
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setForm((prevForm) => ({
                                        ...prevForm,

                                        fullname: e.target.value

                                    }))}
                                    value={form.fullname}
                                    className='update-form-input'
                                    type="text" />

                            </div>
                            {rowdata.roleId === "Medico" && (
                                <>
                                    <div className='update-form-data'>
                                        <label htmlFor="">Estado</label>
                                        <div className='update-form-separated'>
                                            <select
                                                id="status-select"
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setForm((prevForm) => ({
                                                    ...prevForm,

                                                    status: e.target.value

                                                }))}
                                                value={form.status}
                                                className='update-form-input'
                                            >
                                                <option value="1">Disponible</option>
                                                <option value="2">Inactivo temporalmente</option>
                                                <option value="3">Baja permanente</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='update-form-data'>
                                        <label htmlFor="">Zona</label>
                                        <div className='update-form-separated'>
                                            <select
                                                id="zone-select"
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setForm((prevForm) => ({
                                                    ...prevForm,

                                                    zone: e.target.value

                                                }))}
                                                value={form.zone}
                                                className='update-form-input'
                                            >
                                                {zoneOptionsData && zoneOptionsData.map((zone: any) => (
                                                    <option key={zone.id} value={zone.zone}>
                                                        {zone.zone}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='update-form-data'>
                                        <label htmlFor="">Firma &#40;opcional&#41;</label>
                                        <div className='update-form-separated'>
                                            {rowdata.sign !== "N/A" && <img src={rowdata.sign} alt="" className='signature-photo' />}
                                            <button
                                                onClick={handleClick}
                                                className="addSignature">
                                                Subir un archivo
                                            </button>
                                            {pdfFile && <span className='filename'>imagen seleccionada<FaCheck className='filename-icon' /></span>}
                                            <input
                                                onChange={handleFileChange}
                                                type="file" accept=".png" hidden ref={fileRef}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className='update-form-data'>
                                <label htmlFor="">Correo</label>
                                <input
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setForm((prevForm) => ({
                                        ...prevForm,
                                        email: e.target.value
                                    }))}
                                    value={form.email}
                                    className='update-form-input'
                                    type="text" />
                            </div>
                            <div className='update-form-data'>
                                <label htmlFor="">Documento de Identidad</label>
                                <input
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setForm((prevForm) => ({
                                        ...prevForm,
                                        documentid: e.target.value
                                    }))}
                                    value={form.documentid}
                                    className='update-form-input'
                                    type="text" />
                            </div>
                            <div className='update-form-data'>
                                <label htmlFor="">Número de Contacto</label>
                                <PhoneInput
                                    buttonStyle={{
                                        border: "none",
                                    }}
                                    inputStyle={{
                                        width: "100%",
                                        height: "45px",
                                        border: "none",
                                        outline: "none",
                                        backgroundColor: "#f6f8fa",
                                        fontSize: "16px",
                                        borderRadius: "5px",
                                        transition: "all 120ms ease-out 0s",
                                        boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)"
                                    }}
                                    localization={spanish}
                                    regions={'europe'}
                                    country={'es'}
                                    value={form.phone}
                                    onChange={(phone) => setForm({ ...form, phone: phone })}
                                />
                            </div>
                            <div className='update-form-data'>
                                <label htmlFor="">Dirección</label>
                                <input
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setForm((prevForm) => ({
                                        ...prevForm,
                                        address: e.target.value
                                    }))}
                                    value={form.address}
                                    className='update-form-input'
                                    type="text" />
                            </div>

                            <div className='update-form-data'>
                                <label htmlFor="primary-language-select">Idioma</label>
                                <div className='update-form-separated'>
                                    <select
                                        id="primary-language-select"
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setForm((prevForm) => ({
                                            ...prevForm,
                                            language: e.target.value
                                        }))}
                                        value={form.language}
                                        className='update-form-input'
                                    >
                                        {LANGUAGES.map((language) => (
                                            <option key={language.value} value={language.value}>
                                                {language.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {rowdata.roleId === 'Medico' && (
                                <div className='update-form-data'>
                                    <label htmlFor="secondary-languages-select">Idiomas Secundarios</label>
                                    <div className='update-form-separated'>
                                        <div style={{ flex: 1 }}>
                                            <Select
                                                id="secondary-languages-select"
                                                isMulti
                                                options={LANGUAGES.filter((language) => language.value !== form.language)
                                                    .map(language => ({
                                                        value: language.value,
                                                        label: language.label
                                                    }))
                                                }
                                                value={LANGUAGES.filter(option => form.languages?.includes(option.value))
                                                    .map(language => ({
                                                        value: language.value,
                                                        label: language.label
                                                    }))
                                                }
                                                onChange={(selectedOptions: any) => {
                                                    const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                                                    setForm((prevForm) => ({
                                                        ...prevForm,
                                                        languages: selectedValues
                                                    }));
                                                }}
                                                className='react-select-container'
                                                classNamePrefix='react-select'
                                                styles={{
                                                    control: base => ({
                                                        ...base,
                                                        height: 45,
                                                        backgroundColor: '#f6f8fa',
                                                        width: '100%',
                                                    }),
                                                    valueContainer: (css: any) => ({ ...css, "flexWrap": "nowrap", "whiteSpace": "nowrap", overflow: "hidden", 'textOverflow': 'ellipsis' })

                                                }}
                                                menuPlacement="auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className='update-form-data'>
                                <label htmlFor="">Género</label>
                                <div className='update-form-separated'>
                                    <select
                                        id="status-select"
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setForm((prevForm) => ({
                                            ...prevForm,

                                            gender: e.target.value

                                        }))}
                                        value={form.gender}
                                        className='update-form-input'
                                    >
                                        <option value="N/A" disabled>Sin seleccionar</option>
                                        <option value="Female">Mujer</option>
                                        <option value="Male">Hombre</option>
                                        <option value="Other">Otro</option>
                                    </select>
                                </div>
                            </div>
                            {(rowdata.roleId === "Medico" || rowdata.roleId === "Paciente") && (
                                <div className='update-form-data'>
                                    <label htmlFor="company-select">{rowdata.roleId === "Paciente" ? "Procedencia" : "Empresa Vinculada"}</label>
                                    <select
                                        id="company-select"
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setForm((prevForm) => ({
                                            ...prevForm,
                                            companyId: e.target.value
                                        }))}
                                        value={form.companyId}
                                        className='update-form-input'
                                    >
                                        <option value="0">Ninguna</option>
                                        {companyData && companyData.map((company: any) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {/* {!pswd && (
                        <button
                            onClick={handlePswd}
                            className='pswd-button'>
                            <MdKeyboardArrowRight />
                            Enviar nueva contraseña
                        </button>
                    )}
                    {pswd && (
                        <div className='update-pswd'>
                            <input
                                readOnly
                                value={form.data.newPswd}
                                className='update-form-input'
                                type="text" />
                            <button onClick={() => setPswd(false)} className='cancel-pswd'>x</button>
                        </div>
                    )} */}

                        </div>

                        <div className='update-form-bottom'>
                            <button type='submit' className='update-button'>Actualizar</button>
                        </div>
                    </form>
                </div>

            </div>
        </Modal>
    )
}

export default Update
