import React from 'react'
import { useState } from 'react'
import './CreateComission.scss'
import Modal from '../../utilities/Modal';
import { toast } from 'react-toastify';
type IntervalRow = {
    intervalStart: number;
    intervalEnd: number;
    price: number;
    type: '%' | '€';
}

type CommissionType = {
    id?: number;
    name: string;
    intervalo: 'SI' | 'NO';
    commissionType: 'variable' | 'fijo';
    commission: number;
    intervals: IntervalRow[];
}
const CreateComission = ({ editModal, setEditModal, refetch }: any) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState<CommissionType>(null || {
        name: '',
        intervalo: 'NO',
        commissionType: 'variable',
        commission: 0,
        intervals: [{ intervalStart: 0, intervalEnd: 0, price: 0, type: '%' }],
    })


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target
        setFormData(prev => ({
            ...prev,
            [name]: name === 'commission' ? parseInt(value, 10) : value
        }))
    }

    const handleIntervalChange = (index: number, field: keyof IntervalRow, value: string | number) => {
        const newIntervals = [...formData.intervals]
        if (field === 'intervalStart') {
            const start = value as number;
            newIntervals[index].intervalStart = start;
            if (index < newIntervals.length - 1) {
                newIntervals[index + 1].intervalEnd = start;
            }
        } else if (field === 'intervalEnd') {
            newIntervals[index].intervalEnd = value as number;
        } else if (field === 'price') {
            newIntervals[index][field] = parseInt(value as string, 10);
        } else if (field === 'type') {
            newIntervals[index][field] = value as '%' | '€';
        }
        setFormData(prev => ({ ...prev, intervals: newIntervals }))
    }

    const addInterval = () => {
        const lastInterval = formData.intervals[formData.intervals.length - 1];
        setFormData(prev => ({
            ...prev,
            intervals: [...prev.intervals, { intervalStart: lastInterval.intervalEnd, intervalEnd: 0, price: 0, type: '%' }]
        }))
    }

    const removeInterval = (index: number) => {
        setFormData(prev => ({
            ...prev,
            intervals: prev.intervals.filter((_, i) => i !== index)
        }))
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        const dataToSubmit = {
            name: formData.name,
            interval: formData.intervalo === 'SI' ? 1 : 0,
            commission: formData.intervalo === 'NO' ? formData.commission : null,
            commissionType: formData.intervalo === 'NO' ? (formData.commissionType === 'fijo' ? 1 : 0) : null,
            intervalInfo: formData.intervalo === 'SI' ? formData.intervals : null,
        };

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found.');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/create/newcommission`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify(dataToSubmit),

            });

            console.log(dataToSubmit)

            if (!response.ok) {
                toast.error('Error en crear la empresa');
                return;
            }

            setEditModal(false);
            toast.success("Comisión creada correctamente");
            refetch();

        } catch (error) {
            console.error('Error:', error);
        }

    }

    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="ct-card">
                <div className="ct-card-header">
                    <h2 className="ct-card-title">Commission Type</h2>
                    <p className="ct-card-description">Create a commission type</p>
                </div>
                <div className="ct-card-content">
                    <form onSubmit={handleSubmit} className="ct-form">
                        <div className="ct-form-group ct-radio-group">
                            <span className="ct-label">Intervalo</span>
                            <div className="ct-radio-options">
                                <label className="ct-radio-label">
                                    <input
                                        type="radio"
                                        name="intervalo"
                                        value="SI"
                                        checked={formData.intervalo === 'SI'}
                                        onChange={handleChange}
                                        className="ct-radio"
                                    />
                                    SI
                                </label>
                                <label className="ct-radio-label">
                                    <input
                                        type="radio"
                                        name="intervalo"
                                        value="NO"
                                        checked={formData.intervalo === 'NO'}
                                        onChange={handleChange}
                                        className="ct-radio"
                                    />
                                    NO
                                </label>
                            </div>
                        </div>

                        <div className="ct-form-group">
                            <label htmlFor="name" className="ct-label">Nombre</label>
                            <input
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className="ct-input"
                            />
                        </div>

                        {formData.intervalo === 'NO' && (
                            <>
                                <div className="ct-form-group">
                                    <label htmlFor="commissionType" className="ct-label">Tipo de Comisión</label>
                                    <select
                                        id="commissionType"
                                        name="commissionType"
                                        value={formData.commissionType}
                                        onChange={handleChange}
                                        className="ct-select"
                                    >
                                        <option value="variable">Variable %</option>
                                        <option value="fijo">Fijo €</option>
                                    </select>
                                </div>

                                <div className="ct-form-group">
                                    <label htmlFor="commission" className="ct-label">Comisión</label>
                                    <input
                                        id="commission"
                                        name="commission"
                                        type="number"
                                        value={formData.commission}
                                        onChange={handleChange}
                                        step="0.01"
                                        className="ct-input"
                                    />
                                </div>
                            </>
                        )}

                        {formData.intervalo === 'SI' && (
                            <div className="ct-form-group">
                                <div className="ct-intervals-header">
                                    <label className="ct-label">Intervalos</label>
                                    <button type="button" className="ct-button ct-button-outline" onClick={addInterval}>
                                        Añadir Intervalo
                                    </button>
                                </div>
                                {formData.intervals.map((interval, index) => (
                                    <div key={index} className="ct-interval-row">
                                        <div className="ct-form-group">
                                            <label htmlFor={`rango-${index}`} className="ct-label">Rango</label>
                                            <input
                                                id={`rango-${index}`}
                                                value={`${interval.intervalStart}-${interval.intervalEnd}`}
                                                onChange={(e) => {
                                                    const [start, end] = e.target.value.split('-').map(Number);
                                                    handleIntervalChange(index, 'intervalStart', start);
                                                    handleIntervalChange(index, 'intervalEnd', end);
                                                }}
                                                placeholder="0-100"
                                                className="ct-input"
                                            />
                                        </div>
                                        <div className="ct-form-group">
                                            <label htmlFor={`price-${index}`} className="ct-label">Precio</label>
                                            <input
                                                id={`price-${index}`}
                                                type="number"
                                                value={interval.price}
                                                onChange={(e) => handleIntervalChange(index, 'price', e.target.value)}
                                                className="ct-input"
                                            />
                                        </div>
                                        <div className="ct-form-group">
                                            <label htmlFor={`type-${index}`} className="ct-label">Tipo</label>
                                            <select
                                                id={`type-${index}`}
                                                value={interval.type}
                                                onChange={(e) => handleIntervalChange(index, 'type', e.target.value as '%' | 'fijo')}
                                                className="ct-select"
                                            >
                                                <option value="%">Variable %</option>
                                                <option value="€">Fijo €</option>
                                            </select>
                                        </div>
                                        {index > 0 && (
                                            <button type="button" className="ct-button ct-button-destructive" onClick={() => removeInterval(index)}>
                                                Eliminar
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </form>
                </div>
                <div className="ct-card-footer">
                    <button type="submit" className="ct-button" onClick={handleSubmit}>Guardar Tipo de Comisión</button>
                </div>
            </div>
        </Modal>
    )
}

export default CreateComission
