import React, { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const AttentionHistory = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const location = useLocation();
    const { id } = location.state || {};
    const { data: attentionclient } = useFetch(`${apiUrl}/whoattendedwho/${id}`);
    const [records, setRecords] = useState([])

    useEffect(() => {
        setRecords(attentionclient); // Initialize state with the fetched data
    }, [attentionclient]);

    const columns = [
        {
            name: "Id de la Consulta",
            selector: (row: any) => row.triageid,
            sortable: true,
        },
        {
            name: "Paciente",
            selector: (row: any) => row.patient,
            sortable: true,
        },
        {
            name: "Alojamiento",
            selector: (row: any) => row.hotel,
            sortable: true,
        },
        {
            name: "Fecha de la asistencia",
            selector: (row: any) => moment(row.when).utc().format('YYYY-MM-DD hh:mm:ss'),
            sortable: true,
        },

    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    return (
        <div className="table-bill">
            <div className="table-container">
                <span className='table-name'>Historial de atención</span>
            </div>
            <DataTable
                columns={columns}
                data={records}
                pagination paginationComponentOptions={paginationComponentOptions}

            />
        </div>
    )
}

export default AttentionHistory
