import Modal from '../../utilities/Modal';
import { BsExclamationCircle } from 'react-icons/bs';
import { toast } from 'react-toastify';

const ReqAmbulance = ({ editModal, setEditModal, rowdata }: any) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    console.log(rowdata)
    const handleAmbulance = async (status: number) => {
        const data = {
            status: status
        };
        try {

            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const response = await fetch(`${apiUrl}/ambulanceRequest/${rowdata.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Error en actualizar los datos');
            }

            // Resetea valores despues de enviar
            setEditModal(false); // Cierra el modal despues de enviar
            if (status === 2) {
                toast.success("Ambulancia solicitada");
            } else {
                toast.success("Ambulancia rechazada");
            }


        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="popup-delete">
                <div className="popup-delete-message">
                    <BsExclamationCircle className='popup-exclamation' />
                    <h3 >¿Solicitar ambulancia para la consulta [{rowdata.identificator}]?</h3>
                    <div>
                        <h4>Datos que deben facilitarse:</h4>
                        <ul>
                            <li>Tipo de ambulancia: {rowdata.ambulanceType}</li>
                            <li>Zona: {rowdata.hotelZone}</li>
                            <li>Hotel: {rowdata.hotelName}</li>
                            <li>Habitación: {rowdata.roomRes}</li>
                        </ul>
                    </div>
                </div>
                <div className='popup-delete-buttons'>
                    {rowdata.ambulanceRequest === 1 &&
                        <button type="button" onClick={() => { handleAmbulance(2) }} className="confirmgreen-button">Solicitar</button>
                    }
                    <button type="button" onClick={() => { handleAmbulance(3) }} className="denyred-button">Rechazar</button>
                    <button type="button" onClick={() => setEditModal(false)} className="cancel-button">Cancelar</button>
                </div>
            </div>
        </Modal>

    )
}

export default ReqAmbulance
