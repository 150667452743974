import { TbFileInvoice, TbCreditCard } from 'react-icons/tb';
import { GiMoneyStack, GiReceiveMoney } from "react-icons/gi";

export const requestperzone = [
    {
        zone: "Meloneras",
        requests: 50,
        accepted: 6,
        rejected: 0,
        priority: "ALTA"
    },
    {
        zone: "San Agustin",
        requests: 14,
        accepted: 2,
        rejected: 0,
        priority: "ALTA"
    },
    {
        zone: "Playa del inglés",
        requests: 20,
        accepted: 4,
        rejected: 1,
        priority: "MEDIA"
    },
    {
        zone: "Puerto Rico",
        requests: 10,
        accepted: 2,
        rejected: 1,
        priority: "MEDIA"
    },
    {
        zone: "Mogan",
        requests: 18,
        accepted: 3,
        rejected: 1,
        priority: "MEDIA"
    },
    {
        zone: "Arguineguin",
        requests: 1,
        accepted: 1,
        rejected: 1,
        priority: "BAJA"
    },
    {
        zone: "Salobre",
        requests: 0,
        accepted: 0,
        rejected: 0,
        priority: "BAJA"
    },
    {
        zone: "Pata la Vaca",
        requests: 0,
        accepted: 0,
        rejected: 0,
        priority: "BAJA"
    }
]
export const zonedetails = [
    {
        nombre: "María Inmaculada",
        medico: "-",
        estado: "Pendiente",
        hotel: "Baobab",
        tdesplazamiento: "-",
        tconsulta: "-"
    },
    {
        nombre: "Mario Manuel",
        medico: "Ambrosio Pomodoro",
        estado: "Finalizado",
        hotel: "Baobab",
        tdesplazamiento: "24 min 30 seg",
        tconsulta: "12 min 23 seg"
    },
    {
        nombre: "María Inmaculada",
        medico: "Ambrosio Pomodoro",
        estado: "En camino",
        hotel: "Baobab",
        tdesplazamiento: "-",
        tconsulta: "-"
    },
    {
        nombre: "María Inmaculada",
        medico: "Ambrosio Pomodoro",
        estado: "En consulta",
        hotel: "Baobab",
        tdesplazamiento: "24 min 30 seg",
        tconsulta: "-"
    }
]

export const doctorsData = [
    { name: 'Rodolfo', status: 'Disponible', zone: 'Puerto Rico', demand: 'BAJA' },
    { name: 'Irina', status: 'Disponible', zone: 'Mogán', demand: 'BAJA' },
    { name: 'Cristina', status: 'En consulta', zone: 'Puerto Rico', demand: 'BAJA' },
    { name: 'Aaron', status: 'En consulta', zone: 'Puerto Rico', demand: 'BAJA' },
    { name: 'Händel', status: 'En consulta', zone: 'Puerto Rico', demand: 'BAJA' },
    { name: 'Dani', status: 'En consulta', zone: 'Puerto Rico', demand: 'BAJA' },
];

export const cards = [
    {
        title: "Numero de facturas",
        value: "269",
        icon: <TbFileInvoice />,
    },
    {
        title: "Ingresos de este mes",
        value: "1293€",
        icon: <GiReceiveMoney />,
    },
    {
        title: "Ingresos último mes",
        value: "3029€",
        icon: <TbCreditCard />,
    },
    {
        title: "Ingresos totales",
        value: "4322€",
        icon: <GiMoneyStack />,
    },
];

export const ambulancerequest = [{
    "nombre": "Danya Tull",
    "ubicacion": "3 Forest Run Drive",
    "habitacion": "Suite 95",
    "telefono": "803-555-3195",
    "fechayhora": "2023-12-14 20:54:48",
    "motivo": "Felis concolor"
}, {
    "nombre": "Hirsch Ruffell",
    "ubicacion": "2 Calypso Point",
    "habitacion": "Apt 1891",
    "telefono": "314-577-2305",
    "fechayhora": "2024-09-17 10:29:44",
    "motivo": "Crocuta crocuta"
}, {
    "nombre": "Jarrad MacKenny",
    "ubicacion": "128 Katie Pass",
    "habitacion": "PO Box 18784",
    "telefono": "562-631-3818",
    "fechayhora": "2024-07-14 18:26:35",
    "motivo": "Iguana iguana"
}, {
    "nombre": "Clara Cordingly",
    "ubicacion": "290 Lakewood Hill",
    "habitacion": "12th Floor",
    "telefono": "995-778-2473",
    "fechayhora": "2023-09-20 16:53:18",
    "motivo": "Mephitis mephitis"
}, {
    "nombre": "Shaine Goligly",
    "ubicacion": "931 Anthes Hill",
    "habitacion": "Room 998",
    "telefono": "962-807-4761",
    "fechayhora": "2023-07-22 19:07:54",
    "motivo": "Tiliqua scincoides"
}, {
    "nombre": "Octavia Halpine",
    "ubicacion": "576 Kenwood Hill",
    "habitacion": "Apt 1117",
    "telefono": "279-108-5705",
    "fechayhora": "2024-06-14 02:15:18",
    "motivo": "Ciconia ciconia"
}, {
    "nombre": "Milena Reimer",
    "ubicacion": "78 Prentice Park",
    "habitacion": "2nd Floor",
    "telefono": "929-948-8103",
    "fechayhora": "2024-08-20 06:51:51",
    "motivo": "Francolinus leucoscepus"
}, {
    "nombre": "Edita Foord",
    "ubicacion": "521 Carpenter Park",
    "habitacion": "Suite 86",
    "telefono": "927-430-9595",
    "fechayhora": "2023-07-06 21:39:10",
    "motivo": "Lamprotornis nitens"
}, {
    "nombre": "Corbie Brant",
    "ubicacion": "164 Debs Way",
    "habitacion": "Apt 1182",
    "telefono": "125-965-5177",
    "fechayhora": "2023-12-26 03:36:36",
    "motivo": "Microcebus murinus"
}, {
    "nombre": "Goldarina Causbey",
    "ubicacion": "87 Merry Avenue",
    "habitacion": "Suite 63",
    "telefono": "493-332-4767",
    "fechayhora": "2024-10-02 03:58:36",
    "motivo": "Passer domesticus"
}, {
    "nombre": "Charla Rundall",
    "ubicacion": "01274 Mosinee Drive",
    "habitacion": "15th Floor",
    "telefono": "476-219-5081",
    "fechayhora": "2023-08-09 19:04:04",
    "motivo": "Eolophus roseicapillus"
}, {
    "nombre": "Terri Gemelli",
    "ubicacion": "220 Moulton Street",
    "habitacion": "1st Floor",
    "telefono": "271-533-0186",
    "fechayhora": "2023-12-06 17:34:43",
    "motivo": "Neotis denhami"
}, {
    "nombre": "Neils Popham",
    "ubicacion": "66717 Surrey Junction",
    "habitacion": "Suite 89",
    "telefono": "250-141-9852",
    "fechayhora": "2023-08-03 23:24:15",
    "motivo": "Anthropoides paradisea"
}, {
    "nombre": "Hakeem Karys",
    "ubicacion": "82 Oak Valley Avenue",
    "habitacion": "PO Box 29925",
    "telefono": "513-488-6904",
    "fechayhora": "2023-12-16 13:28:27",
    "motivo": "Panthera onca"
}, {
    "nombre": "Ailene Burchmore",
    "ubicacion": "5 Upham Place",
    "habitacion": "Room 1587",
    "telefono": "713-728-3042",
    "fechayhora": "2024-11-03 06:19:31",
    "motivo": "Cercatetus concinnus"
}, {
    "nombre": "Joscelin Warcup",
    "ubicacion": "27929 Forest Circle",
    "habitacion": "Suite 28",
    "telefono": "577-528-9567",
    "fechayhora": "2024-04-27 13:48:41",
    "motivo": "Aegypius tracheliotus"
}, {
    "nombre": "Aloysius Gercke",
    "ubicacion": "03 Twin Pines Parkway",
    "habitacion": "Room 962",
    "telefono": "466-131-7173",
    "fechayhora": "2023-11-25 13:05:29",
    "motivo": "Ephipplorhynchus senegalensis"
}, {
    "nombre": "Lina Leaman",
    "ubicacion": "588 Hintze Trail",
    "habitacion": "7th Floor",
    "telefono": "280-837-0797",
    "fechayhora": "2024-02-16 21:10:21",
    "motivo": "Pycnonotus barbatus"
}, {
    "nombre": "Papagena Batha",
    "ubicacion": "5 Mitchell Terrace",
    "habitacion": "5th Floor",
    "telefono": "768-960-8876",
    "fechayhora": "2024-07-07 23:08:34",
    "motivo": "Pseudocheirus peregrinus"
}, {
    "nombre": "Erika Beresford",
    "ubicacion": "330 Morningstar Street",
    "habitacion": "Apt 5",
    "telefono": "698-482-6174",
    "fechayhora": "2024-12-07 01:52:01",
    "motivo": "Macaca radiata"
}]

export const data = [{
    "id": 1,
    "date": "2023-02-03 21:48:48",
    "billname": "DR240001",
    "client": "Currey Kidstoun",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 2,
    "date": "2024-08-02 22:55:37",
    "billname": "DR240002",
    "client": "Vonny Bottomer",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 3,
    "date": "2024-04-21 23:03:47",
    "billname": "DR240003",
    "client": "Burtie Cancelier",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 4,
    "date": "2024-06-12 07:54:01",
    "billname": "DR240004",
    "client": "Ciro Bilbrey",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 5,
    "date": "2023-07-19 02:20:50",
    "billname": "DR240005",
    "client": "Lorens Bunhill",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 6,
    "date": "2024-09-03 03:46:43",
    "billname": "DR240006",
    "client": "Wilmette Harman",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 7,
    "date": "2023-05-10 02:15:44",
    "billname": "DR240007",
    "client": "Austina Ledbury",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 8,
    "date": "2024-06-26 10:06:44",
    "billname": "DR240008",
    "client": "Easter Demeza",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 9,
    "date": "2023-10-10 04:03:51",
    "billname": "DR240009",
    "client": "Orran Rawls",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 10,
    "date": "2023-08-15 09:27:59",
    "billname": "DR240010",
    "client": "Starlin De Castri",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 11,
    "date": "2023-08-13 09:37:17",
    "billname": "DR240011",
    "client": "Ethyl Lucius",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 12,
    "date": "2023-05-28 01:28:21",
    "billname": "DR240012",
    "client": "Cecily Flipek",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 13,
    "date": "2023-06-03 20:51:25",
    "billname": "DR240013",
    "client": "Morrie Bode",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 14,
    "date": "2023-05-02 22:57:13",
    "billname": "DR240014",
    "client": "Georgie Treswell",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 15,
    "date": "2023-12-30 09:58:31",
    "billname": "DR240015",
    "client": "Rodrique Lafoy",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 16,
    "date": "2023-10-18 19:55:28",
    "billname": "DR240016",
    "client": "Velvet Servante",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 17,
    "date": "2024-01-19 14:33:23",
    "billname": "DR240017",
    "client": "Kay McGall",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 18,
    "date": "2024-10-06 15:39:36",
    "billname": "DR240018",
    "client": "Cristina Eallis",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 19,
    "date": "2024-10-31 04:45:20",
    "billname": "DR240019",
    "client": "Wit Alvarado",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 20,
    "date": "2023-05-20 09:06:31",
    "billname": "DR240020",
    "client": "Maddy Lovell",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 21,
    "date": "2024-01-15 09:46:56",
    "billname": "DR240021",
    "client": "Lulita Idel",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 22,
    "date": "2023-05-09 10:33:11",
    "billname": "DR240022",
    "client": "Donny Stealey",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 23,
    "date": "2023-08-11 20:33:52",
    "billname": "DR240023",
    "client": "Giordano Dacey",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 24,
    "date": "2024-10-31 14:16:32",
    "billname": "DR240024",
    "client": "Thom Simioli",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 25,
    "date": "2024-01-02 20:42:22",
    "billname": "DR240025",
    "client": "Lannie Fruin",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 26,
    "date": "2024-02-02 10:25:11",
    "billname": "DR240026",
    "client": "Ailina Kiddle",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 27,
    "date": "2024-11-16 04:06:13",
    "billname": "DR240027",
    "client": "Minor Burges",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 28,
    "date": "2023-11-20 11:09:02",
    "billname": "DR240028",
    "client": "Riobard Rawkesby",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 29,
    "date": "2024-11-20 01:25:32",
    "billname": "DR240029",
    "client": "Misti Blackhurst",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 30,
    "date": "2024-05-04 10:53:41",
    "billname": "DR240030",
    "client": "Ardelis Washbrook",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 31,
    "date": "2024-05-11 04:29:28",
    "billname": "DR240031",
    "client": "Jessy McVanamy",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 32,
    "date": "2024-03-02 06:50:51",
    "billname": "DR240032",
    "client": "Sydney McKenna",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 33,
    "date": "2023-09-02 14:18:50",
    "billname": "DR240033",
    "client": "Alexa Haxby",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 34,
    "date": "2024-03-30 05:04:30",
    "billname": "DR240034",
    "client": "Micky Sayburn",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 35,
    "date": "2024-05-21 07:13:52",
    "billname": "DR240035",
    "client": "Spencer Prendeville",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 36,
    "date": "2024-03-30 15:13:15",
    "billname": "DR240036",
    "client": "Elsinore Marfe",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 37,
    "date": "2024-06-03 17:27:16",
    "billname": "DR240037",
    "client": "Drucie Friskey",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 38,
    "date": "2024-09-30 07:41:32",
    "billname": "DR240038",
    "client": "Carolus Crame",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 39,
    "date": "2024-01-03 15:06:08",
    "billname": "DR240039",
    "client": "Kerrie Pollastro",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 40,
    "date": "2023-07-24 14:23:20",
    "billname": "DR240040",
    "client": "Miran Jollie",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 41,
    "date": "2024-05-10 20:44:43",
    "billname": "DR240041",
    "client": "Archie Bissatt",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 42,
    "date": "2023-08-19 19:10:12",
    "billname": "DR240042",
    "client": "Rosalie Sprull",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 43,
    "date": "2023-05-22 07:37:11",
    "billname": "DR240043",
    "client": "Ryan Tesdale",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 44,
    "date": "2024-04-22 04:06:32",
    "billname": "DR240044",
    "client": "Ezequiel Dougliss",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 45,
    "date": "2024-12-20 08:16:45",
    "billname": "DR240045",
    "client": "Ingaberg Lasseter",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 46,
    "date": "2024-09-19 15:58:16",
    "billname": "DR240046",
    "client": "Madalena Esplin",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 47,
    "date": "2023-06-04 07:21:46",
    "billname": "DR240047",
    "client": "Martainn Deller",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 48,
    "date": "2023-09-09 11:43:23",
    "billname": "DR240048",
    "client": "Kattie Duncklee",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 49,
    "date": "2024-09-13 04:07:06",
    "billname": "DR240049",
    "client": "Hana Lamping",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 50,
    "date": "2024-01-14 22:42:38",
    "billname": "DR240050",
    "client": "Orin Bramwich",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 51,
    "date": "2024-05-05 02:35:29",
    "billname": "DR240051",
    "client": "Cathrine Sillito",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 52,
    "date": "2024-03-31 10:32:33",
    "billname": "DR240052",
    "client": "Alyson Huburn",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 53,
    "date": "2023-12-12 23:46:21",
    "billname": "DR240053",
    "client": "Hank Ransley",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 54,
    "date": "2023-07-08 11:05:18",
    "billname": "DR240054",
    "client": "Elinor Hammel",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 55,
    "date": "2024-03-17 08:59:56",
    "billname": "DR240055",
    "client": "Rebekah Shorto",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 56,
    "date": "2024-02-06 14:07:58",
    "billname": "DR240056",
    "client": "Oriana Slany",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 57,
    "date": "2024-09-17 14:59:02",
    "billname": "DR240057",
    "client": "Quinta Salvin",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 58,
    "date": "2023-06-17 11:29:07",
    "billname": "DR240058",
    "client": "Graehme Saterweyte",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Tarjeta crédito/débito"
}, {
    "id": 59,
    "date": "2023-12-13 01:03:48",
    "billname": "DR240059",
    "client": "Genevieve Palk",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}, {
    "id": 60,
    "date": "2023-10-19 07:27:23",
    "billname": "DR240060",
    "client": "Felicia Quaife",
    "service": "Consulta general",
    "amount": "30.00€",
    "paymentmethod": "Efectivo"
}
];

export const requestdata = [{
    "first_name": "Lynde",
    "last_name": "Hyatt",
    "email": "lhyatt0@webeden.co.uk",
    "phone": "726 960 2621",
    "document": "9D46H98AJ58",
    "type": "Hotel"
}, {
    "first_name": "Charyl",
    "last_name": "Lermit",
    "email": "clermit1@theglobeandmail.com",
    "phone": "913 180 6899",
    "document": "9WU4QT9VQ51",
    "type": "Hotel"
}, {
    "first_name": "Opaline",
    "last_name": "McKue",
    "email": "omckue2@arstechnica.com",
    "phone": "422 538 8543",
    "document": "5AQ7P90VR13",
    "type": "Medico"
}, {
    "first_name": "Floria",
    "last_name": "Whitwell",
    "email": "fwhitwell3@aol.com",
    "phone": "652 527 7825",
    "document": "7PH7AN0KY71",
    "type": "Hotel"
}, {
    "first_name": "Melvin",
    "last_name": "Blight",
    "email": "mblight4@icio.us",
    "phone": "207 655 7741",
    "document": "9K26HC6PP83",
    "type": "Administrador"
}, {
    "first_name": "Thadeus",
    "last_name": "Voce",
    "email": "tvoce5@netlog.com",
    "phone": "584 685 9973",
    "document": "6WW4DE6FD14",
    "type": "Hotel"
}, {
    "first_name": "Leese",
    "last_name": "Robertsen",
    "email": "lrobertsen6@wiley.com",
    "phone": "150 461 3105",
    "document": "3V44KU3HA18",
    "type": "Hotel"
}, {
    "first_name": "Roxi",
    "last_name": "Candie",
    "email": "rcandie7@yelp.com",
    "phone": "511 581 4837",
    "document": "6JN8E78DJ50",
    "type": "Medico"
}, {
    "first_name": "Balduin",
    "last_name": "Bletso",
    "email": "bbletso8@about.com",
    "phone": "848 292 7791",
    "document": "3EP5C28AH78",
    "type": "Medico"
}, {
    "first_name": "Van",
    "last_name": "Garrity",
    "email": "vgarrity9@latimes.com",
    "phone": "719 398 3585",
    "document": "8HA4RQ7FW96",
    "type": "Medico"
}, {
    "first_name": "Natty",
    "last_name": "Perago",
    "email": "nperagoa@acquirethisname.com",
    "phone": "797 805 8757",
    "document": "1F69A45TR58",
    "type": "Hotel"
}, {
    "first_name": "Brocky",
    "last_name": "Soot",
    "email": "bsootb@reference.com",
    "phone": "798 988 9985",
    "document": "6H12NX5CP54",
    "type": "Medico"
}, {
    "first_name": "Aguistin",
    "last_name": "Mizen",
    "email": "amizenc@goo.ne.jp",
    "phone": "960 997 4508",
    "document": "2QU8DK6CA12",
    "type": "Medico"
}, {
    "first_name": "Genevieve",
    "last_name": "Clohessy",
    "email": "gclohessyd@sun.com",
    "phone": "432 542 8757",
    "document": "7YY2D03KV57",
    "type": "Medico"
}, {
    "first_name": "Osmond",
    "last_name": "Ivett",
    "email": "oivette@e-recht24.de",
    "phone": "718 801 2250",
    "document": "7PD0QF7XR44",
    "type": "Medico"
}, {
    "first_name": "Mychal",
    "last_name": "Pollendine",
    "email": "mpollendinef@flickr.com",
    "phone": "922 175 3901",
    "document": "6FN4YU1UP44",
    "type": "Medico"
}, {
    "first_name": "Loutitia",
    "last_name": "Davidou",
    "email": "ldavidoug@ted.com",
    "phone": "138 518 5011",
    "document": "3QG3FR9FW55",
    "type": "Hotel"
}, {
    "first_name": "Jarrod",
    "last_name": "Allitt",
    "email": "jallitth@nih.gov",
    "phone": "429 411 3544",
    "document": "1Y04XE0WE17",
    "type": "Administrador"
}, {
    "first_name": "Dame",
    "last_name": "Gingel",
    "email": "dgingeli@sitemeter.com",
    "phone": "738 841 5303",
    "document": "6XD5VA1RK41",
    "type": "Medico"
}, {
    "first_name": "Marigold",
    "last_name": "Keely",
    "email": "mkeelyj@dot.gov",
    "phone": "744 298 6832",
    "document": "2C31N10FN30",
    "type": "Administrador"
}]

export const rectifiedbill = [
    {
        "id": "1",
        "rectname": "DRR240001",
        "reference": "DR240012",
        "updatedAt": "2024-04-26 08:53:22",
        "reason": "Error en alguno de los datos profesionales de la factura",
    },
    {
        "id": "2",
        "rectname": "DRR240002",
        "reference": "DR240024",
        "updatedAt": "2024-04-26 10:30:22",
        "reason": "Ausencia de información relevante en el documento",
    },
]

export const medicdata = [
    {
        id: 1,
        name: "Eduardo",
        surname: "Hernández",
        HourStart: "11:12 AM",
        HourEnd: "8:06 PM",
        cAExpedition: "5 Schlimgen Crossing",
        phone: "9985910699",
        address: "202 Del Sol Avenue"
    },
    {
        id: 2,
        name: "Carmen",
        surname: "López",
        HourStart: "9:32 AM",
        HourEnd: "7:57 PM",
        cAExpedition: "73069 Stoughton Park",
        phone: "5404521281",
        address: "9414 Kingsford Pass"
    },
    {
        id: 3,
        name: "Manuel",
        surname: "Rodríguez",
        HourStart: "11:48 AM",
        HourEnd: "12:22 PM",
        cAExpedition: "326 Victoria Way",
        phone: "2659525902",
        address: "16259 Muir Trail"
    },
    {
        id: 4,
        name: "Ana",
        surname: "González",
        HourStart: "11:23 AM",
        HourEnd: "9:33 PM",
        cAExpedition: "13 Anhalt Hill",
        phone: "1515339990",
        address: "48601 Cherokee Circle"
    },
    {
        id: 5,
        name: "Teresa",
        surname: "Martínez",
        HourStart: "6:29 AM",
        HourEnd: "6:57 PM",
        cAExpedition: "00238 Golf View Hill",
        phone: "7023093791",
        address: "0374 Banding Court"
    },
    {
        id: 6,
        name: "Roberto",
        surname: "Alonso",
        HourStart: "11:27 AM",
        HourEnd: "1:47 PM",
        cAExpedition: "80501 East Trail",
        phone: "3989563278",
        address: "9 Badeau Plaza"
    },
    {
        id: 7,
        name: "Laura",
        surname: "Fernández",
        HourStart: "10:11 AM",
        HourEnd: "10:51 PM",
        cAExpedition: "11574 Arrowood Hill",
        phone: "2253500950",
        address: "167 Troy Pass"
    },
    {
        id: 8,
        name: "Ricardo",
        surname: "Gutiérrez",
        HourStart: "10:12 AM",
        HourEnd: "9:13 PM",
        cAExpedition: "926 Vermont Junction",
        phone: "9836592967",
        address: "6697 Mendota Alley"
    },
    {
        id: 9,
        name: "María",
        surname: "Moreno",
        HourStart: "7:15 AM",
        HourEnd: "3:06 PM",
        cAExpedition: "474 Westport Road",
        phone: "6014969050",
        address: "1 Nova Plaza"
    },
    {
        id: 10,
        name: "Javier",
        surname: "Sánchez",
        HourStart: "6:12 AM",
        HourEnd: "6:21 PM",
        cAExpedition: "28 Ridgeview Parkway",
        phone: "8576207461",
        address: "27 Shelley Court"
    }
]

export const accessdata = [
    {
        "id": 1,
        "macaddress": "1C-A7-64-68-F2-B5",
        "ipaddress": "241.212.211.234",
        "date": "08-10-2024"
    }, {
        "id": 2,
        "macaddress": "33-0B-A8-E7-2C-51",
        "ipaddress": "183.23.23.156",
        "date": "23-06-2024"
    }, {
        "id": 3,
        "macaddress": "8F-A7-70-AF-5D-60",
        "ipaddress": "145.168.196.249",
        "date": "22-11-2024"
    }, {
        "id": 4,
        "macaddress": "3F-4D-B3-B7-E0-E3",
        "ipaddress": "65.205.38.36",
        "date": "30-06-2024"
    }, {
        "id": 5,
        "macaddress": "11-16-F0-6B-B0-CE",
        "ipaddress": "187.32.26.98",
        "date": "24-08-2024"
    }, {
        "id": 6,
        "macaddress": "CF-05-4D-37-19-75",
        "ipaddress": "193.77.237.38",
        "date": "14-09-2024"
    }, {
        "id": 7,
        "macaddress": "B2-E6-38-2C-3C-36",
        "ipaddress": "87.233.181.223",
        "date": "27-10-2024"
    }, {
        "id": 8,
        "macaddress": "D3-55-9F-D7-E5-77",
        "ipaddress": "56.187.112.12",
        "date": "02-07-2024"
    }, {
        "id": 9,
        "macaddress": "FE-2F-4A-37-F3-D0",
        "ipaddress": "78.162.82.91",
        "date": "13-08-2024"
    }, {
        "id": 10,
        "macaddress": "2C-EB-D4-27-D9-DB",
        "ipaddress": "90.20.88.25",
        "date": "09-05-2024"
    }
]