// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: #0D1726;
}

.graphfilter-date .date-option {
  display: flex;
  gap: 2rem;
}

.date-option .bobo {
  display: flex;
  gap: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;EACA,WAAA;AACJ","sourcesContent":[".dashboard {\n    grid-area: main;\n    overflow-y: auto;\n    padding: 20px 20px;\n    color: #0D1726;\n}\n\n.graphfilter-date .date-option {\n    display: flex;\n    gap: 2rem;\n}\n\n.date-option .bobo {\n    display: flex;\n    gap: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
