import React from 'react';
import Modal from '../../utilities/Modal';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const SuccessTriage = ({ editModal, setEditModal, identificator, resetValues }: any) => {
    const handleBack = () => {
        setEditModal(false);
        resetValues();
    }
    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="popup-delete">
                <div className="popup-delete-message">
                    <IoIosCheckmarkCircleOutline className='success-check' />
                    <h3>El usuario y el triaje se han creado satisfactoriamente.</h3>
                    <h3>Su identificador es: {identificator}</h3>
                </div>
                <div className='popup-delete-buttons'>
                    <button type="button" onClick={handleBack} className="cancel-button">Volver</button>
                </div>
            </div>
        </Modal>

    )
}

export default SuccessTriage
