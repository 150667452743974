import React, { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch';
import './AssignService.scss';
import { FaCheck, FaSearch, FaTimes } from 'react-icons/fa';
import Modal from '../../utilities/Modal';
import { toast } from 'react-toastify';
interface Service {
    id: number;
    code: string;
    name: string;
    description: string;
    price: number;
    tax: number;
}

interface Commission {
    id: number;
    name: string;
    comissionType: number;
    commission: number;
}

const AssignService = ({ editModal, setEditModal, refetch, selectedCompany }: any) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: services } = useFetch(`${apiUrl}/admin/get/allservices`);
    const { data: comission } = useFetch(`${apiUrl}/admin/get/allcommissions`);
    const [selectedServices, setSelectedServices] = useState<{ [key: number]: number }>({});
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredServices, setFilteredServices] = useState<Service[]>([]);
    const [commissions, setComissions] = useState<Commission[]>([]);

    useEffect(() => {
        if (services) {
            setFilteredServices(services);
        }

        if (comission) {
            setComissions(comission)
        }
    }, [comission, services]);

    useEffect(() => {
        if (services) {
            const filtered = services.filter(
                (service: any) =>
                    service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    service.code.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredServices(filtered);
        }
    }, [searchTerm, services]);

    const handleServiceSelect = (serviceId: number, commissionId: number) => {
        setSelectedServices((prev) => ({
            ...prev,
            [serviceId]: commissionId,
        }));
    };

    const handleAssign = async () => {
        const assignments = Object.entries(selectedServices).map(([serviceId, commissionId]) => ({
            serviceId: Number(serviceId),
            commissionId,
        }));

        const serviceIds = assignments.map(a => a.serviceId);
        const commissionIds = assignments.map(a => a.commissionId);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found.');
                return;
            }

            const response = await fetch(`${apiUrl}/admin/assign/CompanyServices`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    servicesId: serviceIds,
                    commissionId: commissionIds,
                    guid: selectedCompany.guid
                }),
            });

            if (!response.ok) {
                throw new Error('Error assigning services to company');
            }

            toast.success("Servicios asignados correctamente");
            setEditModal(false)
            refetch()

        } catch (error) {
            console.error('Error:', error);
            toast.error("Error al asignar servicios a la empresa");
        }

    };
    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="as-modal-overlay">
                <div className="as-modal-content">
                    <div className="as-modal-header">
                        <h2 className="as-modal-title">Asignar Servicios y Comisiones</h2>
                        <button onClick={() => setEditModal(false)} className="as-close-button">
                            <FaTimes size={24} />
                        </button>
                    </div>

                    <div className="as-search-container">
                        <input
                            type="text"
                            placeholder="Buscar servicios..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="as-search-input"
                        />
                        <FaSearch className="as-search-icon" />
                    </div>

                    <div className="as-table-container">
                        <table className="as-service-table">
                            <thead>
                                <tr>
                                    <th className="as-table-header">Código</th>
                                    <th className="as-table-header">Nombre</th>
                                    <th className="as-table-header">Precio</th>
                                    <th className="as-table-header">Comisión</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredServices.map((service) => (
                                    <tr
                                        key={service.id}
                                        className={`as-table-row ${selectedServices[service.id] ? 'selected' : ''}`}
                                    >
                                        <td className="as-table-cell">{service.code}</td>
                                        <td className="as-table-cell">{service.name}</td>
                                        <td className="as-table-cell">{service.price.toFixed(2)}€</td>
                                        <td className="as-table-cell">
                                            <select
                                                value={selectedServices[service.id] || ''}
                                                onChange={(e) => handleServiceSelect(service.id, Number(e.target.value))}
                                                className="as-commission-select"
                                            >
                                                <option value="">Seleccionar Comisión</option>
                                                {commissions?.map((commission) => (
                                                    <option key={commission.id} value={commission.id}>
                                                        {commission.name} - {commission.comissionType === 0 ? `intervalo%` : `${commission.commission}€`}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="as-button-container">
                        <button
                            onClick={handleAssign}
                            className="as-assign-button"
                            disabled={Object.keys(selectedServices).length === 0}
                        >
                            <FaCheck className="as-check-icon" /> Asignar Servicios Seleccionados
                        </button>
                    </div>
                </div>
            </div>
        </Modal>

    );
};


export default AssignService
