import React from 'react';
import "./Verify.scss";
import Modal from '../../utilities/Modal';
import { LiaTimesSolid } from 'react-icons/lia';

const Verify = ({ editModal, setEditModal, rowdata }: any) => {
    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className='verificar'>

                <div className='rheader'>
                    <h2>Datos de solicitud: <span>{rowdata.first_name + " " + rowdata.last_name}</span></h2>
                    <button onClick={() => setEditModal(false)} className='close-modal'>
                        <LiaTimesSolid />
                    </button>
                </div>

                <div className='group'>
                    <span className='subgroup'>
                        <label htmlFor="Fclient">Nombre:</label>
                        <input type="text" value={rowdata.first_name} readOnly />
                    </span>

                    <span className='subgroup'>
                        <label htmlFor="Fdate">Apellidos:</label>
                        <input type="text" value={rowdata.last_name} readOnly />
                    </span>
                </div>

                <div className='group'>
                    <span className='subgroup'>
                        <label htmlFor="Fservice">Correo:</label>
                        <input type="text" value={rowdata.email} readOnly />
                    </span>

                    <span className='subgroup'>
                        <label htmlFor="Famount">Telefono:</label>
                        <input type="text" value={rowdata.phone} readOnly />
                    </span>

                </div>

                <div className='group'>
                    <span className='subgroup'>
                        <label htmlFor="Fservice">Documento:</label>
                        <input type="text" value={rowdata.document} readOnly />
                    </span>

                    <span className='subgroup'>
                        <label htmlFor="Famount">ROL:</label>
                        <input type="text" value={rowdata.type} readOnly />
                    </span>

                </div>

                <div className='buttongroup'>
                    <button type="button" className="deny-button">
                        Rechazar
                    </button>
                    <button type="button" className="accept-button">
                        Aceptar
                    </button>
                </div>

            </div>
        </Modal>
    )
}

export default Verify
