import React from 'react';
import "./Loading.scss";
import loading from '../../assets/loading.gif';

const Loading = () => {
    return (
        <div className='loading-gif'>
            <img src={loading} alt="loading" />
        </div>
    )
}

export default Loading
