import React, { useState } from 'react';
import "./Reqtables.scss";
import { requestdata } from "../../data/data";
import DataTable from 'react-data-table-component';
import Verify from '../actions/Verify';

const Reqtables = () => {
    const [records, setRecords] = useState(requestdata);
    const [rowData, setRowData] = useState<any>([]);
    const [view, setView] = useState(false);

    const columns = [
        {
            name: "Nombre completo",
            selector: (row: any) => row.first_name + " " + row.last_name,
            sortable: true,
            grow: 2,
        },
        {
            name: "Telefono",
            selector: (row: any) => row.phone,
            sortable: true,

        },
        {
            name: "Correo",
            selector: (row: any) => row.email,
            sortable: true,

        },
        {
            name: "Tipo",
            selector: (row: any) => row.type,
            sortable: true,
        },
        {
            name: "Acciones",
            width: "140px",
            cell: (row: any) => (
                <div className='action-dropdown'>
                    <button
                        className="action-button"
                        onClick={() => { handleClick(row) }}
                        id={row.id}>
                        Ver
                    </button>

                </div>
            )
        },

    ];
    const handleClick = (row: any) => {
        setRowData(row)
        setView(true);

    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const handleFilter = (event: any) => {
        const newData = requestdata.filter(row => {
            return (row.first_name + " " + row.last_name).toLowerCase().includes(event.target.value.toLowerCase());
        })
        setRecords(newData);
    }

    return (
        <div className="table-bill">
            <div className="table-container">
                <span className='table-name'>Solicitudes pendientes ({requestdata.length})</span>

                {/* <button className='generate' onClick={monthlyPDF}>Descargar este mes</button> */}
                <div className='rtable-addon'>
                    <input type="text" placeholder='buscar por nombre' onChange={handleFilter} />
                </div>
            </div>
            <DataTable
                columns={columns}
                data={records}
                pagination paginationComponentOptions={paginationComponentOptions}

            />

            {view && (
                <Verify
                    rowdata={rowData}
                    editModal={view}
                    setEditModal={setView} />
            )}
        </div>
    )
}

export default Reqtables
