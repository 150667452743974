import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import { MdEditNote } from 'react-icons/md';
import moment from 'moment';
import MarkasDone from '../actions/MarkasDone';
import { Socket } from 'socket.io-client';
import useWebSocket from '../../hooks/useWebsocket';
import Button from '../../utilities/Button';

const Referrals = () => {
    // const apiUrl = process.env.REACT_APP_API_URL;
    // const { data, refetch } = useFetch(`${apiUrl}/hospital/referrals`);
    const [records, setRecords] = useState([]);

    const [rowData, setRowData] = useState<any>([]);
    const [attended, setAttended] = useState(false);

    const token = localStorage.getItem('token')!;
    const socketurl = process.env.REACT_APP_WEBSOCKET!;
    const { registerEvent, emitEvent } = useWebSocket(token, socketurl);
    const [data, setData] = useState<any>(null);
    const socketRef = useRef<Socket | null>(null);

    useEffect(() => {
        const handleHospitalData = (data: any) => {
            setData(data);
        };

        registerEvent('hospitalData', handleHospitalData);

        const fetchData = async () => {
            emitEvent('hospitalData', { hospitalData: 'HDg94k75sd' });
        };

        fetchData();

        return () => {
            // Limpia el event listener cuando el componente se desmonta
            if (socketRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                socketRef.current.off('hospitalData', handleHospitalData);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        data && setRecords(data.filter((row: any) => row.hospitalReception === null)); // Initialize state with the fetched data
    }, [data]);

    // const socketurl = process.env.REACT_APP_WEBSOCKET;
    // const token = localStorage.getItem('token');
    // useEffect(() => {
    //     const socket = io(`${socketurl}`, {
    //         transports: ['websocket'],
    //         withCredentials: true,
    //         reconnection: true,
    //         auth: {
    //             token: `${token}`
    //         }
    //     })

    //     socket.on('connect', () => {
    //         console.log('Connected referrals')
    //         socket.emitWithAck('hospitalData', { triages: "HDg94k75sd" })
    //     })


    //     socket.on('hospitalData', (arg) => {
    //         if (!arg || arg == null) {
    //             console.log('No hay datos entrantes')
    //         } else {
    //             setRecords(arg)
    //         }
    //     })
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const columns = [
        {
            name: "Paciente",
            selector: (row: any) => row.patientName,
            sortable: true,

        },
        {
            name: "Identificación",
            selector: (row: any) => row.patientId,
            sortable: true,

        },
        {
            name: "Estado",
            selector: (row: any) => {
                if (row.hospitalReception === null) {
                    return "Pendiente";
                } else if (row.hospitalReception) {
                    return "Atendido";
                }
            },
            sortable: true,
        },
        {
            name: "Fecha solicitud",
            selector: (row: any) => moment(row.ambulanceHour).utc().format(('DD/MM/YYYY hh:mm:ss')),
            sortable: true,

        },
        {
            name: "Motivo",
            selector: (row: any) => row.reason,
            sortable: true,
            wrap: true,

        },
        {
            name: "Acciones",
            cell: (row: any) => (
                <div className='action-dropdown'>
                    <Button
                        click={() => { setRowData(row); setAttended(true) }}
                        title={row.hospitalReception === null ? "Marcar como atendido" : "Atendido"}
                        icon={<MdEditNote />}
                        disabled={row.hospitalReception} />

                </div>
            )
        },
    ];


    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const [activeButton, setActiveButton] = useState<string>('pendientes');
    const handleButtonClick = (buttonName: string) => {
        setActiveButton(buttonName);

        if (!data) {
            setRecords([]);
            return;
        } else if (buttonName === 'todos') {
            setRecords(data);
        } else if (buttonName === 'pendientes') {
            const filteredData = data.filter((row: any) => row.hospitalReception === null);
            setRecords(filteredData);
        } else if (buttonName === 'atendido') {
            const filteredData = data.filter((row: any) => row.hospitalReception !== null);
            setRecords(filteredData);
        }
    };
    return (
        <div className="table-bill">
            <div className="table-container">
                <span className='table-name'>Solicitudes Ambulancia</span>
            </div>
            <div className='tablefilter'>
                <div className='demandbuttons'>
                    <button
                        className={activeButton === 'todos' ? 'active' : ''}
                        onClick={() => handleButtonClick('todos')}
                    >
                        Todos
                    </button>
                    <button
                        className={activeButton === 'pendientes' ? 'active' : ''}
                        onClick={() => handleButtonClick('pendientes')}
                    >
                        Pendientes
                    </button>
                    <button
                        className={activeButton === 'atendido' ? 'active' : ''}
                        onClick={() => handleButtonClick('atendido')}
                    >
                        Atendidos
                    </button>

                </div>
            </div>
            <DataTable
                columns={columns}
                data={records}
                pagination paginationComponentOptions={paginationComponentOptions}
                noDataComponent="No hay ningún dato que mostrar"
            />
            {attended && (
                <MarkasDone
                    rowData={rowData}
                    editModal={attended}
                    setEditModal={setAttended}
                />
            )}
        </div>
    )
}

export default Referrals
