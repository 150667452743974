// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verificar {
  z-index: 20;
  background-color: #1d2634;
  width: 95%;
  margin-bottom: 3rem;
  padding: 2rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 3px 0.3px rgb(182, 182, 182);
}

@media (min-width: 768px) {
  .verificar {
    width: 45rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/actions/Verify.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,yBAAA;EACA,UAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,gDAAA;AACJ;;AAEA;EACI;IACI,YAAA;EACN;AACF","sourcesContent":[".verificar {\n    z-index: 20;\n    background-color: #1d2634;\n    width: 95%;\n    margin-bottom: 3rem;\n    padding: 2rem;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    box-shadow: 0px 0px 3px 0.3px rgb(182, 182, 182);\n}\n\n@media (min-width: 768px) {\n    .verificar {\n        width: 45rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
