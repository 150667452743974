// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-modal {
  position: absolute;
  right: 0;
  z-index: 50;
  padding: 1.5rem;
  border-radius: 0.375rem;
  color: #1d2634;
  background-color: #fff;
  width: 16rem;
  top: 100%;
  box-shadow: 0px 0px 3px 0.3px rgb(182, 182, 182);
}

.user-modal-menu {
  display: flex;
  padding-bottom: 1.25rem;
  flex-direction: column;
  gap: 1rem;
}

.user-modal-content {
  display: flex;
  gap: 1rem;
  text-decoration: none;
  color: #1d2634;
  padding-left: 0.5rem;
}
.user-modal-content span {
  font-size: 1.5rem;
  line-height: 2rem;
}
.user-modal-content h2 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.logout-button {
  border: none;
  background-color: #fff;
  gap: 1rem;
  cursor: pointer;
  display: flex;
  color: #1d2634;
  padding-left: 0.5rem;
}
.logout-button span {
  font-size: 1.5rem;
  line-height: 2rem;
}
.logout-button h2 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.user-modal-content:hover {
  background-color: #F8F8F8;
  border-radius: 1rem;
}

.logout-button:hover {
  background-color: #F8F8F8;
  border-radius: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/nav/UserModal.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,WAAA;EACA,eAAA;EACA,uBAAA;EACA,cAAA;EACA,sBAAA;EACA,YAAA;EACA,SAAA;EACA,gDAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAGA;EACI,aAAA;EACA,SAAA;EACA,qBAAA;EACA,cAAA;EACA,oBAAA;AAAJ;AAEI;EACI,iBAAA;EACA,iBAAA;AAAR;AAII;EACI,mBAAA;EACA,oBAAA;AAFR;;AAMA;EACI,YAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;EACA,aAAA;EACA,cAAA;EACA,oBAAA;AAHJ;AAKI;EACI,iBAAA;EACA,iBAAA;AAHR;AAMI;EACI,mBAAA;EACA,oBAAA;AAJR;;AAQA;EACI,yBAAA;EACA,mBAAA;AALJ;;AAQA;EACI,yBAAA;EACA,mBAAA;AALJ","sourcesContent":[".user-modal {\n    position: absolute;\n    right: 0;\n    z-index: 50;\n    padding: 1.5rem;\n    border-radius: 0.375rem;\n    color: #1d2634;\n    background-color: #fff;\n    width: 16rem;\n    top: 100%;\n    box-shadow: 0px 0px 3px 0.3px rgb(182, 182, 182);\n}\n\n.user-modal-menu {\n    display: flex;\n    padding-bottom: 1.25rem;\n    flex-direction: column;\n    gap: 1rem;\n\n}\n\n.user-modal-content {\n    display: flex;\n    gap: 1rem;\n    text-decoration: none;\n    color: #1d2634;\n    padding-left: 0.5rem;\n\n    span {\n        font-size: 1.5rem;\n        line-height: 2rem;\n\n    }\n\n    h2 {\n        font-size: 1.125rem;\n        line-height: 1.75rem;\n    }\n}\n\n.logout-button {\n    border: none;\n    background-color: #fff;\n    gap: 1rem;\n    cursor: pointer;\n    display: flex;\n    color: #1d2634;\n    padding-left: 0.5rem;\n\n    span {\n        font-size: 1.5rem;\n        line-height: 2rem;\n    }\n\n    h2 {\n        font-size: 1.125rem;\n        line-height: 1.75rem;\n    }\n}\n\n.user-modal-content:hover {\n    background-color: #F8F8F8;\n    border-radius: 1rem;\n}\n\n.logout-button:hover {\n    background-color: #F8F8F8;\n    border-radius: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
