import React, { useState } from 'react';
import "./TriagePage.scss";
import Triagetable from '../components/tables/Triagetable';
import Navbar from '../components/nav/Navbar';
import Sidebar from '../components/nav/Sidebar';
import UploadAnalytics from '../components/actions/UploadAnalytics';

const TriagePage = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    const [uploadAnal, setUploadAnal] = useState(false);
    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>
            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

            <div className='triage'>
                <div className='triage-ontop'>
                    <h2>Tabla de Consultas</h2>
                    <button className='upload-analytics' onClick={() => setUploadAnal(true)}>Nueva Analítica</button>
                </div>
                <Triagetable setUploadAnal={setUploadAnal} />
            </div>
            {uploadAnal && (
                <UploadAnalytics
                    editModal={uploadAnal}
                    setEditModal={setUploadAnal} />
            )}
        </div>
    )
}

export default TriagePage
