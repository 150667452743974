import "./Bill.scss";
import Ftables from '../components/tables/Ftables';
import { useState } from "react";
import Navbar from "../components/nav/Navbar";
import Sidebar from "../components/nav/Sidebar";


const Bill = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className='facturas'>
                <Ftables />
            </div>
        </div>
    )
}

export default Bill