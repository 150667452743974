import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import "./Ftables.scss";
import 'jspdf-autotable';
import moment from 'moment';
import { MdEditNote, MdFileDownload, MdOutlineCheckBox } from "react-icons/md";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from 'date-fns/locale/es';
// import { LiaTimesSolid } from 'react-icons/lia';
import useFetch from '../../hooks/useFetch';
import { Link } from 'react-router-dom';
import Amend from '../actions/Amend';
import { User } from '../../context/Context';
// import Select from 'react-select';
import jsPDFInvoiceTemplate from "jspdf-invoice-template";
import JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import MarkasPaid from '../actions/MarkasPaid';
import { BsSearch } from 'react-icons/bs';
import Loading from '../loading/Loading';
import CustomDateInput from '../../utilities/CustomDateInput';
import Reimbursement from '../actions/Reimbursement';
import Button from '../../utilities/Button';
import ManualInvoice from '../actions/ManualInvoice';
registerLocale('es', es);
// interface Option {
//     value: string,
//     label: string;
// }

const Ftables = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data } = useFetch(`${apiUrl}/accountant/invoice`);
    // const { data: brokers } = useFetch(`${apiUrl}/info/company`);
    const [isLoading, setIsLoading] = useState(false);
    const [rectify, setRectify] = useState(false);
    const [reimbursement, setReimbursement] = useState(false);
    const [rowData, setRowData] = useState<any>([]);
    const { startDate, setStartDate, endDate, setEndDate } = User();
    const [records, setRecords] = useState([]); // New state for filtered data
    const [defaults, setDefaults] = useState([]);
    const [changeStatus, setChangeStatus] = useState(false);
    // const [brokersValue, setBrokersValue] = useState<Option | null>(null);
    const [createInvoice, setCreateInvoice] = useState(false);

    console.log(records)
    useEffect(() => {
        defaults && setRecords(defaults); // Initialize state with the fetched data
    }, [defaults]);

    useEffect(() => {
        if (data) {
            // Filtrar facturas dentro del intervalo de fechas especificado
            const filteredInvoices = data.filter((invoice: any) => {
                const invoiceDate = new Date(invoice.createAt);
                return invoiceDate >= startDate && invoiceDate <= endDate;
            });

            // Actualizar los datos filtrados
            setDefaults(filteredInvoices);
        }
    }, [startDate, endDate, data]);

    const handleFilter = (event: any) => {
        const newData = data.filter((row: any) => {
            return (row.UserName + " " + row.invoiceName + " " + row.paymentMethod).toLowerCase().includes(event.target.value.toLowerCase());
        })
        setRecords(newData);
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const downloadZIP = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const zip = new JSZip();
            const promises = records.filter((row: any) => row.status !== 2).map(async (row: any) => {
                const response = await fetch(`${apiUrl}/user/invoicepdf/${row.guid}`, {
                    method: "GET",
                    headers: {
                        'Authorization': token,
                    }
                })
                const data = await response.blob();
                zip.file(`${row.invoiceName}.pdf`, data);
            });

            await Promise.all(promises);

            zip.generateAsync({ type: 'blob' }).then(function (blob) {
                FileSaver.saveAs(blob, 'facturas_DR.zip');
            });

            setIsLoading(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase())
            .replace(/\b([À-ÿ])/g, (match) => match.toLowerCase());
    }

    const columns = [
        {
            name: "Factura",
            cell: (row: any) => (
                <div>
                    <span>
                        {row.invoiceName}
                    </span>
                    {row.rectified && (
                        <Link to={"/rectificativa"} style={{ textDecoration: "none" }}>
                            <span style={{ color: 'red', display: 'block', fontSize: '13px', marginTop: '5px' }}>
                                Rectificada
                            </span>
                        </Link>
                    )
                    }
                </div >
            ),
            sortable: true,
        },
        {
            name: "F. Emisión",
            selector: (row: any) => moment(row.createAt).format(('DD/MM/YYYY')),
            sortable: true,
        },
        {
            name: "Cliente",
            selector: (row: any) => capitalizeWords(row.UserName),
            sortable: true,
        },
        {
            name: "Cobertura",
            selector: (row: any) => {
                if (row.UserGroup === 0) {
                    return "No Asegurado";
                } else if (row.UserGroup === 1) {
                    return "No Cubierto";
                } else if (row.UserGroup === 2) {
                    return "Coaseguro";
                } else if (row.UserGroup === 3) {
                    return "Asegurado";
                }
            },
            sortable: true,
        },
        // {
        //     name: "Comisionistas",
        //     selector: (row: any) => row.services.flatMap((service: any) => service.Service.company.map((company: any) => company.company.name)).join(', '),
        //     sortable: true,
        //     wrap: true,
        // },
        {
            name: "Importe",
            selector: (row: any) => row.amount,
            sortable: true,
        },
        {
            name: "Estado",
            selector: (row: any) => {
                if (row.status === 0) {
                    return "Pendiente";
                } else if (row.status === 1) {
                    return "Pagado";
                } else if (row.status === 2) {
                    return "Rechazado";
                }
            },
            sortable: true,
        },
        {
            name: "Método",
            selector: (row: any) => row.paymentMethod,
            sortable: true,
        },
        {
            name: "Acciones",
            cell: (row: any) => (
                <div className='action-dropdown'>
                    <Button click={() => handleRectify(row)} title="Rectificar" icon={<MdEditNote />} disabled={row.rectified || row.status === 2} />
                    <Button click={() => handleMark(row)} title="Marcar como pagado" icon={<MdOutlineCheckBox />} disabled={row.status !== 0} />
                    <Button click={() => handleDownload(row)} title="Descargar" icon={<MdFileDownload />} disabled={false} />
                </div>
            )
        },
    ];
    const handleRectify = (row: any) => {
        setRowData(row)
        setRectify(true);
        // setShowDrop(false);

    };

    const handleDownload = async (row: any) => {
        console.log(row)
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            await fetch(`${apiUrl}/user/invoicepdf/${row.guid}`, {
                method: "GET",
                headers: {
                    'Authorization': token,
                }
            })
                // .then(res => res.blob())
                // .then(blob => {
                //     var file = window.URL.createObjectURL(blob);
                //     window.location.assign(file);
                // });
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = `${row.invoiceName}.pdf`;
                    document.body.appendChild(a); // tenemos que añadir el elemento a la dom -> de lo contrario no funcionará en firefox
                    a.click();
                    a.remove();  ////después volvemos a eliminar el elemento            
                });
            setIsLoading(false);


        } catch (error) {
            console.error('Error:', error);
        }
    };
    const totalAmount = records.filter((record: any) => (record.status === 1 || record.status === 0)).reduce((total: number, invoice: any) => total + parseFloat(invoice.amount), 0);

    const igicAmount = (totalAmount * 7) / 100;

    const exportReport = () => {
        jsPDFInvoiceTemplate(propsObject);
    }
    var propsObject = {
        outputType: "save",
        returnJsPDFDocObject: true,
        fileName: "Informe_Facturas_2024",
        orientationLandscape: false,
        compress: true,
        logo: {
            src: "/dr2u-logo.png",
            width: 40, //aspect ratio = width/height
            height: 40,
            margin: {
                top: -8, //negative or positive num, from the current position
                left: 0 //negative or positive num, from the current position
            }
        },
        stamp: {
            inAllPages: true,
            src: "https://raw.githubusercontent.com/edisonneza/jspdf-invoice-template/demo/images/qr_code.jpg",
            width: 20, //aspect ratio = width/height
            height: 20,
            margin: {
                top: 0, //negative or positive num, from the current position
                left: 0 //negative or positive num, from the current position
            }
        },
        business: {
            name: "Emprende Soluciones y Servicios 33, S.L.",
            address: "C/ Rafael Cabrera 7, Trasera Local 20, 35002",
            phone: "Las Palmas de Gran Canaria",
            email: "928 986 872",
            website: "info@dr2u.com",
            email_1: "www.dr2u.es",
        },
        contact: {
            label: "Factura emitida por:",
            name: "DR2U Contabilidad",
            address: "Avda. Rafael Cabrera, 7 (Trasera Local 20)",
            phone: "Las Palmas de Gran Canaria",
            email: "(+34) 928 986 872",
            otherInfo: "info@dr2u.com",
        },
        invoice: {
            label: "Informe de Facturas ",
            num: 2024,
            invDate: `Clasificado desde: ${moment(startDate).format(('DD/MM/YYYY'))}`,
            invGenDate: `Clasificado hasta: ${moment(endDate).format(('DD/MM/YYYY'))}`,
            headerBorder: true,
            tableBodyBorder: true,
            header: [
                {
                    title: "#",
                    style: {
                        width: 10
                    }
                },
                {
                    title: "Factura",
                    style: {
                        width: 30
                    }
                },
                {
                    title: "Fecha",
                    style: {
                        width: 30
                    }
                },
                {
                    title: "Cliente - Servicio",
                    style: {
                        width: 40
                    }
                },
                // { title: "Estado" },
                { title: "Base" },
                { title: "IGIC" },
                { title: "Total" }
            ],
            // no se tiene en cuenta las facturas rechazadas 
            table: records.map((col: any, index) => ([
                index + 1,
                `${col.rectified ? col.invoiceName + " rectificada" : col.invoiceName}`,
                `${moment(col.createAt).format(('DD/MM/YYYY'))}`,
                `${col.UserName} - ${col.services.map((service: any) => service.Service)}`,
                // `${col.status === 0 ? "Pendiente" : col.status === 1 ? "Cobrado" : "Rechazado"}`,
                `${col.amount}`,
                `${((parseFloat(col.amount) * 7) / 100).toFixed(2)}€`,
                `${(((parseFloat(col.amount) * 7) / 100) + parseFloat(col.amount)).toFixed(2)}€`,
            ])),
            additionalRows: [{
                //     col1: 'Deslogose:',
                //     col2: `${records.filter((record: any) => record.status === 0).reduce((total, record: any) => total + parseFloat(record.amount), 0)}€`,
                //     col3: 'pte.',
                //     style: {
                //         fontSize: 10 //optional, default 12
                //     }
                // },
                // {

                //     col2: `${records.filter((record: any) => record.status === 1).reduce((total, record: any) => total + parseFloat(record.amount), 0)}€`,
                //     col3: 'pag.',
                //     style: {
                //         fontSize: 10 //optional, default 12
                //     }
                // },
                // {
                col1: 'SubTotal Facturado:',
                col2: `${totalAmount.toFixed(2)}€`,
                col3: 'ALL',
                style: {
                    fontSize: 10 //optional, default 12
                }
            },
            {
                col1: 'IGIC (7%):',
                col2: `${igicAmount.toFixed(2)}€`,
                col3: 'ALL',
                style: {
                    fontSize: 10 //optional, default 12
                }
            },
            {
                col1: 'Total Facturado:',
                col2: `${(totalAmount + igicAmount).toFixed(2)}€`,
                col3: 'ALL',
                style: {
                    fontSize: 14 //optional, default 12
                }
            }] as any,

            invDescLabel: "Aviso legal",
            invDesc: "En cumplimiento del Reglamento de Protección de Datos de Carácter Personal, (RGPD/UE/2016/679),le comunicamos que en EMPRENDE SOLUCIONES Y SERVICIOS 33, S.L.U. B76345594, Responsable del fichero utilizará los datos facilitados por usted con el fin concreto de los mismos, comprometiéndose a tratar de forma confidencial los datos de carácter personal facilitados y a no comunicar o ceder dicha información a terceros salvo disposición legal",
        },
        footer: {
            text: "Esta factura ha sido generada automáticamente en un ordenador y es válida sin la firma y el sello",
        },
        pageEnable: true,
        pageLabel: "Página ",
    }
    const [selectionComplete, toggleSelectionComplete] = useState(false);

    const handleDateChange = (date: Date | null) => {
        if (!selectionComplete && !startDate) {
            setStartDate(date);
            return;
        }

        if (!selectionComplete && startDate && !endDate) {
            setEndDate(date);
            toggleSelectionComplete(true);

            // Perform your filtering logic here
            const sd = moment(startDate).format('YYYY-MM-DD');
            const ed = moment(date).format('YYYY-MM-DD');

            const filtered = data.filter((record: any) => {
                const recordDate = moment(record.createdAt).format('YYYY-MM-DD');
                return recordDate >= sd && recordDate <= ed;
            });
            setRecords(filtered);
            return;
        }

        if (selectionComplete && startDate && endDate) {
            setStartDate(date);
            setEndDate(undefined);
            toggleSelectionComplete(false);
            return;
        }
    };

    const handleSelectDate = (date: Date | null) => {
        if (date && !selectionComplete && startDate && !endDate && sameDay(date, startDate)) {
            handleDateChange(date);
        }
    };

    const handleShortcutChange = (value: string) => {
        const today = moment();
        let start: moment.Moment | null = null;
        let end: moment.Moment | null = null;

        switch (value) {
            case 'today':
                start = today.startOf('day');
                end = today.endOf('day');
                break;
            case 'week':
                start = today.clone().startOf('week').add(1, 'day');
                end = today.clone().endOf('week').add(1, 'day');
                break;
            case 'month':
                start = today.clone().startOf('month');
                end = today.clone().endOf('month');
                break;
            case 'year':
                start = today.clone().startOf('year');
                end = today.clone().endOf('year');
                break;
            default:
                break;
        }

        if (start && end) {
            setStartDate(start.toDate());
            setEndDate(end.toDate());
            toggleSelectionComplete(true);

            const sd = start.format('YYYY-MM-DD');
            const ed = end.format('YYYY-MM-DD');

            const filtered = data.filter((record: any) => {
                const recordDate = moment(record.createdAt).format('YYYY-MM-DD');
                return recordDate >= sd && recordDate <= ed;
            });
            setRecords(filtered);
        }
    };

    const sameDay = (d1: Date, d2: Date) => {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    };

    const isSameRange = (start: Date, end: Date, rangeStart: moment.Moment, rangeEnd: moment.Moment) => {
        return moment(start).isSame(rangeStart, 'day') && moment(end).isSame(rangeEnd, 'day');
    };

    const handleSelect = () => {
        const selectElement = document.getElementById('selectstatus') as HTMLSelectElement;
        const invoicestatus = parseInt(selectElement.value, 10);

        if (invoicestatus === 3) {
            setRecords(defaults);
        } else {
            setRecords(defaults.filter((record: any) => record.status === invoicestatus));
        }
    };

    const handleCoverage = () => {
        const selectElement = document.getElementById('selectcoverage') as HTMLSelectElement;
        const invoicecoverage = parseInt(selectElement.value, 10);

        if (invoicecoverage === 4) {
            setRecords(defaults);
        } else {
            setRecords(defaults.filter((record: any) => record.UserGroup === invoicecoverage));
        }
    };

    const handleMark = (row: any) => {
        setRowData(row);
        setChangeStatus(true);
        // setShowDrop(false);
    }

    const ExpandedComponent = ({ data }: any) => {
        const getCommissionBreakdown = (services: any[]) => {
            // return services.flatMap((service: any) => {
            //     return service.Service.company.map((company: any) => {
            //         const price = service.Service.price;
            //         const tax = service.Service.tax;
            //         const individualCommission = (price * tax / 100) + price;
            //         const breakdown = (company.company.commission / 100) * individualCommission;
            //         return `${company.company.name}: ${breakdown.toFixed(2)}€`;
            //     });
            // }).join(', ');

            //UNCOMMENT ABOVE ONCE THERE IS COMISSIONS
            return ""
        };

        return (
            <div className='original-invoice'>
                <span><strong>Total comisiones:</strong> {data.amt_comission}€</span>
                <span><strong>Servicio:</strong> {data.services.map((service: any) => service.Service.name).join(', ')}</span>
                <span><strong>Comisionistas:</strong> {getCommissionBreakdown(data.services)}</span>
            </div>
        );
    };

    // DESCOMENTAR CUANDO YA HAY COMISIONISTAS

    // const customStyles = {
    //     control: (provided: any) => ({
    //         ...provided,
    //         paddingLeft: '2rem',
    //         width: '250px',
    //         border: '1px solid black',
    //         borderRadius: '8px'
    //     }),
    // };

    // const bybrokers = brokers
    //     .sort((a: any, b: any) => a.name.localeCompare(b.name))
    //     .map((item: any) => ({
    //         value: item.id.toString(),
    //         label: item.name
    //     }));

    // const handleSelectBrokers = (selectedOption: Option | null) => {
    //     setBrokersValue(selectedOption);
    //     if (selectedOption === null) {
    //         setRecords(defaults);
    //     } else {
    //         const filteredRecords = defaults.filter((invoice: any) =>
    //             invoice.services.some((service: any) =>
    //                 service.Service.company.some((company: any) => company.company.name === selectedOption.label)
    //             )
    //         );
    //         setRecords(filteredRecords);
    //     }
    // };

    return (
        <div className="table-bill">
            {isLoading && <Loading />}
            <div className="table-container">
                <span className='table-name'>Facturas generadas</span>


                <div className='table-addon'>
                    <div className='table-bymonth'>
                        <div className='table-datepick'>
                            <div className='table-selectstatus'>
                                <label className='makegreen' htmlFor="selectstatus">Estado:</label>
                                <select onChange={handleSelect} name="selectstatus" id="selectstatus" defaultValue="3">
                                    <option value="3">Todos</option>
                                    <option value="0">Pendiente</option>
                                    <option value="1">Pagado</option>
                                    <option value="2">Rechazado</option>
                                </select>
                            </div>
                            <div className='table-selectstatus'>
                                <label className='makegreen' htmlFor="selectcoverage">Cobertura:</label>
                                <select onChange={handleCoverage} name="selectcoverage" id="selectcoverage" defaultValue="4">
                                    <option value="4">Todos</option>
                                    <option value="3">Total</option>
                                    <option value="2">Copago</option>
                                    <option value="1">No cubierto</option>
                                    <option value="0">No asegurado</option>
                                </select>
                            </div>

                            <div>
                                <span className='makegreen'>Intervalo de fechas: </span>
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    onSelect={handleSelectDate}
                                    selectsEnd={Boolean(startDate)}
                                    startDate={startDate}
                                    endDate={endDate}
                                    shouldCloseOnSelect={false}
                                    dateFormat="dd/MM/yyyy"
                                    locale="es"
                                    customInput={<CustomDateInput startDate={startDate} endDate={endDate} />}
                                >
                                    <div className="date-range">
                                        {startDate ? moment(startDate).format('DD/MM/YYYY') : '??/??/????'} - {endDate ? moment(endDate).format('DD/MM/YYYY') : '??/??/????'}

                                    </div>
                                    <div className='datepicker-menu'>
                                        <p>Atajo de fechas:</p>
                                        <div className='date-option'>
                                            <input type="radio" id="today" name="date-option" value="Hoy" onChange={() => handleShortcutChange('today')} checked={startDate && moment(startDate).isSame(moment(), 'day')} />
                                            <label htmlFor="today">Hoy</label>

                                            <input type="radio" id="week" name="date-option" value="Semana" onChange={() => handleShortcutChange('week')} checked={startDate && endDate && isSameRange(startDate, endDate, moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day'))} />
                                            <label htmlFor="week">Semana</label>

                                            <input type="radio" id="month" name="date-option" value="Mes" onChange={() => handleShortcutChange('month')} checked={startDate && endDate && isSameRange(startDate, endDate, moment().startOf('month'), moment().endOf('month'))} />
                                            <label htmlFor="month">Mes</label>

                                            <input type="radio" id="year" name="date-option" value="Año" onChange={() => handleShortcutChange('year')} checked={startDate && endDate && isSameRange(startDate, endDate, moment().startOf('year'), moment().endOf('year'))} />
                                            <label htmlFor="year">Año</label>
                                        </div>
                                    </div>
                                </DatePicker>
                            </div>

                            {/* <div className='triageselect-filter'>
                                <BsSearch className='triage_search_icon' />
                                <Select
                                    value={brokersValue}
                                    onChange={handleSelectBrokers}
                                    options={bybrokers}
                                    placeholder="Comisionistas"
                                    isSearchable={true}
                                    isClearable={true}
                                    styles={customStyles}
                                />
                            </div> */}
                        </div>

                        <div className='table-select'>
                            <button className='generate' onClick={() => setCreateInvoice(true)}>CREAR FRA.</button>
                            <button className='generate' onClick={() => setReimbursement(true)}>DEVOLUCIÓN</button>
                            <button className='generate' onClick={exportReport}>INFORME</button>
                            <button className='generate' onClick={downloadZIP}>DESCARGAR</button>
                        </div>

                    </div>

                    <div className='inputfilter'>
                        <BsSearch className='zone_search_icon' />
                        <input type="text" placeholder='Buscar por datos de la factura' onChange={handleFilter} />
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={records} // CAMBIO FUTURO == data en vez de records
                noDataComponent="No hay ningún dato que mostrar"
                pagination paginationComponentOptions={paginationComponentOptions}
                expandableRows
                expandableRowsComponent={ExpandedComponent}

            />
            {rectify && (
                <Amend
                    rowdata={rowData}
                    editModal={rectify}
                    setEditModal={setRectify} />
            )}
            {changeStatus && (
                <MarkasPaid
                    rowdata={rowData}
                    editModal={changeStatus}
                    setEditModal={setChangeStatus} />
            )}
            {reimbursement && (
                <Reimbursement
                    editModal={reimbursement}
                    setEditModal={setReimbursement} />
            )}
            {createInvoice && (
                <ManualInvoice
                    editModal={createInvoice}
                    setEditModal={setCreateInvoice} />
            )}
        </div>
    )
}

export default Ftables;


