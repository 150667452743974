import React from 'react';
import "./Modal.scss";

const Modal = ({ children, modal, setModal }: any) => {
    return <>
        <div
            onClick={() => setModal(false)}
            className="modalstyle"
            style={modal ? { visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}
        />
        {children}
    </>;
}

export default Modal
