// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardstats {
  display: flex;
  gap: 1rem;
  padding: 1rem 1.25rem;
}

.stat {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #F8F8F8;
  padding: 20px 8px 36px 8px;
  border-radius: 8px;
  width: 224px;
  height: 132px;
  text-align: center;
  border: 1px solid #E9E9E9;
}

.statlabel {
  font-size: 1em;
  margin-top: 10px;
  display: block;
  font-weight: 500;
}

.statnumber {
  font-size: 2em;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/cards/Zonescard.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,qBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,yBAAA;EACA,0BAAA;EACA,kBAAA;EAEA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;AAAJ;;AAIA;EACI,cAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AADJ;;AAIA;EACI,cAAA;EACA,iBAAA;AADJ","sourcesContent":[".cardstats {\n    display: flex;\n    gap: 1rem; //justify-content: space-between;\n    padding: 1rem 1.25rem;\n}\n\n.stat {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    background-color: #F8F8F8;\n    padding: 20px 8px 36px 8px;\n    border-radius: 8px;\n\n    width: 224px;\n    height: 132px;\n    text-align: center;\n    border: 1px solid #E9E9E9;\n    //box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);\n}\n\n.statlabel {\n    font-size: 1em;\n    margin-top: 10px;\n    display: block;\n    font-weight: 500;\n}\n\n.statnumber {\n    font-size: 2em;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
