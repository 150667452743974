import { useEffect, useState, useCallback } from 'react';

const useFetch = (url: string) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No hay token guardado.');
            return;
        }
        setLoading(true);
        try {
            const result = await fetch(url, {
                method: "GET",
                headers: {
                    'Authorization': token,
                }
            });
            if (result.ok) {
                const jsonData = await result.json();
                setData(jsonData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, [url]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        data,
        loading,
        setData,
        refetch: fetchData,
    }
}

export default useFetch;
