import React, { useState } from 'react'
import Modal from '../../utilities/Modal'
import useFetch from '../../hooks/useFetch'
import './MyMedic.scss'
import { toast } from 'react-toastify';

const MyMedic = ({ editModal, setEditModal, rowdata, refetch }: any) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: medics } = useFetch(`${apiUrl}/options/findDoctors`);

    const [selectedMedics, setSelectedMedics] = useState<number[]>([]);
    const [selectedZones, setSelectedZones] = useState<number[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;
    const totalPages = Math.ceil(medics?.length / rowsPerPage);

    const handleMedicChange = (medicId: number) => {
        if (selectedMedics.includes(medicId)) {
            setSelectedMedics(selectedMedics.filter(id => id !== medicId));
        } else {
            setSelectedMedics([...selectedMedics, medicId]);
        }
    };

    const handleZoneClick = (zoneId: number) => {
        if (selectedZones.includes(zoneId)) {
            setSelectedZones(selectedZones.filter(id => id !== zoneId));
        } else {
            setSelectedZones([...selectedZones, zoneId]);
        }
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem('token');
        try {
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            if (selectedMedics.length === 0) {
                toast.error("Selecciona un médico");
                return;
            }
            if (selectedZones.length === 0) {
                toast.error("Selecciona una zona");
                return;
            }
            const response = await fetch(`${apiUrl}/medicalcenter/zones/doctors`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    doctors: selectedMedics,
                    zones: selectedZones,
                }),
            })
            if (response.ok) {
                setEditModal(false);
                toast.success('Médicos asignados con éxito');
                return;
            } else {
                toast.error('Fallo al asignar médicos');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleSelectAll = () => {
        const allZoneIds = rowdata
            ?.filter((zone: any) => zone.zoneName.trim() !== "Sin asignar")
            .map((zone: any) => zone.zoneId);
        setSelectedZones(allZoneIds);
    };

    const handleReset = () => {
        setSelectedZones([]);
    };

    // Pagination handlers
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Get the current page data
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentMedics = medics?.slice(indexOfFirstRow, indexOfLastRow);

    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="my-medic-modal-content">
                <h2 className="my-medic-title">Asignar Médicos a varias Zonas</h2>

                {/* Medic Table */}
                <div className="my-medic-table-wrapper">
                    <table className="my-medic-table">
                        <thead>
                            <tr>
                                <th>Seleccionar</th>
                                <th>Médico</th>
                                <th>Zonas asignadas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentMedics?.map((medic: any) => (
                                <tr key={medic.userId}>
                                    <td>
                                        <input
                                            className='select-multiple-medic'
                                            type="checkbox"
                                            name="medic"
                                            value={medic.userId}
                                            checked={selectedMedics.includes(medic.userId)}
                                            onChange={() => handleMedicChange(medic.userId)}
                                        />
                                    </td>
                                    <td>{medic.fullname}</td>
                                    <td>
                                        {medic.zoneNames.length === rowdata.length - 1 && !medic.zoneNames.includes("Sin Asignar")
                                            ? "Todas"
                                            : medic.zoneNames.join(', ')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {medics.length > 5 && (
                        <div className="pagination-controls">
                            <button
                                className="pagination-button"
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                            >
                                Atras
                            </button>
                            <span className="pagination-info">
                                Página {currentPage} de {totalPages}
                            </span>
                            <button
                                className="pagination-button"
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            >
                                Siguiente
                            </button>
                        </div>
                    )}
                </div>

                {/* Zone Selection */}
                <div className="my-medic-input-group">
                    <div className="my-medic-zone-actions">
                        <label className="my-medic-label">Seleccionar las zonas:</label>
                        <div className="my-medic-action-buttons">
                            <button onClick={handleSelectAll}>Seleccionar Todos</button>
                            <button onClick={handleReset}>Resetear</button>
                        </div>
                    </div>

                    <div className="my-medic-zones-list">
                        {rowdata?.sort((a: any, b: any) => a.zoneName.localeCompare(b.zoneName)).map((zone: any) => (
                            <div
                                key={zone.zoneId}
                                className={`my-medic-zone-item ${selectedZones.includes(zone.zoneId) ? 'selected' : ''}`}
                                onClick={() => handleZoneClick(zone.zoneId)}
                            >
                                <span>{zone.zoneName}</span>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Submit Button */}
                <button className="my-medic-submit-button" onClick={handleSubmit}>
                    Asignar Médicos
                </button>
            </div>
        </Modal>
    );
};

export default MyMedic