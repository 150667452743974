import React from 'react';
import "./Delete.scss";
import Modal from '../../utilities/Modal';
import { BsExclamationCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Delete = ({ editModal, setEditModal, rowdata, refetch }: any) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const handleDelete = async (e: any) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/users/delete/${rowdata.userId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });

            if (!response.ok) {
                throw new Error('Error en actualizar los datos');
            }

            // Resetea valores despues de enviar
            setEditModal(false); // Cierra el modal despues de enviar
            navigate('/usuarios');
            refetch();
            toast.success('Usuario dado de baja con exito')

        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="popup-delete">
                <div className="popup-delete-message">
                    <BsExclamationCircle className='popup-exclamation' />
                    <h3 >¿Estás seguro de que quieres eliminar este usuario?</h3>
                </div>
                <div className='popup-delete-buttons'>
                    <button type="button" onClick={handleDelete} className="confirm-button">Confirmar</button>
                    <button type="button" onClick={() => setEditModal(false)} className="cancel-button">Cancelar</button>
                </div>
            </div>
        </Modal>

    )
}

export default Delete
