import React, { useEffect, useRef, useState } from 'react'
import "./Zonestable.scss";
import DataTable from 'react-data-table-component';
import { RiUserLocationFill } from 'react-icons/ri';
import { BsSearch } from 'react-icons/bs';
import Select from 'react-select';
import { Socket } from 'socket.io-client';
import useWebSocket from '../../hooks/useWebsocket';
import { MdOutlineAssignmentInd } from 'react-icons/md';
// import useFetch from '../../hooks/useFetch';
// import { webSocketData, zonedetails } from '../../data/data';
interface Option {
    value: string,
    label: string;
}

interface ZoneData {
    zoneName: string;
    pendingRequest: number;
    assignedDoctors: number;
    availableDoctors: number;
    demands: 'ALTA' | 'MEDIA' | 'BAJA';
    zoneId: number;
}
interface ZoneDetails {
    patient: string;
    doctor: string;
    estado: string;
    hotel: string;
    traveltime: string;
    consulttime: string;
}

const sortByDemands = (zones?: ZoneData[]): ZoneData[] => {
    if (!zones) return [];

    const priority = { 'ALTA': 1, 'MEDIA': 2, 'BAJA': 3 };

    return zones.sort((a, b) => {
        return priority[a.demands] - priority[b.demands];
    });
};




const Zonestable = ({ moreDetails, setMoreDetails, setzoneClicked, setTransfer, setMyMedic }: any) => {
    const [records, setRecords] = useState<ZoneData[]>([]);
    const [records2, setRecords2] = useState<ZoneDetails[]>([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [specificZone, setSpecificZone] = useState<ZoneDetails[]>([]);

    const token = localStorage.getItem('token')!;
    const socketurl = process.env.REACT_APP_WEBSOCKET!;
    const { registerEvent, emitEvent } = useWebSocket(token, socketurl);
    const [webSocketData, setWebSocketData] = useState<any>(null);
    const socketRef = useRef<Socket | null>(null);

    useEffect(() => {
        const handleDemandZone = (data: any) => {
            setWebSocketData(data);
        };

        registerEvent('demandZone', handleDemandZone);

        const fetchData = async () => {
            emitEvent('demandZone', { demandZone: 'Kh59J56h' });
        };

        fetchData();

        return () => {
            // Limpia el event listener cuando el componente se desmonta
            if (socketRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                socketRef.current.off('demandZone', handleDemandZone);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (webSocketData) {
            setRecords(sortByDemands(webSocketData?.[2] as ZoneData[]));
        }
        if (specificZone) {
            setRecords2(specificZone);
        }

    }, [specificZone, webSocketData]);

    const columns = [
        {
            name: "LOCALIZACIÓN",
            selector: (row: any) => row.zoneName,
            sortable: true,
        },
        {
            name: <div className="custom-header-cell">SOLICITUDES PENDIENTES</div>,
            selector: (row: any) => row.pendingRequest,
            sortable: true,
        },
        {
            name: <div className="custom-header-cell">MÉDICOS ASIGNADOS</div>,
            selector: (row: any) => row.assignedDoctors,
            sortable: true,
            wrap: true,
        },
        {
            name: <div className="custom-header-cell">MÉDICOS DISPONIBLES</div>,
            selector: (row: any) => row.availableDoctors,
            sortable: true,
            wrap: true,
        },
        {
            name: "DEMANDA",
            selector: (row: any) => row.demands,
            sortable: true,
            cell: (row: any) => {
                let backgroundColor, color, borderRadius, padding;
                switch (row.demands) {
                    case "ALTA":
                        backgroundColor = "#FF9E99";
                        color = "#FF3D32";
                        borderRadius = "16px";
                        padding = "8px 16px";
                        break;
                    case "MEDIA":
                        backgroundColor = "#FFE680";
                        color = "#806600";
                        borderRadius = "16px";
                        padding = "8px 16px";
                        break;
                    case "BAJA":
                        backgroundColor = "lightgrey";
                        color = "#0D1726";
                        borderRadius = "16px";
                        padding = "8px 16px";
                        break;
                }
                return (
                    <div style={{ backgroundColor, color, borderRadius, padding }}>{row.demands}</div>
                );
            },
        },
        {
            name: "",
            selector: (row: any) => {
                return (
                    <>
                        <div className='table-zoneactions'>
                            <button className='table-zoneassign' onClick={() => { setTransfer(true); setzoneClicked(row) }}> <span>Asignar</span><RiUserLocationFill /></button>
                            <button className='table-zonedetails' onClick={handleRowClick}>&gt;</button>
                        </div>
                    </>
                )
            },
            sortable: true,
        },
    ]

    const columns2 = [
        {
            name: "PACIENTE",
            selector: (row: any) => row.patient,
            sortable: true,
        },
        {
            name: "MÉDICO",
            selector: (row: any) => row.doctor,
            sortable: true,
        },
        {
            name: "ESTADO",
            selector: (row: any) => row.estado,
            sortable: true,
        },
        {
            name: "HOTEL",
            selector: (row: any) => row.hotel,
            sortable: true,
            wrap: true,
            grow: 2,
        },
        {
            name: <div className="custom-header-cell">TIEMPO DESPLAZAMIENTO</div>,
            selector: (row: any) => row.traveltime,
            sortable: true,
            wrap: true,
        },
        {
            name: <div className="custom-header-cell">TIEMPO DE CONSULTA</div>,
            selector: (row: any) => row.consulttime,
            sortable: true,
            wrap: true,
        },
    ]

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const [activeButton, setActiveButton] = useState<string>('Todos');
    const [zoneValue, setZoneValue] = useState<Option | null>(null);

    const handleButtonClick = (buttonName: string) => {
        setActiveButton(buttonName);

        if (!webSocketData[2]) {
            setRecords([]);
            return;
        } else if (buttonName === 'Todos') {
            setRecords(webSocketData[2]);
        } else {
            const filteredRecords = (webSocketData[2] as ZoneData[]).filter((zone: any) => zone.demands === buttonName.toUpperCase());
            setRecords(filteredRecords);
        }
    };

    const handleSelect = (selectedOption: Option | null) => {
        setZoneValue(selectedOption);
        if (!webSocketData) {
            setRecords([]);
            return;
        } else if (selectedOption === null) {
            setRecords(webSocketData[2]);
        } else {
            const filteredRecords = (webSocketData[2] as ZoneData[]).filter((zone: any) => zone.zoneName === selectedOption.value);
            setRecords(filteredRecords);
        }

    }

    const zones = [
        { zone: "Meloneras" },
        { zone: "San Agustín" },
        { zone: "Arguineguin" },
        { zone: "Bahia Feliz" },
        { zone: "Taurito" },
        { zone: "Puerto Rico" },
        { zone: "Sin asignar" }
    ];

    const zoneOptions = zones.sort((a: any, b: any) => a.zone.localeCompare(b.zone))
        .map((item: any) => ({
            value: item.zone,
            label: item.zone
        }));

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            paddingLeft: '2rem',
            backgroundColor: '#F8F8F8',
            width: '250px'
        }),
    };

    const handleRowClick = (row: any) => {
        setzoneClicked(row);
        setMoreDetails(true);

        if (row.zoneId) {
            const getMoreDetails = async () => {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No hay token guardado.');
                    return;
                }
                const result = await fetch(`${apiUrl}/medicalcenter/request/zoneTriages/${row.zoneId}`, {
                    method: "GET",
                    headers: {
                        'Authorization': token,
                    }
                });
                const jsonData = await result.json();
                setSpecificZone(jsonData[2]);
            };

            getMoreDetails();
        }
    };

    const handleFilter = (event: any) => {
        const newData = specificZone.filter((row: any) => {
            return (row.patient + " " + row.doctor + " " + row.estado + " " + row.hotel).toLowerCase().includes(event.target.value.toLowerCase());
        })
        setRecords2(newData);
    }
    const handleSelect2 = () => {
        const selectElement = document.getElementById('selectstatus') as HTMLSelectElement;
        const triagestatus = selectElement.value;

        if (!specificZone) {
            setRecords([]);
            return;
        } else if (triagestatus === "Todos") {
            setRecords2(specificZone);
        } else {
            setRecords2(specificZone.filter((record: any) => record.estado === triagestatus));
        }
    };
    return (
        <div className='table-bill'>
            {!moreDetails ? (
                <>
                    <label className="table-bill-label" htmlFor="">Demanda</label>
                    <div className='tablefilter'>
                        <div className='demandbuttons'>
                            <button
                                className={activeButton === 'Todos' ? 'active' : ''}
                                onClick={() => handleButtonClick('Todos')}
                            >
                                Todos
                            </button>
                            <button
                                className={activeButton === 'Alta' ? 'active' : ''}
                                onClick={() => handleButtonClick('Alta')}
                            >
                                Alta
                            </button>
                            <button
                                className={activeButton === 'Media' ? 'active' : ''}
                                onClick={() => handleButtonClick('Media')}
                            >
                                Media
                            </button>
                            <button
                                className={activeButton === 'Baja' ? 'active' : ''}
                                onClick={() => handleButtonClick('Baja')}
                            >
                                Baja
                            </button>
                        </div>

                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <div
                                onClick={() => setMyMedic(true)}
                                className='assign-global'
                            >
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    Mis médicos  <MdOutlineAssignmentInd style={{ fontSize: '24px' }} />
                                </span>
                            </div>

                            <div className='tablefilter-input'>
                                <BsSearch className='zone_search_icon' />
                                <Select
                                    value={zoneValue}
                                    onChange={handleSelect}
                                    options={zoneOptions}
                                    placeholder="Buscar por zona"
                                    isSearchable={true}
                                    isClearable={true}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={records}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        noHeader={true}
                        onRowClicked={handleRowClick}
                        highlightOnHover
                        pointerOnHover
                        noDataComponent="No hay ningún dato que mostrar"
                    />
                </>
            ) : (
                <>

                    <div className='tablefilter-details'>
                        <div className='inputfitler-details'>
                            <BsSearch className='zone_search_icon' />
                            <input type="text" placeholder='Buscar nombre, médico, etc' onChange={handleFilter} />
                        </div>

                        <div className='selectstatus-details'>
                            <label htmlFor="selectstatus">Estado:</label>
                            <select onChange={handleSelect2} name="selectstatus" id="selectstatus" defaultValue="Todos">
                                <option value="Todos">Todos</option>
                                <option value="Pendiente">Pendiente</option>
                                <option value="En consulta">En consulta</option>
                                <option value="Finalizado">Finalizado</option>
                                <option value="Rechazado">Rechazado</option>
                            </select>
                        </div>
                    </div>
                    <DataTable
                        columns={columns2}
                        data={records2}
                        pagination={false}
                        noHeader={true}
                        highlightOnHover
                        pointerOnHover
                        noDataComponent="No hay ningún dato que mostrar"
                    />
                </>
            )}

        </div>
    )
}

export default Zonestable
