import React, { useState } from 'react'
import { accessdata } from '../../data/data';
import DataTable from 'react-data-table-component';

const Accesstable = () => {
    const [records, setRecords] = useState(accessdata);

    const columns = [
        {
            name: "ID",
            selector: (row: any) => row.id,
            sortable: true,
        },
        {
            name: "Identificador MAC",
            selector: (row: any) => row.macaddress,
            sortable: true,
            grow: 2,
        },
        {
            name: "Dirección IP",
            selector: (row: any) => row.ipaddress,
            sortable: true,
            grow: 2,
        },
        {
            name: "Fecha acceso",
            selector: (row: any) => row.date,
            sortable: true,
        },

    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const handleFilter = (event: any) => {
        const newData = accessdata.filter(row => {
            return (row.macaddress + " " + row.ipaddress).toLowerCase().includes(event.target.value.toLowerCase());
        })
        setRecords(newData);
    }

    return (
        <div className="table-bill">
            <div className="table-container">
                <span className='table-name'>Historial de acceso</span>

                <div className='rtable-addon'>
                    <input type="text" placeholder='buscar por mac o ip' onChange={handleFilter} />
                </div>
            </div>
            <DataTable
                columns={columns}
                data={records}
                pagination paginationComponentOptions={paginationComponentOptions}

            />
        </div>
    )
}

export default Accesstable
