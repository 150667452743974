// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.modalstyle {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
  transition-property: visibility, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  animation: fadeIn 0.5s ease forwards;
}

.modalstyle[style*="visibility: hidden"] {
  animation: fadeOut 0.5s ease forwards;
}`, "",{"version":3,"sources":["webpack://./src/utilities/Modal.scss"],"names":[],"mappings":"AAAA;EACI;IACI,UAAA;EACN;EAEE;IACI,UAAA;EAAN;AACF;AAGA;EACI;IACI,UAAA;EADN;EAIE;IACI,UAAA;EAFN;AACF;AAKA;EACI,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,oCAAA;EAEA,wCAAA;EACA,wDAAA;EACA,0BAAA;EAEA,oCAAA;AALJ;;AAQA;EACI,qCAAA;AALJ","sourcesContent":["@keyframes fadeIn {\n    from {\n        opacity: 0;\n    }\n\n    to {\n        opacity: 1;\n    }\n}\n\n@keyframes fadeOut {\n    from {\n        opacity: 1;\n    }\n\n    to {\n        opacity: 0;\n    }\n}\n\n.modalstyle {\n    position: fixed;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    z-index: 10;\n    background-color: rgba(0, 0, 0, 0.4);\n\n    transition-property: visibility, opacity;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 500ms;\n\n    animation: fadeIn 0.5s ease forwards;\n}\n\n.modalstyle[style*=\"visibility: hidden\"] {\n    animation: fadeOut 0.5s ease forwards;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
