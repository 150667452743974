import React, { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch';
import moment from 'moment';
import DataTable from 'react-data-table-component';

const SumUp = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data } = useFetch(`${apiUrl}/transaction/historial`);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        data && setRecords(data.filter(item => item !== null));
    }, [data]);

    const columns = [
        {
            name: "ID Consulta",
            selector: (row: any) => row.consultIdentificator,
            sortable: true,

        },
        {
            name: "Paciente",
            selector: (row: any) => row.client,
            sortable: true,

        },
        {
            name: "Médico",
            selector: (row: any) => row.doctorName,
            sortable: true,
        },
        {
            name: "Transacción",
            selector: (row: any) => row.transactionId,
            sortable: true,
            wrap: true
        },
        {
            name: "Fecha y hora",
            selector: (row: any) => moment(row.transactionTimestamp).utc().format(('DD/MM/YYYY hh:mm:ss')),
            sortable: true,
        },
        {
            name: "Importe",
            selector: (row: any) => `${row.transactionAmout}€`,
            sortable: true,
        },
        {
            name: "Ticket",
            selector: (row: any) => <a href={row.ticket} target="_blank" rel="noopener noreferrer">Descargar Recibo</a>,
            sortable: true,
        },
    ];


    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    return (
        <div className="table-bill">
            <div className="table-container">
                <span className='table-name'>Transacciones SumUp</span>
            </div>
            <DataTable
                columns={columns}
                data={records}
                pagination paginationComponentOptions={paginationComponentOptions}
                noDataComponent="No hay ningún dato que mostrar"
            />

        </div>
    )
}

export default SumUp