import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import Loading from '../components/loading/Loading';
const UserContext = createContext<any>(null);

const Context = ({ children }: any) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            fetchUserData(token);
        } else {
            setIsLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);


    const fetchUserData = async (token: string) => {
        try {
            const response = await fetch(`${apiUrl}/profile/me`, {
                method: "GET",
                headers: {
                    'Authorization': token,
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const userData = await response.json();
            setCurrentUser(userData);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        const today = new Date();
        const last30Days = new Date(today);
        last30Days.setDate(today.getDate() - 30);

        setStartDate(last30Days);
        setEndDate(today);
    }, []);

    return (
        <UserContext.Provider
            value={{
                currentUser,
                setCurrentUser,
                isLoading,
                showModal,
                setShowModal,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
            }}>
            {isLoading ? <Loading /> : children}
        </UserContext.Provider>
    );
};

export default Context;

export const User = () => useContext(UserContext);