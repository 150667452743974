import React from 'react'
import Modal from '../../utilities/Modal'
import { BsExclamationCircle } from 'react-icons/bs'
import { toast } from 'react-toastify';

const MarkasDone = ({ editModal, setEditModal, rowData }: any) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    console.log(rowData.guid)
    const handleUpdate = async (e: any) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const response = await fetch(`${apiUrl}/hospital/date/update/${rowData.guid}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });

            if (!response.ok) {
                throw new Error('Error en actualizar los datos');
            }
            setEditModal(false);
            toast.success("Marcado como atendido correctamente")

        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="popup-delete">
                <div className="popup-delete-message">
                    <BsExclamationCircle className='popup-exclamation' />
                    <h3 >¿Marcar la solicitud como atendida?</h3>
                    <p><strong>Paciente:</strong> {rowData.patientName}</p>
                </div>
                <div className='popup-delete-buttons'>
                    <button type="button" onClick={handleUpdate} className="confirmgreen-button">Confirmar</button>
                    <button type="button" onClick={() => setEditModal(false)} className="cancel-button">Cancelar</button>
                </div>
            </div>
        </Modal>
    )
}

export default MarkasDone
