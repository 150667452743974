import React, { useState } from 'react'
import Sidebar from '../components/nav/Sidebar'
import Navbar from '../components/nav/Navbar'
import ServiceTable from '../components/tables/ServiceTable'
import "./Services.scss"

const Services = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

            <div className='services'>
                <ServiceTable />
            </div>
        </div>
    )
}

export default Services
