import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import "./Fchart.scss";
import { ApexOptions } from 'apexcharts';
import useFetch from '../../hooks/useFetch';
import { User } from '../../context/Context';
// import moment from 'moment';

const Fchart = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data } = useFetch(`${apiUrl}/accountant/invoice`);
    const { startDate, endDate } = User();
    const [aggregatedData, setAggregatedData] = useState<{ status0: number, status1: number, received: number, comission: number }>({
        status0: 0,
        status1: 0,
        received: 0,
        comission: 0
    });
    // const [aggregatedData2, setAggregatedData2] = useState<{ date: string, status0: number, status1: number, received: number, comission: number }[]>([]);

    useEffect(() => {
        if (data) {
            // Filter invoices within the specified date range and with status 0 or 1
            const filteredInvoices = data.filter((invoice: any) => !invoice.rectified).filter((invoice: any) => {
                const invoiceDate = new Date(invoice.createAt);
                const isWithinDateRange = invoiceDate >= new Date(startDate) && invoiceDate <= new Date(endDate);
                const hasValidStatus = invoice.status === 0 || invoice.status === 1;
                return isWithinDateRange && hasValidStatus;
            });

            // Aggregate the data
            const aggregation = filteredInvoices.reduce((acc: any, invoice: any) => {
                if (invoice.status === 0) {
                    acc.status0 += parseFloat(invoice.amount);
                } else if (invoice.status === 1) {
                    acc.status1 += parseFloat(invoice.amount);
                }
                acc.received += parseFloat(invoice.amt_received);
                acc.comission += parseFloat(invoice.amt_comission);
                return acc;
            }, { status0: 0, status1: 0, received: 0, comission: 0 });

            // Update aggregated data
            setAggregatedData(aggregation);
        }
    }, [data, startDate, endDate]);

    // useEffect(() => {
    //     if (data) {
    //         // Filter invoices within the specified date range and with status 0 or 1
    //         const filteredInvoices = data.filter((invoice: any) => !invoice.rectified).filter((invoice: any) => {
    //             const invoiceDate = new Date(invoice.createAt);
    //             const isWithinDateRange = invoiceDate >= new Date(startDate) && invoiceDate <= new Date(endDate);
    //             const hasValidStatus = invoice.status === 0 || invoice.status === 1;
    //             return isWithinDateRange && hasValidStatus;
    //         });

    //         // Aggregate the data by date and status
    //         const aggregation: { date: string, status0: number, status1: number, received: number, comission: number }[] = filteredInvoices.reduce((acc: any, invoice: any) => {
    //             const date = `${moment(invoice.createAt).format('DD/MM/YY')} (${data.filter((record: any) => record.createAt === invoice.createAt).length})`;
    //             if (!acc[date]) {
    //                 acc[date] = { date, status0: 0, status1: 0, received: 0, comission: 0 };
    //             }
    //             if (invoice.status === 0) {
    //                 acc[date].status0 += parseFloat(invoice.amount);
    //             } else if (invoice.status === 1) {
    //                 acc[date].status1 += parseFloat(invoice.amount);
    //             }
    //             acc[date].received += parseFloat(invoice.amt_received);
    //             acc[date].comission += parseFloat(invoice.amt_comission);
    //             return acc;
    //         }, {});

    //         // Convert aggregated data into an array
    //         const aggregatedArray = Object.values(aggregation);

    //         // Update aggregated data
    //         setAggregatedData2(aggregatedArray);
    //     }
    // }, [data, startDate, endDate]);

    const options: ApexOptions = {
        chart: {
            type: "pie",
        },
        labels: ["Pagado", "Pendiente", "Recibido"],
        colors: ['#006757', '#FFCC00', '#87D11F'],
        // labels: ["Pagado", "Pendiente", "Recibido", "Comisión"],
        // colors: ['#006757', '#806600', '#87D11F', '#FF9E98'],
        dataLabels: {
            enabled: true,
        },
        tooltip: {
            enabled: true,
        },
    };

    const series = [
        aggregatedData.status1,
        aggregatedData.status0,
        aggregatedData.received,
        // aggregatedData.comission,
    ];

    // const options2: ApexOptions = {
    //     chart: {
    //         type: "bar",
    //         stacked: true,
    //     },
    //     xaxis: {
    //         type: 'category',
    //         title: {
    //             text: 'Fecha de emisión',
    //             style: {
    //                 fontSize: '16px',
    //                 fontWeight: 'bold'
    //             }
    //         },
    //         labels: {
    //             style: {
    //                 fontSize: '14px',
    //                 fontWeight: 700,
    //             }
    //         },
    //         categories: aggregatedData2.map(data => data.date),
    //     },
    //     yaxis: {
    //         title: {
    //             text: 'Importe facturado',
    //             style: {
    //                 fontSize: '16px',
    //                 fontWeight: 'bold'
    //             }
    //         },
    //     },
    //     plotOptions: {
    //         bar: {
    //             borderRadius: 4,
    //             horizontal: false,
    //             columnWidth: "20%"
    //         },
    //     },
    //     colors: ['#006757', '#FFCC00', '#87D11F'],
    //     dataLabels: {
    //         enabled: true
    //     },
    //     tooltip: {
    //         shared: true,
    //         intersect: false
    //     },
    // };

    // const series2 = [
    //     {
    //         name: "Pagado",
    //         data: aggregatedData2.map(data => data.status1),
    //         group: 'estado'
    //     },
    //     {
    //         name: "Pendiente",
    //         data: aggregatedData2.map(data => data.status0),
    //         group: 'estado'
    //     },
    //     {
    //         name: "Recibido",
    //         data: aggregatedData2.map(data => data.received),
    //         group: 'desglose'
    //     },

    // ];

    return (
        <div className="chart-container">
            <Chart
                options={options}
                series={series}
                type="pie"
                height={350}
            />

            {/* <Chart
                options={options2}
                series={series2}
                type="bar"
                height={350}
            /> */}
        </div>
    );
};

export default Fchart