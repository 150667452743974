// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.triage {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: #0D1726;
}

.triage-ontop {
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
}

.upload-analytics {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border-bottom-width: 4px;
  border-color: #047c74;
  font-weight: 700;
  color: white;
  background-color: #20A2A0;
  cursor: pointer;
  align-items: end;
}`, "",{"version":3,"sources":["webpack://./src/pages/TriagePage.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;EACA,kBAAA;AACJ;;AAEA;EACI,oBAAA;EACA,sBAAA;EACA,wBAAA;EACA,qBAAA;EACA,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".triage {\n    grid-area: main;\n    overflow-y: auto;\n    padding: 20px 20px;\n    color: #0D1726;\n}\n\n.triage-ontop {\n    display: flex;\n    justify-content: space-between;\n    margin-right: 1rem;\n}\n\n.upload-analytics {\n    padding: 0.5rem 1rem;\n    border-radius: 0.25rem;\n    border-bottom-width: 4px;\n    border-color: #047c74;\n    font-weight: 700;\n    color: white;\n    background-color: #20A2A0;\n    cursor: pointer;\n    align-items: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
