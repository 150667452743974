// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.rectify {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
}

.rectify-data {
  position: relative;
  border-radius: 0.75rem;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.step-items {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  justify-content: center;
}

.steps {
  display: flex;
  flex-direction: column;
  margin: 1em;
  border-radius: 10%;
  height: 5em;
  padding: 1rem;
  width: 7rem;
  height: 4rem;
  position: relative;
  color: #fff;
  background: linear-gradient(45deg, #04c7a9, #45e5c3);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
}

.steps:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 22%;
  top: 2em;
  left: -2em;
  color: #04c7a9;
  background-color: #20A2A0;
}

.steps:first-child::after {
  content: none;
  display: none;
}

.steps:first-child::before {
  content: "▶︎";
  position: relative;
  display: block;
  left: calc(100% + 36px);
  color: #04c7a9;
  top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Rectify.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;AAEJ;;AACA;EACI,kBAAA;EACA,sBAAA;EACA,uBAAA;EACA,2CAAA;AAEJ;;AACA;EACI,aAAA;EACA,qBAAA;EACA,kBAAA;EACA,uBAAA;AAEJ;;AACA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,oDAAA;EACA,sFAAA;AAEJ;;AACA;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;EACA,QAAA;EACA,UAAA;EACA,cAAA;EACA,yBAAA;AAEJ;;AACA;EACI,aAAA;EACA,aAAA;AAEJ;;AACA;EACI,aAAA;EACA,kBAAA;EACA,cAAA;EACA,uBAAA;EACA,cAAA;EACA,QAAA;AAEJ","sourcesContent":[".rectify {\n    grid-area: main;\n    overflow-y: auto;\n    padding: 20px 20px;\n}\n\n.rectify-data {\n    position: relative;\n    border-radius: 0.75rem;\n    background-color: white;\n    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);\n}\n\n.step-items {\n    display: flex;\n    flex-flow: row nowrap;\n    position: relative;\n    justify-content: center;\n}\n\n.steps {\n    display: flex;\n    flex-direction: column;\n    margin: 1em;\n    border-radius: 10%;\n    height: 5em;\n    padding: 1rem;\n    width: 7rem;\n    height: 4rem;\n    position: relative;\n    color: #fff;\n    background: linear-gradient(45deg, #04c7a9, #45e5c3);\n    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);\n}\n\n.steps:after {\n    content: \"\";\n    position: absolute;\n    height: 2px;\n    width: 22%;\n    top: 2em;\n    left: -2em;\n    color: #04c7a9;\n    background-color: #20A2A0;\n}\n\n.steps:first-child::after {\n    content: none;\n    display: none;\n}\n\n.steps:first-child::before {\n    content: '▶︎';\n    position: relative;\n    display: block;\n    left: calc(100% + 36px);\n    color: #04c7a9;\n    top: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
