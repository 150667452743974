import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import "./Csummary.scss";
import { ApexOptions } from 'apexcharts';
import useFetch from '../../hooks/useFetch';
import NoDataFallback from '../loading/NoDataFallback';

interface Service {
    service: string;
    serviceName: string;
    servicePrice: number;
    cantidad: number;
    comision: string;
    commistionType: number;
    comisionTotal: number;
}

interface CompanyData {
    companyName: string;
    companyId: number;
    companyUserId: number;
    companyUserName: string;
    services: Service[];
    totalCommission: number;
}

// const companyData: CompanyData = {
//     companyName: "Clínica Salud Plus",
//     companyId: 2,
//     companyUserId: 7,
//     companyUserName: "Medico Medico",
//     services: [
//         {
//             service: "F1",
//             serviceName: "Forfait 1",
//             servicePrice: 150,
//             cantidad: 2,
//             comision: "Comisión fija sin intervalos",
//             comistionType: 1,
//             comistionTotal: 100,
//         },
//         {
//             service: "F3",
//             serviceName: "Forfait 3",
//             servicePrice: 500,
//             cantidad: 1,
//             comision: "Comisión Intervalo",
//             commistionType: 0,
//             comisionTotal: 50,
//         },
//     ],
//     totalCommission: 150,
// };


const Csummary = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data } = useFetch(`${apiUrl}/commissions/company/info`);
    const [companyData, setCompanyData] = useState<CompanyData | null>(null);

    console.log(companyData)

    useEffect(() => {
        if (data) {
            const parsedData: CompanyData = JSON.parse(JSON.stringify(data));
            console.log(parsedData)
            setCompanyData(parsedData);
        }
    }, [data]);

    if (!companyData || !companyData.services || companyData.services.length === 0) {
        return <NoDataFallback />;
    }

    const chartOptions: ApexOptions = {
        chart: {
            type: 'pie' as const,
        },
        labels: companyData.services.map(service => service.serviceName),
        colors: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
        legend: {
            position: 'bottom' as const,
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom' as const
                }
            }
        }]
    };

    const chartSeries = companyData.services.map(service => service.comisionTotal);

    return (
        <div className="csp-admin-dashboard">
            <header className="csp-admin-dashboard__header">
                <h1 className="csp-admin-dashboard__title">{companyData.companyName}</h1>
                <p className="csp-admin-dashboard__subtitle">Admin: {companyData.companyUserName}</p>
            </header>
            <main className="csp-admin-dashboard__content">
                <section className="csp-admin-dashboard__summary">
                    <h2 className="csp-admin-dashboard__section-title">Resumen de la Comisión</h2>
                    <div className="csp-admin-dashboard__chart">
                        <Chart
                            options={chartOptions}
                            series={chartSeries}
                            type="pie"
                            width="100%"
                            height={300}
                        />
                    </div>
                    <div className="csp-admin-dashboard__total-commission">
                        <h3>Comisión total</h3>
                        <p className="csp-admin-dashboard__commission-amount">${companyData.totalCommission}</p>
                    </div>
                </section>
                <section className="csp-admin-dashboard__services">
                    <h2 className="csp-admin-dashboard__section-title">Servicios</h2>
                    <ul className="csp-admin-dashboard__services-list">
                        {companyData.services.map((service, index) => (
                            <li key={index} className="csp-admin-dashboard__service-item">
                                <div className="csp-admin-dashboard__service-header">
                                    <h3 className="csp-admin-dashboard__service-name">{service.serviceName}</h3>
                                    <span className="csp-admin-dashboard__service-price">${service.comisionTotal}</span>
                                </div>
                                <div className="csp-admin-dashboard__service-details">
                                    <p>Cantidad: {service.cantidad}</p>
                                    <p>Comisión aplicada: {service.comision}</p>
                                    <p>Tipo de comisión: {service.commistionType === 1 ? "Fijo" : "Por tramo"}</p>
                                    <p className="csp-admin-dashboard__service-commission">
                                        Precio del servicio: ${service.servicePrice}
                                    </p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </section>
            </main>
        </div>
    );
};


export default Csummary