// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-user {
  display: flex;
  justify-content: end;
  position: relative;
}

.create-user span {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #006757;
}

.create-user .new-user {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border-bottom-width: 4px;
  border-color: #047c74;
  font-weight: 700;
  color: white;
  background-color: #20A2A0;
  cursor: pointer;
  align-items: end;
}

.usercardstats {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/Usertables.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,OAAA;EACA,cAAA;AACJ;;AAGA;EACI,iBAAA;EACA,qBAAA;EACA,oBAAA;EACA,sBAAA;EACA,wBAAA;EACA,qBAAA;EACA,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,SAAA;EACA,eAAA;AAAJ","sourcesContent":[".create-user {\n    display: flex;\n    justify-content: end;\n    position: relative;\n}\n\n.create-user span {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    color: #006757;\n}\n\n\n.create-user .new-user {\n    margin-left: 1rem;\n    margin-bottom: 0.5rem;\n    padding: 0.5rem 1rem;\n    border-radius: 0.25rem;\n    border-bottom-width: 4px;\n    border-color: #047c74;\n    font-weight: 700;\n    color: white;\n    background-color: #20A2A0;\n    cursor: pointer;\n    align-items: end;\n}\n\n.usercardstats {\n    display: flex;\n    gap: 1rem; //justify-content: space-between;\n    padding: 1rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
