import React, { useEffect, useState } from 'react';
import "./Profile.scss";
import { FaStar } from "react-icons/fa";
import Accesstable from '../components/tables/Accesstable';
import { LiaTimesSolid } from 'react-icons/lia';
import RemoveMac from '../components/actions/RemoveMac';
import Navbar from '../components/nav/Navbar';
import Sidebar from '../components/nav/Sidebar';
import { User } from '../context/Context';
import DataTable from 'react-data-table-component';
import docavatar from '../assets/doc-avatar.png';
import moment from 'moment';

const MyProfile = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    const [deleteMac, setDeleteMac] = useState(false);
    const { currentUser: userData } = User();
    const [records, setRecords] = useState([])
    const [showHistory, setShowHistory] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {
        if (userData) {
            const getHistory = async () => {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No hay token guardado.');
                    return;
                }
                const result = await fetch(`${apiUrl}/whoattendedwho/${userData.id}`, {
                    method: "GET",
                    headers: {
                        'Authorization': token,
                    }
                });
                const jsonData = await result.json();
                setRecords(jsonData);
            };

            if (userData.roleId === 3 || userData.roleId === 5) {
                getHistory();
                setShowHistory(true);
            }

        }
    }, [apiUrl, userData, userData.roleId, userData.userId]);

    const columns = [
        {
            name: "Id de la Consulta",
            selector: (row: any) => row.triageid,
            sortable: true,
        },
        {
            name: "Paciente",
            selector: (row: any) => row.patient,
            sortable: true,
        },
        {
            name: "Alojamiento",
            selector: (row: any) => row.hotel,
            sortable: true,
        },
        {
            name: "Fecha de la asistencia",
            selector: (row: any) => moment(row.when).utc().format('YYYY-MM-DD hh:mm:ss'),
            sortable: true,
        },

    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const getRoleName = (roleId: number) => {
        switch (roleId) {
            case 1:
                return 'Admin';
            case 2:
                return 'Medico';
            case 3:
                return 'Paciente';
            case 4:
                return 'Contable';
            case 5:
                return 'Call Center';
            case 6:
                return 'Medical Center';
            case 7:
                return 'Hotel';
            default:
                return 'Unknown Role';
        }
    };
    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className="profile-container">
                {userData && (
                    <div className="profile">
                        <div className='profile-left'>
                            <img className='profile-user-img' src={docavatar} alt="user profile pic" />
                            <h2>{userData?.data.name} {userData?.data.surname}</h2>
                            {userData.roleId === "Medico" && (
                                <div className='rating'>
                                    <span>
                                        <FaStar className='star checked' />
                                    </span>
                                    <span>
                                        <FaStar className='star checked' />
                                    </span>
                                    <span>
                                        <FaStar className='star checked' />
                                    </span>
                                    <span>
                                        <FaStar className='star' />
                                    </span>
                                    <span>
                                        <FaStar className='star' />
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className='profile-right'>
                            <h3>Información del usuario</h3>
                            <p><strong>Rol:</strong> {getRoleName(userData.roleId)}</p>
                            <p><strong>Zona actual:</strong> {userData.zone ? userData.zone : "-"}</p>
                            <p><strong>Estado {userData.roleId === "Paciente" ? "triaje:" : "actual:"}</strong> {userData.status ? userData.status : "-"}</p>
                            <p><strong>Correo:</strong> {userData.email}</p>
                            <p><strong>Número de contacto:</strong> {userData.data.phone}</p>
                        </div>

                        <div className='profile-device'>
                            <h4>Dispositivos asignados</h4>

                            <div className='mac-address'>
                                <p>1C-A7-64-68-F2-B5</p>
                                <button onClick={() => setDeleteMac(true)} className='remove-mac'><LiaTimesSolid /></button>
                            </div>
                            <div className='mac-address'>
                                <p>33-0B-A8-E7-2C-51</p>
                                <button onClick={() => setDeleteMac(true)} className='remove-mac'><LiaTimesSolid /></button>
                            </div>
                            <div className='mac-address'>
                                <p>8F-A7-70-AF-5D-60</p>
                                <button onClick={() => setDeleteMac(true)} className='remove-mac'><LiaTimesSolid /></button>
                            </div>

                        </div>
                    </div>
                )}
                {showHistory && (
                    <div className="table-bill">
                        <div className="table-container">
                            <span className='table-name'>Historial de atención</span>
                        </div>
                        <DataTable
                            columns={columns}
                            data={records}
                            pagination paginationComponentOptions={paginationComponentOptions}

                        />
                    </div>
                )}
                <Accesstable />
                {deleteMac && (
                    <RemoveMac
                        rowdata={userData}
                        editModal={deleteMac}
                        setEditModal={setDeleteMac} />
                )}
            </div>
        </div>
    )
}

export default MyProfile