import React, { useState } from 'react'
import Modal from '../../utilities/Modal';
import { BsExclamationCircle } from 'react-icons/bs';
import { toast } from 'react-toastify';

const CancelTriage = ({ editModal, setEditModal, rowdata }: any) => {

    const [selectedReason, setSelectedReason] = useState("")
    const apiUrl = process.env.REACT_APP_API_URL;
    console.log(rowdata)
    const handleDelete = async (e: any) => {
        e.preventDefault();

        if (selectedReason === '') toast.error("Selecciona el motivo de la cancelación");
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const response = await fetch(`${apiUrl}/callcenter/cancel/triage/${rowdata.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({ reason: rowdata.status === 0 ? selectedReason : `Médico: ${rowdata.doctorName} (ID: ${rowdata.doctor_id}), Motivo:${selectedReason}` }),
            });

            if (!response.ok) {
                throw new Error('Error en actualizar los datos');
            }

            setEditModal(false);
            toast.success("Triaje cancelado con éxito");

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const options = [
        { value: "notInHotel", label: "El paciente ya no se encuentra en el hotel." },
        { value: "feelBetter", label: "El paciente se siente mejor y no necesita atención médica." },
        { value: "reschedule", label: "El paciente ha decidido posponer la consulta para otro día." },
        { value: "attendedElsewhere", label: "El paciente ha sido atendido por otro médico o centro de salud." },
        { value: "changePlans", label: "El paciente ha tenido un cambio en su horario o planes." },
        { value: "notNecessary", label: "El paciente considera que la consulta ya no es necesaria." },
        { value: "alternativeSolution", label: "El paciente ha encontrado una solución alternativa al problema de salud." },
        { value: "personalReason", label: "Otro motivo personal" },
    ]
    const getLabel = (baseLabel: string) => {
        return baseLabel.replace('El paciente', `El paciente (${rowdata.userName} ${rowdata.userSurname} [ID:${rowdata.userId}]),`);
    }


    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="popup-delete">
                <div className="popup-delete-message">
                    <BsExclamationCircle className='popup-exclamation' />
                    <h3 >¿Estás seguro de cancelar esta consulta pendiente?</h3>

                    <div className="cancellation-group">
                        {rowdata.status === 0 &&
                            <>
                                <h4 className="cancellation-label">Motivo:</h4>
                                <select
                                    name="selectstatus"
                                    id="selectstatus"
                                    value={selectedReason}
                                    onChange={(e) => setSelectedReason(e.target.options[e.target.selectedIndex].text)}
                                    className="custom-select"
                                >
                                    <option value="" disabled>Selecciona la razón</option>
                                    {options.map((option) => (
                                        <option key={option.value} value={getLabel(option.label)}>
                                            {getLabel(option.label)}
                                        </option>
                                    ))}
                                </select>
                            </>
                        }
                        {rowdata.status === 1 && (
                            <>
                                <label
                                    htmlFor="medic_cancel"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        color: '#333',
                                        display: 'block',
                                        marginBottom: '8px',
                                    }}
                                >
                                    Médico que cancela: <span style={{ color: '#ff0000', fontWeight: '600' }}>{rowdata.doctorName}</span>
                                </label>
                                <textarea
                                    name="cancellationReason"
                                    id="medic_cancel"
                                    value={selectedReason}
                                    onChange={(e) => setSelectedReason(e.target.value)}
                                    placeholder="Introduzca el motivo de la cancelación... "
                                    style={{
                                        width: '100%',
                                        height: '120px',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        fontSize: '14px',
                                        lineHeight: '1.5',
                                        resize: 'vertical',
                                        color: '#333',
                                    }}
                                />
                            </>
                        )}

                    </div>

                </div>
                <div className='popup-delete-buttons'>
                    <button type="button" onClick={handleDelete} className="confirm-button">Confirmar</button>
                    <button type="button" onClick={() => setEditModal(false)} className="cancel-button">Cancelar</button>
                </div>
            </div>
        </Modal>

    )
}

export default CancelTriage
