import React from 'react'
import "./CbyZone.scss";
import useFetch from '../../hooks/useFetch';
import Chart from "react-apexcharts";
import { ApexOptions } from 'apexcharts';
import moment from 'moment';

const CbyZone = ({ startDate, endDate }: any) => {

    // console.log(moment(startDate).format('YYYY-MM-DD'))
    // console.log(moment(endDate).format('YYYY-MM-DD'))
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data } = useFetch(`${apiUrl}/consultsByZone?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
    const { tiempo, consultas_total }: any = data;

    const options: ApexOptions = {
        chart: {
            type: 'area',
            stacked: true,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true,
            },
            toolbar: {
                autoSelected: 'zoom',
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0,
        },
        title: {
            text: 'Consultas por zona',
            align: 'left',
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100],
            },
        },
        yaxis: {
            title: {
                text: 'Consultas',
            },
        },
        xaxis: {
            categories: tiempo,
            title: {
                text: 'Fechas',
            },
        },
        tooltip: {
            shared: true,
        },
    };

    const series = [
        {
            name: 'Consultas',
            data: consultas_total,
        },
    ];

    return (
        <div className='chart-container'>
            <Chart options={options} series={series} type='area' height={350} />
        </div>
    );
};

export default CbyZone
