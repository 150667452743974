import React, { useState } from 'react';
import "./UploadAnalytics.scss";
import Modal from '../../utilities/Modal';
import { LiaTimesSolid } from 'react-icons/lia';
import { toast } from 'react-toastify';

const UploadAnalytics = ({ editModal, setEditModal }: any) => {
    const [file, setFile] = useState<File | null>(null);
    const [identificator, setIdentificator] = useState("");
    const [dragActive, setDragActive] = useState(false);

    const handleDrag = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFile(e.dataTransfer.files[0]);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };

    const apiUrl = process.env.REACT_APP_API_URL;
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            if (!file) {
                toast.error('Ningún archivo seleccionado');
                return;
            }
            const formData = new FormData();
            formData.append('identificator', identificator);
            formData.append('file', file);

            const response = await fetch(`${apiUrl}/auxreport/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                },
                body: formData,
            });
            const result = await response.text();
            if (!response.ok) {
                const error = JSON.parse(result);
                console.error(error.message);
                toast.error("El identificador es incorrecto")
            } else {
                setEditModal(false); // Cierra el modal despues de enviar
                toast.success("El archivo se ha subido correctamente")
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="formbold-main-wrapper">
                <div className="formbold-title">
                    <p>Subir prueba analítica</p>
                    <button onClick={() => setEditModal(false)} className='close-modal'>
                        <LiaTimesSolid />
                    </button>
                </div>
                <div className="formbold-form-wrapper">
                    <form onSubmit={handleSubmit}>
                        <div className="formbold-mb-5">
                            <label htmlFor="identificator" className="formbold-form-label">
                                Identificador:
                            </label>
                            <input type="text" id="identificator" placeholder="Identificador de la prueba" className="formbold-form-input" onChange={(e: any) => setIdentificator(e.target.value)} />
                        </div>
                        <div className="mb-6 pt-4">
                            <label className="formbold-form-label formbold-form-label-2">
                                Subir PDFs
                            </label>
                            <div className="formbold-mb-5 formbold-file-input">
                                <input type="file" name="file" id="file" accept=".pdf" onChange={handleChange} />
                                <label
                                    htmlFor="file"
                                    className={`drop-area ${dragActive ? 'drag-active' : ''}`}
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}
                                >
                                    <div>
                                        <span className="formbold-drop-file"> Arrastrar aqui el archivo </span>
                                        <span className="formbold-or"> O </span>
                                        <span className="formbold-browse"> Seleccionar </span>
                                    </div>
                                </label>
                            </div>
                            {file && (
                                <div className="formbold-file-list formbold-mb-5">
                                    <div className="formbold-file-item">
                                        <span className="formbold-file-name">{file.name}</span>
                                        <button onClick={() => setFile(null)}>
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z" fill="currentColor" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z" fill="currentColor" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <input className="formbold-btn w-full" type='submit' value="Subir archivo" />
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default UploadAnalytics
