import React, { useState } from 'react'
import Sidebar from '../components/nav/Sidebar'
import Navbar from '../components/nav/Navbar'
import "./CAdmin.scss"
import Csummary from '../components/statistics/Csummary'

const CAdmin = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

            <div className='companyadmin'>
                <Csummary />
            </div>
        </div>
    )
}

export default CAdmin
