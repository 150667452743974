import React, { useState } from 'react';
import "./Rectify.scss";
import Rectables from '../components/tables/Rectables';
import Navbar from '../components/nav/Navbar';
import Sidebar from '../components/nav/Sidebar';
// import useFetch from '../hooks/useFetch';

const Rectify = () => {
    //const apiUrl = process.env.REACT_APP_API_URL;
    // const { data } = useFetch(`${apiUrl}/accountant/amends`);
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }

    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className='rectify'>
                {/* <div className="rectify-data">
                {data && data.map((item: any, index) => (
                    <div key={index} className="step-items">

                        <div className="steps">
                            <span className="invoice-label">Factura:</span>
                            <span className="invoice-value">{item.reference}</span>
                        </div>

                        <div className="steps">
                            <span className="invoice-label">Rectificativa:</span>
                            <span className="invoice-value">{item.recinvoiceName}</span>
                        </div>
                    </div>
                ))}
            </div> */}
                <Rectables />
            </div>
        </div>
    )
}

export default Rectify
