// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.facturas {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/pages/Bill.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AACJ","sourcesContent":[".facturas {\n    grid-area: main;\n    overflow-y: auto;\n    padding: 20px 20px;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
