// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmgreen-button {
  border: none;
  display: inline-flex;
  padding: 0.625rem 1.25rem;
  align-items: center;
  border-radius: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #20A2A0;
  border: 1px solid #20A2A0;
}

.confirmgreen-button:hover {
  background-color: #187A78;
}

.denyred-button {
  border: none;
  display: inline-flex;
  padding: 0.625rem 1.25rem;
  align-items: center;
  border-radius: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #FF3D32;
  border: 1px solid #FF3D32;
}

.denyred-button:hover {
  background-color: #BF2E25;
}`, "",{"version":3,"sources":["webpack://./src/components/actions/MarkasPaid.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,oBAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,yBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,YAAA;EACA,oBAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,yBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".confirmgreen-button {\n    border: none;\n    display: inline-flex;\n    padding: 0.625rem 1.25rem;\n    align-items: center;\n    border-radius: 2rem;\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 500;\n    text-align: center;\n    color: #ffffff;\n    background-color: #20A2A0;\n    border: 1px solid #20A2A0;\n}\n\n.confirmgreen-button:hover {\n    background-color: #187A78;\n}\n\n.denyred-button {\n    border: none;\n    display: inline-flex;\n    padding: 0.625rem 1.25rem;\n    align-items: center;\n    border-radius: 2rem;\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 500;\n    text-align: center;\n    color: #ffffff;\n    background-color: #FF3D32;\n    border: 1px solid #FF3D32;\n}\n\n.denyred-button:hover {\n    background-color: #BF2E25;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
