import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';

const useWebSocket = (token: string, socketurl: string) => {
    const socketRef = useRef<Socket | null>(null);

    useEffect(() => {
        if (token) {
            const socket = io(socketurl, {
                transports: ['websocket'],
                withCredentials: true,
                reconnection: true,
                auth: { token },
            });

            socket.on('connect', () => {
                console.log('Connected to WebSocket');
            });

            socketRef.current = socket;

            return () => {
                if (socketRef.current) {
                    socketRef.current.disconnect();
                }
            };
        }
    }, [token, socketurl]);

    const registerEvent = (event: string, callback: (data: any) => void) => {
        if (socketRef.current) {
            socketRef.current.on(event, callback);
        }
    };

    const emitEvent = (event: string, data: any) => {
        if (socketRef.current) {
            socketRef.current.emit(event, data);
        }
    };

    return { registerEvent, emitEvent };
};

export default useWebSocket