import React, { useState } from 'react';
import "./Request.scss";
import Reqtables from '../components/tables/Reqtables';
import Navbar from '../components/nav/Navbar';
import Sidebar from '../components/nav/Sidebar';

const Request = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className='request'>
                <Reqtables />
            </div>
        </div>
    )
}

export default Request
