import React from 'react'
import "./Button.scss";

const Button = ({ click, icon, title, disabled }: any) => {
    return (
        <button
            onClick={click}
            className='dropdown-option'
            disabled={disabled}
            style={{
                backgroundColor: disabled ? 'lightgrey' : '#20A2A0',
                cursor: disabled ? 'not-allowed' : 'pointer',
            }}>
            <span className='d-icons'>{icon}</span>
            <span className='tooltip'>{title}</span>
        </button>
    )
}

export default Button
