import React, { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { MdOutlineDesignServices, MdInfo } from 'react-icons/md'
import { HiOutlineReceiptTax } from "react-icons/hi"
import CreateService from '../actions/CreateService'
import CreateComission from '../actions/CreateComission'
import ShowIntervals from '../actions/ShowIntervals'
import './ServiceTable.scss'

const ServiceTable = () => {
    const apiUrl = process.env.REACT_APP_API_URL
    const { data: service, refetch: refetchServices } = useFetch(`${apiUrl}/admin/get/allservices`)
    const { data: commission, refetch: refetchCommissions } = useFetch(`${apiUrl}/admin/get/allcommissions`)

    interface Service {
        id: string;
        code: string;
        name: string;
        description: string;
        tax: number,
        price: number,
    }

    interface Commission {
        id: string;
        name: string;
        interval: number;
        comissionType: number;
        commission: number;
        intervalInfo: {
            intervalStart: number;
            intervalEnd: number;
            price: number;
            type: string;
        }[];

    }

    const [services, setServices] = useState<Service[]>([]);
    const [commissions, setCommissions] = useState<Commission[]>([]);

    const [activeTab, setActiveTab] = useState('services')
    const [createService, setCreateService] = useState(false)
    const [createCommission, setCreateCommission] = useState(false)
    const [showAllIntervals, setShowAllIntervals] = useState(false)
    const [selectedIntervals, setSelectedIntervals] = useState(null)

    useEffect(() => {
        service && setServices(service.filter(item => item !== null))
        commission && setCommissions(commission)
    }, [service, commission])

    const handleShowIntervals = (intervalInfo: any) => {
        setSelectedIntervals(intervalInfo)
        setShowAllIntervals(true)
    }

    console.log(commissions)

    return (
        <div className="service-management">
            <div className="tabs">
                <button
                    className={`tab ${activeTab === 'services' ? 'active' : ''}`}
                    onClick={() => setActiveTab('services')}
                >
                    Servicios
                </button>
                <button
                    className={`tab ${activeTab === 'commissions' ? 'active' : ''}`}
                    onClick={() => setActiveTab('commissions')}
                >
                    Comisiones
                </button>
            </div>

            {activeTab === 'services' && (
                <div className="card">
                    <div className="card-header">
                        <h2>Servicios Disponibles</h2>
                        <button className="create-button" onClick={() => setCreateService(true)}>
                            <MdOutlineDesignServices />
                            Crear Servicio
                        </button>
                    </div>
                    <p className="card-description">Administra tus servicios disponibles aquí.</p>
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Precio</th>
                                <th>Impuesto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {services && services.map((service) => (
                                <tr key={service.code}>
                                    <td>{service.code}</td>
                                    <td>{service.name}</td>
                                    <td>{service.description}</td>
                                    <td>{service.price}€</td>
                                    <td>{service.tax}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {activeTab === 'commissions' && (
                <div className="card">
                    <div className="card-header">
                        <h2>Comisiones Creadas</h2>
                        <button className="create-button" onClick={() => setCreateCommission(true)}>
                            <HiOutlineReceiptTax />
                            Crear Comisión
                        </button>
                    </div>
                    <p className="card-description">Administra tus comisiones aquí.</p>
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nombre</th>
                                <th>Intervalo</th>
                                <th>Comisión %</th>
                                <th>Tipo de Comisión</th>
                                <th>Información del Intervalo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {commissions && commissions.map((commission) => (
                                <tr key={commission.id}>
                                    <td>{commission.id}</td>
                                    <td>{commission.name}</td>
                                    <td>{commission.interval === 0 ? "No" : "Sí"}</td>
                                    <td>{commission.commission !== null ? commission.commission : "N/A"}</td>
                                    <td>{commission.comissionType === 0 ? "Variable" : "Fijo"}</td>
                                    <td>
                                        {commission.intervalInfo && commission.intervalInfo.length > 0 && (
                                            <button className="info-button" onClick={() => handleShowIntervals(commission.intervalInfo)}>
                                                <MdInfo />
                                                Ver Información
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {createService && (
                <CreateService
                    editModal={createService}
                    setEditModal={setCreateService}
                    refetch={refetchServices}
                />
            )}

            {createCommission && (
                <CreateComission
                    editModal={createCommission}
                    setEditModal={setCreateCommission}
                    refetch={refetchCommissions}
                />
            )}

            {showAllIntervals && (
                <ShowIntervals
                    editModal={showAllIntervals}
                    setEditModal={setShowAllIntervals}
                    rowdata={selectedIntervals}
                />
            )}
        </div>
    )
}

export default ServiceTable