import React from 'react';
import { LiaUserSolid } from 'react-icons/lia';
import { Link, useNavigate } from 'react-router-dom';
import './UserModal.scss';
import { User } from '../../context/Context';
import { IoIosLogOut } from 'react-icons/io';

const UserModal = ({ setModal }: any) => {
    const navigate = useNavigate();
    const { currentUser } = User();

    if (!currentUser) {
        return null; // Return null or a loader/spinner if currentUser is not set
    }

    const userModal = [
        {
            title: "Perfil",
            icon: <LiaUserSolid />,
            path: `/miperfil/${currentUser.guid}`,
        },
    ];

    const handleLogout = () => {
        // Eliminar el token del local storage
        localStorage.removeItem('token');
        navigate('/login');
    };

    const getRoleText = (roleId: number) => {
        switch (roleId) {
            case 1:
                return 'Administrador';
            case 4:
                return 'Contable';
            case 5:
                return 'CallCenter';
            case 6:
                return 'Centro Médico';
            default:
                return 'Rol del usuario';
        }
    };

    return (
        <div className='user-modal'>
            <div className='user-modal-menu'>
                <div>
                    <h3>{currentUser?.data?.name ?? 'Nombre del usuario'}</h3>
                    <p>{getRoleText(currentUser?.roleId ?? 0)}</p>
                </div>
                {userModal.map((link, i) => (
                    <Link
                        onClick={() => setModal(false)}
                        className='user-modal-content'
                        key={i}
                        to={link.path}>

                        <span>{link.icon}</span>
                        <h2>{link.title}</h2>
                    </Link>
                ))}
                <button onClick={handleLogout} className='logout-button'>
                    <span><IoIosLogOut /> </span>
                    <h2>Cerrar sesion</h2>
                </button>
            </div>
        </div>
    );
};

export default UserModal
