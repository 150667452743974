import React from 'react'
import Modal from '../../utilities/Modal';
import "./ShowInstruction.scss";
import { LiaTimesSolid } from 'react-icons/lia';

const ShowInstruction = ({ editModal, setEditModal }: any) => {
    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className='triage-info'>
                <div className='triage-info-header'>
                    <h2>Instrucciones para el Proceso de Triaje</h2>
                    <button onClick={() => setEditModal(false)} className='close-modal'>
                        <LiaTimesSolid />
                    </button>
                </div>
                <p className='triage-info-description'>
                    El proceso de triaje consta de 3 pasos sin orden particular. Por favor, lea en qué consiste cada apartado
                </p>
                <div className='calcenter_instructions'>
                    <div className='instruction-step'>
                        <h3>A: Localizar al Cliente</h3>
                        <p>Debes completar el formulario con la información necesaria para localizar al cliente. Asegúrate de obtener el hotel exacto y cualquier otro detalle relevante.</p>
                    </div>
                    <div className='instruction-step'>
                        <h3>B: Identificar Problemas de Salud</h3>
                        <p>Pregunta al cliente sobre los problemas de salud que está experimentando actualmente. Selecciona los síntomas correspondientes en el sistema.</p>
                    </div>
                    <div className='instruction-step'>
                        <h3>C: Validar el Registro del Cliente</h3>
                        <p>Lo más importante de esta sección es rellenar los datos del cliente para crear una nueva cuenta y ver si tiene seguro, y luego llamar a la compañía de seguros para verificarlo.</p>
                    </div>
                </div>
                <p className='callcenter-note'>
                    <strong>Nota:</strong> Tienes la libertad de completar los pasos en el orden que consideres oportuno. Si falta algún campo por rellenar, el icono de interrogación aparecerá sobre el círculo. El botón de "listo" se activará una vez que se hayan rellenado todos los datos.
                </p>
            </div>
        </Modal>
    );
}

export default ShowInstruction
