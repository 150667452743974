// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.original-invoice {
  display: flex;
  padding: 1rem;
}
.original-invoice span {
  border: 2px solid #20A2A0;
  padding: 8px;
}

.rect-download button {
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 0.25rem;
  font-weight: 700;
  color: black;
  background-color: #20A2A0;
  cursor: pointer;
}

.rect-download button:hover {
  color: white;
}

.icon-download {
  font-size: 1rem;
}

.rtable-addon .reportgen {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border-bottom-width: 4px;
  border-color: #047c74;
  font-weight: 700;
  color: white;
  background-color: #20A2A0;
  cursor: pointer;
  align-items: end;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/Rectables.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;AACJ;AAEI;EACI,yBAAA;EACA,YAAA;AAAR;;AAIA;EACI,oBAAA;EACA,gBAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AADJ;;AAIA;EACI,YAAA;AADJ;;AAIA;EACI,eAAA;AADJ;;AAIA;EACI,iBAAA;EACA,qBAAA;EACA,oBAAA;EACA,sBAAA;EACA,wBAAA;EACA,qBAAA;EACA,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;AADJ","sourcesContent":[".original-invoice {\n    display: flex;\n    padding: 1rem;\n\n\n    span {\n        border: 2px solid #20A2A0;\n        padding: 8px;\n    }\n}\n\n.rect-download button {\n    display: inline-flex;\n    padding: 4px 8px;\n    border-radius: 0.25rem;\n    font-weight: 700;\n    color: black;\n    background-color: #20A2A0;\n    cursor: pointer;\n}\n\n.rect-download button:hover {\n    color: white;\n}\n\n.icon-download {\n    font-size: 1rem;\n}\n\n.rtable-addon .reportgen {\n    margin-left: 1rem;\n    margin-bottom: 0.5rem;\n    padding: 0.5rem 1rem;\n    border-radius: 0.25rem;\n    border-bottom-width: 4px;\n    border-color: #047c74;\n    font-weight: 700;\n    color: white;\n    background-color: #20A2A0;\n    cursor: pointer;\n    align-items: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
