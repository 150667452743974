import React, { useState } from 'react';
import "./Users.scss";
import Usertables from '../components/tables/Usertables';
import Navbar from '../components/nav/Navbar';
import Sidebar from '../components/nav/Sidebar';

const Users = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className='usersinfo'>
                <Usertables />
            </div>
        </div>
    )
}

export default Users
