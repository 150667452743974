import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../utilities/Modal';

const CreateService = ({ editModal, setEditModal, refetch }: any) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        svcDesc: '',
        code: '',
        price: '',
        tax: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        console.log({
            ...formData,
            price: parseFloat(formData.price),
            tax: parseFloat(formData.tax),
            svcDesc: JSON.stringify({ [formData.name]: formData.svcDesc })
        })
        try {
            const response = await fetch(`${apiUrl}/service`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token') || '',
                },
                body: JSON.stringify({
                    ...formData,
                    price: parseFloat(formData.price),
                    tax: parseFloat(formData.tax),
                    svcDesc: formData.svcDesc ? { [formData.name]: formData.svcDesc } : null,
                }),
            });

            if (!response.ok) {
                throw new Error('Error creating service');
            }

            toast.success("Servicio creado correctamente");
            setEditModal(false)
            setFormData({
                name: '',
                description: '',
                svcDesc: '',
                code: '',
                price: '',
                tax: ''
            });
            refetch();


        } catch (error) {
            console.error('Error:', error);
            toast.error("Error al crear el servicio");
        }
    };

    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div style={{
                width: '34rem',
                margin: '20px auto',
                padding: '20px',
                backgroundColor: '#f8f9fa',
                borderRadius: '5px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                zIndex: 20,
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '90vh',
                overflowY: 'auto',
            }}>
                <h2 style={{
                    fontSize: '24px',
                    marginBottom: '20px',
                    textAlign: 'center',
                    color: '#333'
                }}>Crear un Nuevo Servicio</h2>
                <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="name" style={labelStyle}>Nombre:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            style={inputStyle}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="description" style={labelStyle}>Descripción:</label>
                        <textarea
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                            style={{ ...inputStyle, height: '100px' }}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="svcDesc" style={labelStyle}>¿Qué incluye el servicio?</label>
                        <input
                            type="text"
                            id="svcDesc"
                            name="svcDesc"
                            value={formData.svcDesc}
                            onChange={handleChange}
                            required
                            style={inputStyle}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="code" style={labelStyle}>Código:</label>
                        <input
                            type="text"
                            id="code"
                            name="code"
                            value={formData.code}
                            onChange={handleChange}
                            required
                            style={inputStyle}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="price" style={labelStyle}>Precio (€):</label>
                        <input
                            type="number"
                            id="price"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            required
                            min="0"
                            step="0.01"
                            style={inputStyle}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="tax" style={labelStyle}>Impuesto (%):</label>
                        <input
                            type="number"
                            id="tax"
                            name="tax"
                            value={formData.tax}
                            onChange={handleChange}
                            required
                            min="0"
                            max="100"
                            step="0.1"
                            style={inputStyle}
                        />
                    </div>
                    <button type="submit" style={buttonStyle}>
                        Crear Servicio
                    </button>
                </form>
            </div>
        </Modal>
    );
};

const labelStyle = {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold' as const,
    color: '#333'
};

const inputStyle = {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontSize: '16px'
};

const buttonStyle = {
    backgroundColor: '#20A2A0',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    width: '100%'
};

export default CreateService