import React, { useState } from 'react'
import "./Zones.scss";
import Zonestable from '../components/tables/Zonestable';
import Zonescard from '../components/cards/Zonescard';
// import DatePicker, { registerLocale } from "react-datepicker";
// import { es } from 'date-fns/locale/es';
// import moment from 'moment';
import TransferMedic from '../components/actions/TransferMedic';
import Navbar from '../components/nav/Navbar';
import Sidebar from '../components/nav/Sidebar';
import useFetch from '../hooks/useFetch';
import MyMedic from '../components/actions/MyMedic';
// registerLocale('es', es);

const Zones = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }

    // const [startDate, setStartDate] = useState(undefined);
    // const [endDate, setEndDate] = useState(undefined);
    // const [selectionComplete, toggleSelectionComplete] = useState(false);
    const [moreDetails, setMoreDetails] = useState(false);
    const [zoneClicked, setzoneClicked] = useState({
        assignedDoctors: 0,
        availableDoctors: 0,
        demands: "",
        pendingRequest: 0,
        zoneId: 0,
        zoneName: ""
    });
    const [transfer, setTransfer] = useState(false);
    const [myMedic, setMyMedic] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: requestperzone, refetch } = useFetch(`${apiUrl}/medicalcenter/request/attZones`);

    // const handleDateChange = (date: any) => {
    //     if (!selectionComplete && !startDate) {

    //         setStartDate(date);
    //         return;
    //     }

    //     if (!selectionComplete && startDate && !endDate) {

    //         setEndDate(date);
    //         toggleSelectionComplete(true);

    //         return;
    //     }

    //     if (selectionComplete && startDate && endDate) {

    //         setStartDate(date);
    //         setEndDate(undefined);
    //         toggleSelectionComplete(false);
    //         return;
    //     }
    // };

    // const handleSelect = (date: any) => {
    //     if (!selectionComplete && startDate && !endDate && sameDay(date, startDate)) {
    //         handleDateChange(date);
    //     }
    // };

    // const sameDay = (d1: Date, d2: Date) => {
    //     return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    // };

    // const getPlaceholderText = () => {
    //     const today = moment().startOf('day');
    //     const startOfWeek = moment().startOf('isoWeek');
    //     const endOfWeek = moment().endOf('isoWeek');
    //     const startOfMonth = moment().startOf('month');
    //     const endOfMonth = moment().endOf('month');

    //     if (startDate && endDate) {
    //         if (moment(startDate).isSame(today, 'day') && moment(endDate).isSame(today, 'day')) {
    //             return "Hoy";
    //         } else if (moment(startDate).isBetween(startOfWeek, endOfWeek, undefined, '[]') && moment(endDate).isBetween(startOfWeek, endOfWeek, undefined, '[]')) {
    //             return "Esta semana";
    //         } else if (moment(startDate).isBetween(startOfMonth, endOfMonth, undefined, '[]') && moment(endDate).isBetween(startOfMonth, endOfMonth, undefined, '[]')) {
    //             return "Este mes";
    //         } else {
    //             return `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`;
    //         }
    //     } else {
    //         return "Seleccione la fecha";
    //     }
    // };

    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

            <div className='zones-module'>
                {moreDetails && (
                    <span
                        className='zonedetails-demand'
                        style={{
                            backgroundColor:
                                zoneClicked.demands === 'ALTA'
                                    ? '#FF9E99'
                                    : zoneClicked.demands === 'MEDIA'
                                        ? '#FFE680'
                                        : 'lightgrey',
                            color:
                                zoneClicked.demands === 'ALTA'
                                    ? '#FF3D32'
                                    : zoneClicked.demands === 'MEDIA'
                                        ? '#806600'
                                        : '#0D1726',
                        }}
                    >
                        Demanda {zoneClicked.demands}
                    </span>
                )}
                {!moreDetails ? (<h2>Zonas de atención</h2>) : (<h2 className='zone-breadcrumbs'><span onClick={() => setMoreDetails(false)}>Todas</span><p>&gt;</p><span>{zoneClicked.zoneName}</span></h2>)}
                <div className='zonedatefilter'>
                    {/* <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        onSelect={handleSelect}
                        selectsEnd={Boolean(startDate)}
                        startDate={startDate}
                        endDate={endDate}
                        shouldCloseOnSelect={false}
                        dateFormat="dd/MM/yyyy"
                        locale="es"
                        placeholderText={getPlaceholderText()}
                    >
                        <div className="date-range">
                            {startDate ? moment(startDate).format('DD/MM/YYYY') : '??/??/????'} - {endDate ? moment(endDate).format('DD/MM/YYYY') : '??/??/????'}
                        </div>
                    </DatePicker> */}
                    {moreDetails && <button className='transfer-button' onClick={() => setTransfer(true)}>Asignar médico</button>}
                </div>
                <Zonescard moreDetails={moreDetails} rowdata={zoneClicked} requestperzone={requestperzone} />
                <Zonestable moreDetails={moreDetails} setMoreDetails={setMoreDetails} setzoneClicked={setzoneClicked} setTransfer={setTransfer} setMyMedic={setMyMedic} requestperzone={requestperzone} />

                {transfer && (
                    <TransferMedic
                        rowdata={zoneClicked}
                        editModal={transfer}
                        setEditModal={setTransfer}
                        refetch={refetch} />
                )}
                {myMedic && (
                    <MyMedic
                        rowdata={requestperzone[2]}
                        editModal={myMedic}
                        setEditModal={setMyMedic}
                        refetch={refetch} />
                )}
            </div>
        </div>

    )
}

export default Zones
