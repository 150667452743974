import React, { useState, useEffect } from 'react';
import { FaTimes, FaBuilding, FaIdCard, FaMapMarkerAlt, FaIndustry, FaPhone, FaCalendarAlt, FaEnvelope, FaUser } from 'react-icons/fa';
import './EditCompany.scss';
import Modal from '../../utilities/Modal';
import useFetch from '../../hooks/useFetch';
import { toast } from 'react-toastify';
import Select from 'react-select';

interface CompanyInfo {
    id: number,
    name: string;
    nif: string;
    address: string;
    type_id: number;
    phone: string;
    dateStart: string;
    dateEnd: string;
    email: string;
    userId: number;
    action_zones: {
        zones: number[];
    }
}

interface CompanyType {
    id: string;
    name: string;
    guid: string;
}

const EditCompany = ({ editModal, setEditModal, refetch, initialData }: any) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: optionsCompany }: { data: CompanyType[] } = useFetch(`${apiUrl}/company/types`);
    const { data: users } = useFetch(`${apiUrl}/admin/users`);
    const { data: zones } = useFetch(`${apiUrl}/admin/zone`);

    const [companyInfo, setCompanyInfo] = useState<CompanyInfo>(initialData);
    const [errors, setErrors] = useState<Partial<CompanyInfo>>({});
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        if (initialData) {
            setCompanyInfo(initialData);
            setErrors({});
        }
    }, [initialData]);

    useEffect(() => {
        if (users) {
            const validRoles = ["Aseguradora", "Medical Center", "Hospital", "Hotel"];
            const filtered = users.filter((user: any) => validRoles.includes(user.roleId));
            setFilteredUsers(filtered);
        }
    }, [users]);

    const zoneOptions = zones && zones.map((zone: any) => ({
        value: zone.id,
        label: zone.zone
    }));

    console.log(companyInfo)

    const handleZoneChange = (selectedOptions: any) => {
        const selectedZoneIds = selectedOptions.map((option: any) => option.value);
        setCompanyInfo({
            ...companyInfo,
            action_zones: { zones: selectedZoneIds }
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === 'phone' && !value.startsWith('+34')) {
            setCompanyInfo(prev => ({ ...prev, [name]: `+34${value}` }));
        } else {
            setCompanyInfo(prev => ({ ...prev, [name]: value }));
        }

        setErrors(prev => ({ ...prev, [name]: '' }));
    };

    const validateForm = (): boolean => {
        const newErrors: Partial<CompanyInfo> = {};
        let isValid = true;

        if (!companyInfo.name.trim()) {
            newErrors.name = 'El nombre de la empresa es obligatorio';
            isValid = false;
        }

        if (!companyInfo.nif.trim()) {
            newErrors.nif = 'El NIF es obligatorio';
            isValid = false;
        }

        if (!companyInfo.email.trim()) {
            newErrors.email = 'El correo electrónico es obligatorio';
        } else if (!/\S+@\S+\.\S+/.test(companyInfo.email)) {
            newErrors.email = 'Formato de correo electrónico inválido';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found.');
                    return;
                }

                const response = await fetch(`${apiUrl}/company/${companyInfo.id}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    body: JSON.stringify({
                        type: +companyInfo.type_id,
                        address: companyInfo.address,
                        dateStart: new Date(companyInfo.dateStart),
                        dateEnd: new Date(companyInfo.dateEnd),
                        name: companyInfo.name,
                        nif: companyInfo.nif,
                        phone: companyInfo.phone,
                        email: companyInfo.email,
                        userId: +companyInfo.userId,
                        action_zones: companyInfo.action_zones ? companyInfo.action_zones.zones : [],
                    }),
                });

                console.log(response)

                if (!response.ok) {
                    throw new Error('Error al editar datos de la empresa');
                } else {
                    toast.success("Empresa modificada con éxito");
                    setEditModal(false);
                    refetch();
                }

            } catch (error) {
                console.error('Error:', error);
                toast.error("Error al editar datos de a la empresa");
            }
        }
    };


    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="cei-modal-overlay">
                <div className="cei-modal-content">
                    <button className="cei-close-button" onClick={() => setEditModal(false)}>
                        <FaTimes />
                    </button>
                    <h2 className="cei-modal-title">Editar Información de la Empresa</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="cei-form-group">
                            <label htmlFor="name">
                                <FaBuilding /> Nombre de la Empresa
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={companyInfo.name}
                                onChange={handleChange}
                                placeholder="Ingrese el nombre de la empresa"
                            />
                            {errors.name && <span className="cei-error">{errors.name}</span>}
                        </div>
                        <div className="cei-form-group">
                            <label htmlFor="nif">
                                <FaIdCard /> NIF
                            </label>
                            <input
                                type="text"
                                id="nif"
                                name="nif"
                                value={companyInfo.nif}
                                onChange={handleChange}
                                placeholder="Ingrese el NIF"
                            />
                            {errors.nif && <span className="cei-error">{errors.nif}</span>}
                        </div>
                        <div className="cei-form-group">
                            <label htmlFor="address">
                                <FaMapMarkerAlt /> Dirección
                            </label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={companyInfo.address}
                                onChange={handleChange}
                                placeholder="Ingrese la dirección"
                            />
                        </div>
                        <div className="cei-form-group">
                            <label htmlFor="type">
                                <FaIndustry /> Tipo de Empresa
                            </label>
                            <select
                                name="type_id"
                                id="type_id"
                                value={companyInfo.type_id}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>Selecciona el tipo</option>
                                {optionsCompany && optionsCompany.map((comm) => (
                                    <option key={comm.guid} value={comm.id}>
                                        {comm.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="cei-form-group">
                            <label htmlFor="phone">
                                <FaPhone /> Teléfono
                            </label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={companyInfo.phone}
                                onChange={handleChange}
                                placeholder="Ingrese el número de teléfono"
                            />
                        </div>
                        <div className="cei-form-group">
                            <label htmlFor="dateStart">
                                <FaCalendarAlt /> Fecha de Inicio Contrato
                            </label>
                            <input
                                type="date"
                                id="dateStart"
                                name="dateStart"
                                value={companyInfo.dateStart ? companyInfo.dateStart.split('T')[0] : ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="cei-form-group">
                            <label htmlFor="dateEnd">
                                <FaCalendarAlt /> Fecha de Fin Contrato
                            </label>
                            <input
                                type="date"
                                id="dateEnd"
                                name="dateEnd"
                                value={companyInfo.dateEnd ? companyInfo.dateEnd.split('T')[0] : ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="cei-form-group">
                            <label htmlFor="email">
                                <FaEnvelope /> Correo Electrónico
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={companyInfo.email}
                                onChange={handleChange}
                                placeholder="Ingrese la dirección de correo electrónico"
                            />
                            {errors.email && <span className="cei-error">{errors.email}</span>}
                        </div>
                        <div className="cei-form-group">
                            <label htmlFor="userId">
                                <FaUser /> Usuario admin
                            </label>
                            <select
                                name="userId"
                                value={companyInfo.userId}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>Selecciona un usuario</option>
                                {filteredUsers.map((user: any) => (
                                    <option key={user.userId} value={user.userId}>
                                        {user.fullname}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="cei-form-group">
                            <label>Zonas de Operación</label>
                            {zoneOptions.length > 0 ? (
                                <Select
                                    name="action_zones"
                                    value={zoneOptions.filter((option: any) => companyInfo.action_zones?.zones?.includes(option.value))}
                                    onChange={(selectedOptions) => handleZoneChange(selectedOptions)}
                                    options={zoneOptions}
                                    isMulti
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    menuPlacement="auto"
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            ) : (
                                <p>No hay zonas disponibles.</p>
                            )}
                        </div>
                        <button type="submit" className="cei-save-button">Guardar Cambios</button>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default EditCompany;

