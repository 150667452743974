import React, { useEffect, useState } from 'react';
import "./Zonescard.scss";
interface ZoneInfo {
    requests: number;
    endRequests: number;
    busyDoctors: number;
    availableDoctors: number;
}

interface ZoneData {
    zoneName: string;
    pendingRequest: number;
    assignedDoctors: number;
    availableDoctors: number;
    demands: string;
    zoneId: number;
}

const Zonescard = ({ moreDetails, rowdata, requestperzone }: any) => {
    const [records, setRecords] = useState<ZoneInfo>();
    const [records2, setRecords2] = useState<ZoneData[]>([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    // para las tarjetas de la zona especifica
    const [records3, setRecords3] = useState<ZoneInfo>();

    useEffect(() => {
        if (requestperzone) {
            setRecords(requestperzone[1] as ZoneInfo);
            setRecords2(requestperzone[2] as ZoneData[]);
        }
    }, [requestperzone]);

    useEffect(() => {
        const getMoreDetails = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const result = await fetch(`${apiUrl}/medicalcenter/request/zoneTriages/${rowdata.zoneId}`, {
                method: "GET",
                headers: {
                    'Authorization': token,
                }
            });
            const jsonData = await result.json();
            setRecords3(jsonData[1]);
        };

        if (moreDetails && rowdata.zoneId !== 0) {
            getMoreDetails();
        }
    }, [apiUrl, moreDetails, rowdata.zoneId]);

    const totalPendingRequests = records2 ? records2.reduce((acc, zone) => acc + zone.pendingRequest, 0) : 0;
    const availableDoctors = records ? records.availableDoctors : 0;

    return (
        <div className='zones-stats'>
            {moreDetails ? (
                <div className='cardstats'>
                    <div className="stat">
                        <span className="statlabel">Solicitudes pendientes</span>
                        <span className="statnumber">{rowdata?.pendingRequest}/{records3?.requests}</span>
                    </div>
                    <div className="stat">
                        <span className="statlabel">Consultas realizadas</span>
                        <span className="statnumber">{records3?.endRequests}</span>
                    </div>
                    <div className="stat">
                        <span className="statlabel">Médicos asignados</span>
                        <span className="statnumber">{rowdata?.assignedDoctors}</span>
                    </div>
                </div>
            ) : (
                <div className='cardstats'>

                    <div className="stat">
                        <span className="statlabel">Solicitudes pendientes</span>
                        <span className="statnumber">{totalPendingRequests}/{records?.requests}</span>
                    </div>
                    <div className="stat">
                        <span className="statlabel">Consultas realizadas</span>
                        <span className="statnumber">{records?.endRequests}</span>
                    </div>
                    <div className="stat">
                        <span className="statlabel">Médicos disponibles</span>
                        <span className="statnumber">{availableDoctors}</span>
                    </div>
                    <div className="stat">
                        <span className="statlabel">Médicos ocupados</span>
                        <span className="statnumber">{records?.busyDoctors}</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Zonescard
