import React, { useState } from 'react';
import "./Navbar.scss";
import { BsPersonCircle, BsJustify } from 'react-icons/bs';
import Modal from '../../utilities/Modal';
// import Notification from './Notification';
import UserModal from './UserModal';
// import Message from './Message';

const Navbar = ({ OpenSidebar }: any) => {
    // const [notifModal, setNotifModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    // const [msgModal, setMsgModal] = useState(false);

    return (
        <header className='header'>
            <div className='menu-icon'>
                <BsJustify className='icon' onClick={OpenSidebar} />
            </div>
            {/* <div className='header-left'>
                <BsSearch className='icon' />
            </div> */}
            <div className='header-right'>

                {/* <div className='nav-modal'>
                    <span
                        onClick={() => setNotifModal(true)}>
                        <BsFillBellFill className='icon' />
                        <div className='indicator'></div>
                    </span>
                    <Modal modal={notifModal} setModal={setNotifModal}>
                        <div className='nav-trans' style={notifModal ? { visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                            <Notification setModal={setNotifModal} />
                        </div>
                    </Modal>
                </div> */}

                {/* <div className='nav-modal'>
                    <span
                        onClick={() => setMsgModal(true)}>
                        <BsFillEnvelopeFill className='icon' />
                    </span>
                    <Modal modal={msgModal} setModal={setMsgModal}>
                        <div className='nav-trans' style={msgModal ? { visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                            <Message setModal={setMsgModal} />
                        </div>
                    </Modal>
                </div> */}


                <div className='nav-modal'>
                    <span
                        onClick={() => setUserModal(true)}>
                        <BsPersonCircle className='icon' />
                    </span>
                    <Modal modal={userModal} setModal={setUserModal}>
                        <div className='nav-trans' style={userModal ? { visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                            <UserModal setModal={setUserModal} />
                        </div>
                    </Modal>
                </div>
            </div>
        </header>
    );
};

export default Navbar
