import React, { useState } from 'react'
import Navbar from '../components/nav/Navbar'
import Sidebar from '../components/nav/Sidebar'
import "./Transactions.scss"
import SumUp from '../components/tables/SumUp'

const Transactions = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }

    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

            <div className='transacciones'>
                <SumUp />
            </div>
        </div>
    )
}

export default Transactions