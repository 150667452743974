import React, { useEffect, useState } from 'react';
import "./Profile.scss";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import Accesstable from '../components/tables/Accesstable';
import Update from '../components/actions/Update';
import { LiaTimesSolid } from 'react-icons/lia';
import RemoveMac from '../components/actions/RemoveMac';
import Navbar from '../components/nav/Navbar';
import Sidebar from '../components/nav/Sidebar';
import useFetch from '../hooks/useFetch';
import AttentionHistory from '../components/tables/AttentionHistory';
import { IoArrowBackOutline } from "react-icons/io5";
import docavatar from '../assets/doc-avatar.png';

interface User {
    userId: number;
    guid: string;
    fullname: string;
    email: string;
    createAt: string;
    roleId: string;
    zone: string | null;
    status: string | null;
    phone: string;
}
const Profile = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    const { guid: userId } = useParams();
    const [editUser, setEditUser] = useState(false);
    const [deleteMac, setDeleteMac] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: users } = useFetch(`${apiUrl}/admin/users`);

    const userData = users && users.find((user: any) => user.guid.toString() === userId) as User | undefined;
    const location = useLocation();
    const { role } = location.state || {};
    const navigate = useNavigate();

    const { data: rating } = useFetch(`${apiUrl}/admin/doctor/evaluation/${userId}`) as any;
    const [showHistory, setShowHistory] = useState(false);

    useEffect(() => {
        if (role) {
            if (role === "Medico" || role === "Call Center") {
                setShowHistory(true);
            }
        };
    }, [role]);

    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className="profile-container">
                {userData && (
                    <div className="profile">
                        <div className='profile-left'>
                            <img className='profile-user-img' src={docavatar} alt="user profile pic" />
                            <h2>{userData?.fullname}</h2>
                            {(userData.roleId === "Medico" && rating !== 0) && (
                                <div className="rating">
                                    {[...Array(5)].map((_, index) => {
                                        if (index < Math.floor(rating)) {
                                            return (
                                                <span key={index}>
                                                    <FaStar className="star checked" />
                                                </span>
                                            );
                                        } else if (index < rating) {
                                            return (
                                                <span key={index}>
                                                    <FaStarHalfAlt className="star checked" />
                                                </span>
                                            );
                                        } else {
                                            return (
                                                <span key={index}>
                                                    <FaStar className="star" style={{ color: 'lightgray' }} />
                                                </span>
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        </div>

                        <div className='profile-right'>
                            <h3>Información del usuario</h3>
                            <p><strong>Rol:</strong> {userData.roleId}</p>
                            <p><strong>Zona actual:</strong> {userData.zone ? userData.zone : "-"}</p>
                            <p><strong>Estado {userData.roleId === "Paciente" ? "triaje:" : "actual:"}</strong> {userData.status ? userData.status : "-"}</p>
                            <p><strong>Correo:</strong> {userData.email}</p>
                            <p><strong>Número de contacto:</strong> {userData.phone}</p>
                        </div>

                        <div className='profile-device'>
                            <h4>Dispositivos asignados</h4>

                            <div className='mac-address'>
                                <p>1C-A7-64-68-F2-B5</p>
                                <button onClick={() => setDeleteMac(true)} className='remove-mac'><LiaTimesSolid /></button>
                            </div>
                            <div className='mac-address'>
                                <p>33-0B-A8-E7-2C-51</p>
                                <button onClick={() => setDeleteMac(true)} className='remove-mac'><LiaTimesSolid /></button>
                            </div>
                            <div className='mac-address'>
                                <p>8F-A7-70-AF-5D-60</p>
                                <button onClick={() => setDeleteMac(true)} className='remove-mac'><LiaTimesSolid /></button>
                            </div>

                        </div>

                        <div className='profile-addon'>
                            <IoArrowBackOutline className='goback-button' onClick={() => navigate(-1)} />
                            <button className='update-button-profile' onClick={() => setEditUser(true)}>Editar</button>
                        </div>
                    </div>
                )}
                {showHistory && (
                    <AttentionHistory />
                )}
                <Accesstable />
                {editUser && (
                    <Update
                        rowdata={userData}
                        editModal={editUser}
                        setEditModal={setEditUser} />
                )}
                {deleteMac && (
                    <RemoveMac
                        rowdata={userData}
                        editModal={deleteMac}
                        setEditModal={setDeleteMac} />
                )}
            </div>
        </div>
    )
}

export default Profile
