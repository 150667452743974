import Modal from '../../utilities/Modal'
import './CommissionInfo.scss'

const CommissionInfo = ({ editModal, setEditModal, rowdata: commission }: any) => {

    console.log("PQ NO SE MUESTRA?!")

    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="commission-modal-overlay">
                <div className="commission-modal">
                    <div className="commission-modal-header">
                        <h2 className="commission-modal-title">{commission?.name}</h2>
                        <button className="commission-modal-close" onClick={() => setEditModal(false)}>
                            x
                        </button>
                    </div>
                    <div className="commission-modal-content">
                        {commission.interval === 0 && commission.commission !== null && (
                            <div className="commission-fixed">
                                <h3 className="commission-section-title">Comisión Fija</h3>
                                <p className="commission-fixed-amount">
                                    €{commission.commission}
                                </p>
                            </div>
                        )}
                        {commission.interval === 1 && commission.intervalInfo && (
                            <div className="commission-intervals">
                                <h3 className="commission-section-title">Comisión por Tramo</h3>
                                <ul className="commission-interval-list">
                                    {commission.intervalInfo.map((interval: any, index: any) => (
                                        <li key={index} className="commission-interval-item">
                                            <span className="commission-interval-range">
                                                {interval.intervalStart} - {interval.intervalEnd}
                                            </span>
                                            <span className="commission-interval-price">
                                                {interval.price}, {interval.type} {interval.type === "%" ? "precio variable" : "precio fijo"}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CommissionInfo