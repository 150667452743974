import React, { useEffect, useState } from 'react';
import "./Rectables.scss";
import DataTable from 'react-data-table-component';
import useFetch from '../../hooks/useFetch';
import moment from 'moment';
import { MdFileDownload } from 'react-icons/md';
import jsPDFInvoiceTemplate from 'jspdf-invoice-template';
import Button from '../../utilities/Button';

const Rectables = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data } = useFetch(`${apiUrl}/accountant/amends`);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        data && setRecords(data); // Initialize state with the fetched data
    }, [data]);

    const columns = [
        {
            name: "Rectificativa",
            selector: (row: any) => row.recinvoiceName,
            sortable: true,

        },
        {
            name: "Factura",
            selector: (row: any) => row.reference,
            sortable: true,

        },
        {
            name: "Fecha",
            selector: (row: any) => moment(row.updateAt).format(('DD/MM/YYYY')),
            sortable: true,
        },
        {
            name: "Motivo",
            selector: (row: any) => row.reason,
            grow: 3,
            wrap: true,
        },
        {
            name: "Acción",
            cell: (row: any) => (
                <div className='action-dropdown'>
                    <Button
                        click={() => handleDownload(row)}
                        title="Descargar"
                        icon={<MdFileDownload />}
                        disabled={false}
                    />
                </div>
            )
        },


    ];

    const handleDownload = async (row: any) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            await fetch(`${apiUrl}/accountant/recinvoicepdf/${row.guid}`, {
                method: "GET",
                headers: {
                    'Authorization': token,
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = `${row.recinvoiceName}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });


        } catch (error) {
            console.error('Error:', error);
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const ExpandedComponent = ({ data }: any) => <pre>{
        <div className='original-invoice'>
            <span>Factura: {data.invoice.invoiceName}</span>
            <span>Expedición: {moment(data.invoice.createAt).format('DD/MM/YYYY')}</span>
            <span>Cliente: {data.invoice.username + " " + data.invoice.usersurname}</span>
            <span>Servicio: {data.invoice.services.map((service: any) => service.Service).join(', ')}</span>
            <span>Importe: -{data.invoice.amount}</span>
        </div>
    }</pre>;


    const exportReport = () => {
        jsPDFInvoiceTemplate(propsObject);
    }

    const totalAmount = records.reduce((total: number, invoice: any) => total + parseFloat(invoice.amount), 0);

    const igicAmount = (totalAmount * 7) / 100;
    var propsObject = {
        outputType: "save",
        returnJsPDFDocObject: true,
        fileName: `Informe_Rectificativa_${new Date().getFullYear()}`,
        orientationLandscape: false,
        compress: true,
        logo: {
            src: "/dr2u-logo.png",
            width: 43.33, //aspect ratio = width/height
            height: 36.66,
            margin: {
                top: -8, //negative or positive num, from the current position
                left: 0 //negative or positive num, from the current position
            }
        },
        stamp: {
            inAllPages: true,
            src: "https://raw.githubusercontent.com/edisonneza/jspdf-invoice-template/demo/images/qr_code.jpg",
            width: 20, //aspect ratio = width/height
            height: 20,
            margin: {
                top: 0, //negative or positive num, from the current position
                left: 0 //negative or positive num, from the current position
            }
        },
        business: {
            name: "Emprende Soluciones y Servicios 33, S.L.",
            address: "C/ Rafael Cabrera 7, Trasera Local 20, 35002",
            phone: "Las Palmas de Gran Canaria",
            email: "928 986 872",
            website: "info@dr2u.com",
            email_1: "www.dr2u.es",
        },
        contact: {
            label: "Factura emitida por:",
            name: "DR2U Contabilidad",
            address: "Avda. Rafael Cabrera, 7 (Trasera Local 20)",
            phone: "Las Palmas de Gran Canaria",
            email: "(+34) 928 986 872",
            otherInfo: "info@dr2u.com",
        },
        invoice: {
            label: "Informe de Rectificativas ",
            num: new Date().getFullYear(),
            headerBorder: true,
            tableBodyBorder: true,
            header: [
                {
                    title: "#",
                    style: {
                        width: 10
                    }
                },
                {
                    title: "Rectificativa > Original",
                    style: {
                        width: 40
                    }
                },
                {
                    title: "Fecha",
                    style: {
                        width: 30
                    }
                },
                {
                    title: "Justificación",
                    style: {
                        width: 50
                    }
                },
                { title: "Base" },
                { title: "IGIC" },
                { title: "Total" }
            ],
            // no se tiene en cuenta las facturas rechazadas 
            table: records.map((col: any, index) => ([
                index + 1,
                `${col.recinvoiceName} > ${col.invoice.invoiceName} `,
                `${moment(col.updateAt).format(('DD/MM/YYYY'))}`,
                `${col.reason}`,
                `${col.amount}`,
                `${((parseFloat(col.amount) * 7) / 100)}€`,
                `${((parseFloat(col.amount) * 7) / 100) + parseFloat(col.amount)}€`,
            ])),
            additionalRows: [{

                col1: 'SubTotal Facturado:',
                col2: `${totalAmount}€`,
                col3: 'ALL',
                style: {
                    fontSize: 10 //optional, default 12
                }
            },
            {
                col1: 'IGIC (7%):',
                col2: `${igicAmount}€`,
                col3: 'ALL',
                style: {
                    fontSize: 10 //optional, default 12
                }
            },
            {
                col1: 'Total Facturado:',
                col2: `${(totalAmount + igicAmount)}€`,
                col3: 'ALL',
                style: {
                    fontSize: 14 //optional, default 12
                }
            }] as any,

            invDescLabel: "Aviso legal",
            invDesc: "En cumplimiento del Reglamento de Protección de Datos de Carácter Personal, (RGPD/UE/2016/679),le comunicamos que en EMPRENDE SOLUCIONES Y SERVICIOS 33, S.L.U. B76345594, Responsable del fichero utilizará los datos facilitados por usted con el fin concreto de los mismos, comprometiéndose a tratar de forma confidencial los datos de carácter personal facilitados y a no comunicar o ceder dicha información a terceros salvo disposición legal",
        },
        footer: {
            text: "Esta factura ha sido generada automáticamente en un ordenador y es válida sin la firma y el sello",
        },
        pageEnable: true,
        pageLabel: "Página ",
    }

    return (
        <div className="table-bill">
            <div className="table-container">
                <span className='table-name'>Rectificativas generadas</span>
                <div className='rtable-addon'>
                    <button className='reportgen' onClick={exportReport} >INFORME</button>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={records}
                pagination paginationComponentOptions={paginationComponentOptions}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                noDataComponent="No hay ningún dato que mostrar"
            />

        </div>
    )
}

export default Rectables
