import React, { useState } from 'react';
import "./Callcenter.scss";
import Triage from '../components/forms/Triage';
import Navbar from '../components/nav/Navbar';
import Sidebar from '../components/nav/Sidebar';

const Callcenter = () => {

    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className='callcenter'>
                <h2>Atender a un nuevo paciente</h2>
                <Triage />
            </div>
        </div>
    )
}

export default Callcenter
