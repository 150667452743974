import React, { useState } from 'react'
import "./Dashboard.scss";
import Navbar from '../components/nav/Navbar'
import Sidebar from '../components/nav/Sidebar'
import CbyZone from '../components/statistics/CbyZone';
import DatePicker, { registerLocale } from "react-datepicker";
import CustomDateInput from '../utilities/CustomDateInput';
import { es } from 'date-fns/locale/es';
import moment from 'moment';
registerLocale('es', es);

const Dashboard = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }

    // page functions start here
    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>();
    const [selectionComplete, toggleSelectionComplete] = useState(false);


    const handleDateChange = (date: Date | null) => {
        if (!selectionComplete && !startDate) {
            setStartDate(date);
            return;
        }

        if (!selectionComplete && startDate && !endDate) {
            setEndDate(date);
            toggleSelectionComplete(true);
        }

        if (selectionComplete && startDate && endDate) {
            setStartDate(date);
            setEndDate(undefined);
            toggleSelectionComplete(false);
            return;
        }
    };

    const handleSelectDate = (date: Date | null) => {
        if (date && !selectionComplete && startDate && !endDate && sameDay(date, startDate)) {
            handleDateChange(date);
        }
    };

    const handleShortcutChange = (value: string) => {
        const today = moment();
        let start: moment.Moment | null = null;
        let end: moment.Moment | null = null;

        switch (value) {
            case 'today':
                start = today.startOf('day');
                end = today.endOf('day');
                break;
            case 'week':
                start = today.clone().startOf('week').add(1, 'day');
                end = today.clone().endOf('week').add(1, 'day');
                break;
            case 'month':
                start = today.clone().startOf('month');
                end = today.clone().endOf('month');
                break;
            case 'year':
                start = today.clone().startOf('year');
                end = today.clone().endOf('year');
                break;

            default:
                break;
        }

        if (start && end) {
            setStartDate(start.toDate());
            setEndDate(end.toDate());
            toggleSelectionComplete(true);

        }
    };
    const sameDay = (d1: Date, d2: Date) => {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    };

    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className='dashboard'>
                <h2>Estadísticas generales</h2>
                <div>
                    <span className='makegreen'>Intervalo de fechas: </span>
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        onSelect={handleSelectDate}
                        selectsEnd={Boolean(startDate)}
                        startDate={startDate}
                        endDate={endDate}
                        shouldCloseOnSelect={false}
                        dateFormat="dd/MM/yyyy"
                        locale="es"
                        customInput={<CustomDateInput startDate={startDate} endDate={endDate} />}
                    >
                        <div className="date-range">
                            {startDate ? moment(startDate).format('DD/MM/YYYY') : '??/??/????'} - {endDate ? moment(endDate).format('DD/MM/YYYY') : '??/??/????'}

                        </div>

                    </DatePicker>
                    <div className='graphfilter-date'>
                        <p>Atajo de fechas:</p>
                        <div className='date-option'>
                            <div className='bobo'>
                                <input type="radio" id="today" name="date-option" value="Hoy" onChange={() => handleShortcutChange('today')} />
                                <label htmlFor="today">Hoy</label>
                            </div>

                            <div className='bobo'>
                                <input type="radio" id="week" name="date-option" value="Semana" onChange={() => handleShortcutChange('week')} />
                                <label htmlFor="week">Semana</label>
                            </div>

                            <div className='bobo'>
                                <input type="radio" id="month" name="date-option" value="Mes" onChange={() => handleShortcutChange('month')} />
                                <label htmlFor="month">Mes</label>
                            </div>

                            <div className='bobo'>
                                <input type="radio" id="year" name="date-option" value="Año" onChange={() => handleShortcutChange('year')} />
                                <label htmlFor="year">Año</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='daashboard-graph'>
                    <CbyZone startDate={startDate} endDate={endDate} />
                </div>
            </div>
        </div>
    )
}

export default Dashboard
