import React from 'react'
import Modal from '../../utilities/Modal'

const ShowIntervals = ({ editModal, setEditModal, rowdata }: any) => {
    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div style={{
                width: '100%',
                maxWidth: '34rem',
                margin: '0 auto',
                backgroundColor: '#ffffff',
                borderRadius: '0.5rem',
                zIndex: 20,
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '90vh',
                overflowY: 'auto',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                padding: '20px'
            }}>
                <h2 style={{
                    fontSize: '24px',
                    marginBottom: '20px',
                    textAlign: 'center'
                }}>Información de los Intervalos</h2>
                <div style={{
                    overflowX: 'auto'
                }}>
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                    }}>
                        <thead>
                            <tr style={{
                                backgroundColor: '#e9ecef'
                            }}>
                                <th style={tableHeaderStyle}>Inicio</th>
                                <th style={tableHeaderStyle}>Fin</th>
                                <th style={tableHeaderStyle}>Precio - Tipo</th>
                                {/* <th style={tableHeaderStyle}>Tipo</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {rowdata.map((interval: any, index: number) => (
                                <tr key={index} style={{
                                    backgroundColor: index % 2 === 0 ? '#ffffff' : '#f8f9fa'
                                }}>
                                    <td style={tableCellStyle}>{interval.intervalStart}</td>
                                    <td style={tableCellStyle}>{interval.intervalEnd}</td>
                                    <td style={tableCellStyle}>{interval.price} {interval.type} {interval.type === "%" ? "(Variable)" : "(Fijo)"}</td>
                                    {/* <td style={tableCellStyle}>{interval.type}  {interval.type === "%" ? "(Variable)" : "(Fijo)"}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>
    )
}

const tableHeaderStyle = {
    padding: '12px',
    textAlign: 'left' as const,
    borderBottom: '2px solid #dee2e6',
    fontWeight: 'bold'
}

const tableCellStyle = {
    padding: '12px',
    borderBottom: '1px solid #dee2e6'
}

export default ShowIntervals
