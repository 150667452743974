import React, { useState } from 'react'
import "./Reimbursement.scss";
import Modal from '../../utilities/Modal';
import { toast } from 'react-toastify';
import { LiaTimesSolid } from 'react-icons/lia';

const Reimbursement = ({ editModal, setEditModal }: any) => {
    const currentYear = new Date().getFullYear().toString().slice(2)
    const [identificator, setIdentificator] = useState("0000000");
    const [amount, setAmount] = useState("");

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const data = {
            TriageIdentificator: `CG${currentYear}${identificator}`,
            amount: parseFloat(amount.replace(/,/, '.'))
        };

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const response = await fetch(`${apiUrl}/sumup/ambulance/refund`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                toast.success('Devolución enviada correctamente');
                setIdentificator("00000");
                setAmount("");
                setEditModal(false);
            } else {
                toast.error('Fallo al enviar los datos. Compruebe el identificador');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while submitting data.');
        }
    };
    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="refund">
                <div className="refund-header">
                    <h2>Devolución del depósito</h2>
                    <button onClick={() => setEditModal(false)} className='close-modal'>
                        <LiaTimesSolid />
                    </button>
                </div>

                <div className="input-group">
                    <label htmlFor="identificator">Identificador</label>
                    {/* <span className="refund-description">Introduce los últimos 6 dígitos de la consulta</span> */}
                    <span className="refund-description">Introduce los últimos 7 dígitos de la consulta</span>
                    <div className="input-wrapper">
                        {/* <span className="wrapper-prefix">CG{currentYear}</span> */}
                        <span className="wrapper-prefix">DR{currentYear}</span>

                        <input
                            type="text"
                            id="identificator"
                            value={identificator}
                            onChange={(e) => setIdentificator(e.target.value)}
                        />
                    </div>
                </div>

                <div className="input-group">
                    <label htmlFor="amount">Importe</label>
                    <span className="refund-description">El importe a devolver con coma o punto</span>
                    <div className="input-wrapper">
                        <input
                            type="number"
                            id="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            step="0.01"
                        />
                        <span className="wrapper-suffix">€</span>
                    </div>
                </div>
                <div className="line"></div>
                <div style={{ display: 'flex', gap: "1rem" }}>
                    <label htmlFor="click" className="close-btn" onClick={handleSubmit}>Enviar</label>

                </div>
            </div>
        </Modal>
    )
}

export default Reimbursement
