// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rtable-addon {
  display: flex;
  justify-content: end;
}
.rtable-addon input {
  padding: 8px;
  border-radius: 8px;
  border-width: 1px;
  border-color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/Reqtables.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oBAAA;AACJ;AACI;EAEI,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;AAAR","sourcesContent":[".rtable-addon {\n    display: flex;\n    justify-content: end;\n\n    input {\n\n        padding: 8px;\n        border-radius: 8px;\n        border-width: 1px;\n        border-color: black;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
