import React from 'react';
import image from '../../assets/not-found.png';

const NoDataFallback: React.FC = () => {
    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <img
                    src={image}
                    alt="No hay datos"
                    style={styles.image}
                />
                <h1 style={styles.title}>No hay datos disponibles</h1>
                <p style={styles.message}>
                    Lo sentimos, actualmente no hay datos de comisiones para mostrar.
                </p>
                <div style={styles.reasonsContainer}>
                    <p style={styles.subMessage}>
                        Esto podría deberse a:
                    </p>
                    <ul style={styles.list}>
                        <li>Aún no se han registrado comisiones</li>
                        <li>Puede haber un problema con la recuperación de datos</li>
                        <li>Tu cuenta podría no tener datos asociados</li>
                    </ul>
                </div>
                <p style={styles.subMessage}>
                    Por favor, vuelve a intentarlo más tarde o contacta con soporte si el problema persiste.
                </p>
            </div>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: '20px',
        boxSizing: 'border-box',
        backgroundColor: '#f5f5f5',

    },
    content: {
        backgroundColor: '#ffffff',
        borderRadius: '16px',
        padding: '2rem',
        width: '100%',
        maxWidth: '500px',
        textAlign: 'center',
        boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1)',
    },
    image: {
        width: '300px',
        height: '200px',
        maxWidth: '100%',
        marginBottom: '1.5rem',
    },
    title: {
        color: '#2c3e50',
        fontSize: '2rem',
        marginBottom: '1rem',
    },
    message: {
        color: '#34495e',
        fontSize: '1.1rem',
        marginBottom: '1.5rem',
    },
    reasonsContainer: {
        backgroundColor: '#f8f9fa',
        borderRadius: '8px',
        padding: '1rem',
        marginBottom: '1.5rem',
    },
    subMessage: {
        color: '#34495e',
        fontSize: '1rem',
        marginBottom: '0.5rem',
    },
    list: {
        listStyleType: 'none',
        padding: 0,
        margin: '0.5rem 0 0 0',
        color: '#7f8c8d',
        fontSize: '0.9rem',
        textAlign: 'left',
    },
};

export default NoDataFallback;

