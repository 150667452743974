import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TbCreditCardPay, TbFileInvoice, TbReportMoney } from "react-icons/tb";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsGraphUp } from "react-icons/bs";
import { RiCustomerService2Line } from "react-icons/ri";
import { GrMap } from "react-icons/gr";
import { CiMedicalClipboard } from "react-icons/ci";
import { LiaTimesSolid } from 'react-icons/lia';
import './Sidebar.scss';
import logo from '../../assets/whitelogo.svg';
import { User } from '../../context/Context';
import { GiAmbulance } from 'react-icons/gi';
import { MdOutlineBusinessCenter, MdOutlineMiscellaneousServices } from 'react-icons/md';
import { FaChartPie } from 'react-icons/fa';

const Sidebar = ({ openSidebarToggle, OpenSidebar }: any) => {
    const location = useLocation();
    const { pathname } = location;
    const indicatorDiv = useRef<HTMLDivElement>(null);
    const { currentUser } = User();

    const handleItemClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const offsetTop = e.currentTarget.offsetTop;
        const scrollTop = document.documentElement.scrollTop;
        const topPosition = `${offsetTop - scrollTop}px`;

        if (indicatorDiv.current) {
            indicatorDiv.current.style.top = topPosition;
        }
    };

    const isActive = (path: string) => pathname === path ? 'active' : '';

    if (!currentUser) {
        return null; // Return null or a loader/spinner if currentUser is not set
    }

    return (
        <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : "closed"}>
            <div className='sidebar-title'>
                <div className='sidebar-brand'>
                    <Link to="/"><img src={logo} alt="logo" /></Link>
                </div>
                <span className='icon close_icon' onClick={OpenSidebar}><LiaTimesSolid /></span>
            </div>
            {/* {currentUser.roleId === 1 && (
                <ul className='sidebar-list'>
                    <Link to="/dashboard" className='link'>
                        <li className={`sidebar-list-item ${isActive('/dashboard')}`} onClick={handleItemClick}>
                            <BsFileEarmarkBarGraph className='icon' />
                            <span>Dashboard</span>
                        </li>
                    </Link>
                </ul>
            )} */}

            {(currentUser.roleId === 1 || currentUser.roleId === 5) && (
                <ul className='sidebar-list'>
                    <p className='list-title'>Call Center</p>
                    <Link to="/consultas" className='link'>
                        <li className={`sidebar-list-item ${isActive('/')}`} onClick={handleItemClick}>
                            <CiMedicalClipboard className='icon' />
                            <span>Consultas</span>
                        </li>
                    </Link>
                    <Link to="/callcenter" className='link'>
                        <li className={`sidebar-list-item ${isActive('/callcenter')}`} onClick={handleItemClick}>
                            <RiCustomerService2Line className='icon' />
                            <span>Triaje nuevo</span>
                        </li>
                    </Link>
                </ul>
            )}

            {(currentUser.roleId === 1 || currentUser.roleId === 6) && (
                <ul className='sidebar-list'>
                    <p className='list-title'>Centro Médico</p>
                    {currentUser.roleId === 1 && (
                        <Link to="/zonas" className='link'>
                            {/* <li className={`sidebar-list-item ${currentUser.roleId === 6 ? isActive('/') : isActive('/zonas')}`} onClick={handleItemClick}> */}
                            <li className={`sidebar-list-item ${isActive('/zonas')}`} onClick={handleItemClick}>
                                <GrMap className='icon' />
                                <span>Zonas</span>
                            </li>
                        </Link>
                    )}
                    {currentUser.roleId === 6 && (
                        <Link to="/consultas" className='link'>
                            <li className={`sidebar-list-item ${currentUser.roleId === 6 ? isActive('/') : isActive('/consultas')}`} onClick={handleItemClick}>
                                <CiMedicalClipboard className='icon' />
                                <span>Consultas</span>
                            </li>
                        </Link>
                    )}
                </ul>
            )}

            {(currentUser.roleId === 1 || currentUser.roleId === 4) && (
                <ul className='sidebar-list'>
                    <p className='list-title'>Contabilidad</p>
                    <Link to="/resumen" className='link'>
                        <li className={`sidebar-list-item ${currentUser.roleId === 4 ? isActive('/') : isActive('/resumen')}`} onClick={handleItemClick}>
                            <BsGraphUp className='icon' />
                            <span>Resumen</span>
                        </li>
                    </Link>
                    <Link to="/facturas" className='link'>
                        <li className={`sidebar-list-item ${isActive('/facturas')}`} onClick={handleItemClick}>
                            <TbFileInvoice className='icon' />
                            <span>Facturas</span>
                        </li>
                    </Link>
                    <Link to="/rectificativas" className='link'>
                        <li className={`sidebar-list-item ${isActive('/rectificativas')}`} onClick={handleItemClick}>
                            <TbReportMoney className='icon' />
                            <span>Rectificativas</span>
                        </li>
                    </Link>
                    {(currentUser.roleId === 4) &&
                        <Link to="/transacciones" className='link'>
                            <li className={`sidebar-list-item ${isActive('/transacciones')}`} onClick={handleItemClick}>
                                <TbCreditCardPay className='icon' />
                                <span>Transacciones</span>
                            </li>
                        </Link>
                    }
                </ul>
            )}

            {currentUser.roleId === 1 && (
                <ul className='sidebar-list'>
                    <p className='list-title'>Administración</p>
                    <Link to="/usuarios" className='link'>
                        <li className={`sidebar-list-item ${isActive('/usuarios')}`} onClick={handleItemClick}>
                            <AiOutlineUsergroupAdd className='icon' />
                            <span>Usuarios</span>
                        </li>
                    </Link>
                    <Link to="/empresas" className='link'>
                        <li className={`sidebar-list-item ${isActive('/empresas')}`} onClick={handleItemClick}>
                            <MdOutlineBusinessCenter className='icon' />
                            <span>Empresas</span>
                        </li>
                    </Link>
                    <Link to="/servicios" className='link'>
                        <li className={`sidebar-list-item ${isActive('/servicios')}`} onClick={handleItemClick}>
                            <MdOutlineMiscellaneousServices className='icon' />
                            <span>Servicios</span>
                        </li>
                    </Link>
                    <Link to="/transacciones" className='link'>
                        <li className={`sidebar-list-item ${isActive('/transacciones')}`} onClick={handleItemClick}>
                            <TbCreditCardPay className='icon' />
                            <span>Transacciones</span>
                        </li>
                    </Link>
                </ul>
            )}

            {(currentUser.roleId === 1 || currentUser.roleId === 8) && (
                <ul className='sidebar-list'>
                    <p className='list-title'>Hospital</p>
                    <Link to="/hospital" className='link'>
                        <li className={`sidebar-list-item ${currentUser.roleId === 8 ? isActive('/') : isActive('/hospital')}`} onClick={handleItemClick}>
                            <GiAmbulance className='icon' />
                            <span>Solicitudes</span>
                        </li>
                    </Link>
                </ul>
            )}


            {(currentUser.roleId === 6) && (
                <ul className='sidebar-list'>
                    <Link to="/comisiones" className='link'>
                        <li className={`sidebar-list-item ${currentUser.roleId === 8 ? isActive('/') : isActive('/comisiones')}`} onClick={handleItemClick}>
                            <FaChartPie className='icon' />
                            <span>Comisiones</span>
                        </li>
                    </Link>
                </ul>
            )}

        </aside>
    );
};

export default Sidebar
