import moment from 'moment';
import React, { forwardRef } from 'react'

interface CustomDateInputProps {
    value?: string;
    onClick?: () => void;
    startDate?: Date | null;
    endDate?: Date | null;
}

const CustomDateInput: React.ForwardRefRenderFunction<HTMLInputElement, CustomDateInputProps> = (
    { value, onClick, startDate, endDate },
    ref
) => (
    <input
        ref={ref}
        type="text"
        onClick={onClick}
        value={
            startDate && endDate
                ? `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
                : value || 'Seleccionar intervalo'
        }
        readOnly
    />
);

export default forwardRef(CustomDateInput);

