import React, { useState } from 'react';
import "./Amend.scss";
import Modal from '../../utilities/Modal';
import { LiaTimesSolid } from "react-icons/lia";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

const Amend = ({ editModal, setEditModal, rowdata }: any) => {
    const [reason, setReason] = useState({ reason: "" });
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found.');
                return;
            }

            const response = await fetch(`${apiUrl}/accountant/amends/${rowdata.guid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify(reason),
            });

            if (!response.ok) {
                toast.error('Error al rectificar la factura');
                return;
            }

            setReason({ reason: "" });
            setEditModal(false);
            navigate('/rectificativas');
            toast.success("La factura se ha rectificado correctamente")


        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="rectificar">
                <div className="rheader">
                    <h2>Número de Serie: <span>{rowdata.invoiceName}</span></h2>
                    <button onClick={() => setEditModal(false)} className="close-modal">
                        <LiaTimesSolid />
                    </button>
                </div>

                <div className="invoicedata">
                    <div className="group">
                        <span className="subgroup">
                            <label htmlFor="Fclient">Cliente:</label>
                            <input type="text" value={`${rowdata.UserName}`} readOnly />
                        </span>
                        <span className="subgroup">
                            <label htmlFor="Fdate">Fecha:</label>
                            <input type="text" value={moment(rowdata.createAt).utc().format('DD/MM/YYYY hh:mm:ss')} readOnly />
                        </span>
                    </div>

                    <div className="group">
                        <span className="subgroup">
                            <label htmlFor="Fservice">Servicios:</label>
                            <input type="text" value={rowdata.services.map((service: any) => service.Service).join(", ")} readOnly />
                        </span>
                        <span className="subgroup">
                            <label htmlFor="Famount">Importe:</label>
                            <input type="text" value={rowdata.amount} readOnly />
                        </span>
                    </div>
                </div>

                <div className="justification">
                    <div className="formform">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="reason">Motivo</label>
                            <textarea
                                onChange={(e) => setReason({ ...reason, reason: e.target.value })}
                                value={reason.reason}
                                placeholder="Observaciones (aquí se pueden añadir los motivos que justifican la rectificación de la factura)"
                                rows={4}
                                className="textboxrectify"
                                required
                            />
                            <button type="submit" className="rect-button">
                                Rectificar Factura
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Amend;
