import React, { useState } from 'react';
import "./Login.scss";
import { FaLock, FaUser, FaUserSecret } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { User } from '../context/Context';
import { toast } from 'react-toastify';
import medicalservice from '../assets/medical-service.svg';
import dr2ulogo from '../assets/dr2u-logo.png';
import Loading from '../components/loading/Loading';
import { IoChevronBackCircleOutline } from 'react-icons/io5';

const Login = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [form, setForm] = useState({
        email: "",
        password: "",
    })
    const [needVerify, setNeedVerify] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const navigate = useNavigate();
    const { setCurrentUser } = User();
    const [loading, setLoading] = useState(false);

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(form),
            });
            const data = await response.json();

            if (response.ok) {
                // Asumiendo que la API devuelve un token
                const token = data.access_token;
                // Guarda el token en local storage
                localStorage.setItem('token', token);

                const result = await fetch(`${apiUrl}/profile/me`, {
                    method: "GET",
                    headers: {
                        'Authorization': token,
                    }
                });
                const jsonData = await result.json();
                setCurrentUser(jsonData);
                navigate('/');
            }
            if (response.status === 307) {
                setNeedVerify(true);
                toast.error('Verifique el código enviado a su correo');
                return;
            }
            if (response.status === 401) {
                toast.error('Indique una dirección de correo registrada');
                return;
            }
        } catch (error) {
            console.error('Error al iniciar de sesion:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleVerification = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(`${apiUrl}/auth/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: verificationCode }),
            });

            if (response.status !== 308) {
                toast.error("Código incorrecto, compruebe de nuevo");
            } else if (response.status === 308) {
                setVerificationCode("");

                const response = await fetch(`${apiUrl}/auth/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',

                    },
                    body: JSON.stringify(form),
                });
                const data = await response.json();
                if (response.ok) {
                    const token = data.access_token;
                    localStorage.setItem('token', token);
                }
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No hay token guardado.');
                    return;
                }
                const result = await fetch(`${apiUrl}/profile/me`, {
                    method: "GET",
                    headers: {
                        'Authorization': token,
                    }
                });
                const jsonData = await result.json();
                setCurrentUser(jsonData);
                navigate("/");
                toast.success("Verificación completada con éxito");
                setNeedVerify(false);
            }

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    // ------------ Funcion para reenviar codigo OTP ---------------
    const [isDisabled, setIsDisabled] = useState(false);
    const [timer, setTimer] = useState(30);
    const handleResend = async (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        setLoading(true);
        setIsDisabled(true);

        try {
            const response = await fetch(`${apiUrl}/auth/verify/resend`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: form.email }),
            });

            if (response.ok) toast.success("Se ha vuelto a enviar un nuevo código");
            if (!response.ok) toast.error("Fallo al enviar el codigo. Verifica su correo");
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
            startTimer();
        }
    }

    const startTimer = () => {
        let countdown = 30;
        setTimer(countdown);
        const intervalId = setInterval(() => {
            countdown -= 1;
            setTimer(countdown);
            if (countdown <= 0) {
                clearInterval(intervalId);
                setIsDisabled(false);
            }
        }, 1000);
    }

    return (
        <>
            {loading && <Loading />}
            <div className="login-container">
                <div className="forms-container">
                    <div className="signin-signup">
                        {!needVerify ? (
                            <div className='formform'>
                                <form onSubmit={handleLogin} className="sign-in-form">
                                    <img className='applogo' src={dr2ulogo} alt="app-logo" />
                                    <h2 className="title">Inicia sesion</h2>
                                    <div className="input-field">
                                        <i className="fas fa-user"><FaUser /></i>
                                        <input value={form.email} onChange={(e: any) => setForm({ ...form, email: e.target.value })} type="text" placeholder="Correo" required />
                                    </div>
                                    <div className="input-field">
                                        <i className="fas fa-lock"><FaLock /></i>
                                        <input value={form.password} onChange={(e: any) => setForm({ ...form, password: e.target.value })} type="password" placeholder="Contraseña" required />
                                    </div>
                                    <input type="submit" value="Entrar" className="btn solid" />
                                </form>
                            </div>
                        ) : (
                            <div className='formform'>
                                <form onSubmit={handleVerification} className="sign-in-form">
                                    <IoChevronBackCircleOutline className='backverify' onClick={() => setNeedVerify(false)} />
                                    <img className='applogo' src={dr2ulogo} alt="app-logo" />
                                    <h2 className="title">Verifique su identidad</h2>
                                    <div className="input-field">
                                        <i className="fas fa-user"><FaUserSecret /></i>
                                        <input
                                            value={verificationCode}
                                            onChange={(e: any) => setVerificationCode(e.target.value)}
                                            type="text"
                                            placeholder="Código de verificación"
                                            required
                                        />
                                    </div>
                                    <div style={{ display: 'flex', gap: '1.2rem' }}>
                                        <input
                                            type="button"
                                            onClick={handleResend}
                                            value={isDisabled ? `Reenviar (${timer})` : 'Reenviar'}
                                            className="btn solid"
                                            disabled={isDisabled}
                                            style={{ backgroundColor: isDisabled ? 'gray' : '' }}
                                        />
                                        <input type="submit" value="Verificar" className="btn solid" />
                                    </div>
                                </form>
                            </div>
                        )}

                    </div>
                </div>
            </div>

            <div className="panels-container">
                <div className="panel left-panel">
                    <div className="content">
                        <h2>¡Bienvenido a DR2U!</h2>
                        <p>
                            Gestión Centralizada y Eficiente para Todos tus Usuarios con Dr2u
                        </p>

                    </div>
                    <img src={medicalservice} className="image" alt="" />
                </div>
            </div>
        </>


    )
}

export default Login
