import React from 'react'
import Modal from '../../utilities/Modal'
import { BsExclamationCircle } from 'react-icons/bs'

const RemoveMac = ({ editModal, setEditModal, rowdata }: any) => {
    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="popup-delete">
                <div className="popup-delete-message">
                    <BsExclamationCircle className='popup-exclamation' />
                    <h3 >¿Eliminar esta MAC para este usuario?</h3>
                </div>
                <div className='popup-delete-buttons'>
                    <button type="button" className="confirm-button">Confirmar</button>
                    <button type="button" className="cancel-button">Cancelar</button>
                </div>
            </div>
        </Modal>
    )
}

export default RemoveMac
