import React, { useEffect, useState } from 'react';
import "./TransferMedic.scss";
import DataTable from 'react-data-table-component';
import Modal from '../../utilities/Modal';
import { LiaTimesSolid } from 'react-icons/lia';
import useFetch from '../../hooks/useFetch';
import { BsSearch } from 'react-icons/bs';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const TransferMedic = ({ editModal, setEditModal, rowdata, refetch }: any) => {
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState([]);
    const [resetPaginationToggle] = useState(false);

    const [records, setRecords] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: allmedics } = useFetch(`${apiUrl}/medicalcenter/findDoctorsAddZone/${rowdata.zoneId}`);

    useEffect(() => {
        setRecords(allmedics);
    }, [allmedics]);


    const columns = [
        {
            name: "MÉDICO",
            selector: (row: any) => row.name,
            sortable: true,
            wrap: true
        },
        {
            name: 'ESTADO',
            selector: (row: any) => row.status,
            sortable: true,
            cell: (row: any) => (
                <span style={{ color: row.status === 'Disponible' ? '#20A2A0' : '#FF3D32' }}>
                    {row.status}
                </span>
            ),
        },
        {
            name: 'ZONA ACTUAL',
            selector: (row: any) => row.currentZone,
            sortable: true,
        },
        {
            name: 'DEMANDA',
            selector: (row: any) => row.demand,
            sortable: true,
            cell: (row: any) => (
                <span className={`demand-badge demand-${row.demand.toLowerCase()}`}>
                    {row.demand}
                </span>
            ),
        },
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const handleRowSelected = (state: any) => {
        setSelectedRows(state.selectedRows);
    };

    const handleTransfer = async () => {
        const doctorIds = selectedRows.map((row: any) => row.doctorid);
        const zoneIds = [rowdata.zoneId];
        const token = localStorage.getItem('token');

        try {
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const response = await fetch(`${apiUrl}/medicalcenter/zones/doctors`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    doctors: doctorIds,
                    zones: zoneIds,
                }),
            });

            if (response.ok) {
                setEditModal(false);
                navigate('/zonas');
                refetch();
                toast.success('Médicos asignados con éxito');
                return;
            } else {
                toast.error('Fallo al asignar médicos');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleFilter = (event: any) => {
        const newData = allmedics.filter((row: any) => {
            return (row.name + " " + row.currentZone + " " + row.status + " " + row.demand).toLowerCase().includes(event.target.value.toLowerCase());
        })
        setRecords(newData);
    }

    const [selectedStatus, setSelectedStatus] = useState({ value: "Todos", label: "Estado" });
    const [selectedZone, setSelectedZone] = useState({ value: "Todos", label: "Zona" });
    const [selectedDemand, setSelectedDemand] = useState({ value: "Todos", label: "Demanda" });

    const statusOptions = [
        { value: "Todos", label: "Estado" },
        { value: "Disponible", label: "Disponible" },
        { value: "En consulta", label: "En consulta" }
    ];

    const zoneOptions = [
        { value: "Todos", label: "Zona" },
        { value: "Arguineguin", label: "Arguineguin" },
        { value: "Bahia Feliz", label: "Bahia Feliz" },
        { value: "Meloneras", label: "Meloneras" },
        { value: "Puerto Rico", label: "Puerto Rico" },
        { value: "San Agustín", label: "San Agustín" },
        { value: "Sin asignar", label: "Sin asignar" },
        { value: "Taurito", label: "Taurito" }
    ];

    const demandOptions = [
        { value: "Todos", label: "Demanda" },
        { value: "ALTA", label: "ALTA" },
        { value: "MEDIA", label: "MEDIA" },
        { value: "BAJA", label: "BAJA" }
    ];

    const handleSelectStatus = (selectedOption: any) => {
        setSelectedStatus(selectedOption);
        setSelectedZone({ value: "Todos", label: "Zona" });
        setSelectedDemand({ value: "Todos", label: "Demanda" });

        if (selectedOption.value === "Todos") {
            setRecords(allmedics);
        } else {
            setRecords(allmedics.filter((record: any) => record.status === selectedOption.value));
        }
    };

    const handleSelectZone = (selectedOption: any) => {
        setSelectedZone(selectedOption);
        setSelectedStatus({ value: "Todos", label: "Estado" });
        setSelectedDemand({ value: "Todos", label: "Demanda" });

        if (selectedOption.value === "Todos") {
            setRecords(allmedics);
        } else {
            setRecords(allmedics.filter((record: any) => record.currentZone === selectedOption.value));
        }
    };

    const handleSelectDemand = (selectedOption: any) => {
        setSelectedDemand(selectedOption);
        setSelectedStatus({ value: "Todos", label: "Estado" });
        setSelectedZone({ value: "Todos", label: "Zona" });

        if (selectedOption.value === "Todos") {
            setRecords(allmedics);
        } else {
            setRecords(allmedics.filter((record: any) => record.demand === selectedOption.value));
        }
    };

    const customStyles = {
        control: (base: any) => ({
            ...base,
            border: '1px solid #187A78',
            color: '#187A78',
            outline: 'none',
            borderRadius: '8px',
            padding: '2px 10px',
            height: '45px',
            backgroundColor: '#F8F8F8',
        }),
    }

    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="transfer-doctors">
                <div className='transfer-headers'>
                    <h2>Asignar médicos a <span>{rowdata.zoneName}</span></h2>
                    <button onClick={() => setEditModal(false)} className='close-modal'>
                        <LiaTimesSolid />
                    </button>
                </div>
                <div className='transferfilter-medics'>
                    <div className='inputfitler-details'>
                        <BsSearch className='zone_search_icon' />
                        <input type="text" placeholder='Buscar...' onChange={handleFilter} />
                    </div>

                    <div className='selectstatus-medics'>
                        <Select
                            name="selectstatus"
                            id="selectstatus"
                            value={selectedStatus}
                            onChange={handleSelectStatus}
                            options={statusOptions}
                            styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                        <Select
                            name="selectstatus"
                            id="selectstatus2"
                            value={selectedZone}
                            onChange={handleSelectZone}
                            options={zoneOptions}
                            styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                        <Select
                            name="selectstatus"
                            id="selectstatus3"
                            value={selectedDemand}
                            onChange={handleSelectDemand}
                            options={demandOptions}
                            styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={records}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    selectableRows
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={resetPaginationToggle}
                    noDataComponent="No hay ningún dato que mostrar"
                    className="custom-table-transfer"
                />
                <div className="zonedetails-actions">
                    <button className="cancel-button" onClick={() => setEditModal(false)}>Cancelar</button>
                    <button className="transfer-button" onClick={handleTransfer}>Transferir</button>
                </div>
            </div>
        </Modal>

    );
};

export default TransferMedic
