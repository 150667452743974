import React, { useEffect, useState } from 'react';
import "./Summary.scss";
import { TbFileInvoice, TbReceiptTax, TbPigMoney, TbMoneybag } from 'react-icons/tb';
import Fchart from '../components/statistics/Fchart';
import useFetch from '../hooks/useFetch';
// import { LiaTimesSolid } from 'react-icons/lia';
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import CustomDateInput from '../utilities/CustomDateInput';
import { es } from 'date-fns/locale/es';
import { User } from '../context/Context';
// import { Link } from 'react-router-dom';
import Navbar from '../components/nav/Navbar';
import Sidebar from '../components/nav/Sidebar';
registerLocale('es', es);

const Summary = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(true)
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data } = useFetch(`${apiUrl}/accountant/invoice`);

    const [records, setRecords] = useState([]); // New state for filtered data

    useEffect(() => {
        setRecords(data); // Initialize state with the fetched data
    }, [data]);

    const { startDate, setStartDate, endDate, setEndDate } = User();

    useEffect(() => {
        if (data) {
            // Filtrar facturas dentro del intervalo de fechas especificado
            const filteredInvoices = data.filter((invoice: any) => !invoice.rectified).filter((invoice: any) => {
                const invoiceDate = new Date(invoice.createAt);
                return invoiceDate >= startDate && invoiceDate <= endDate;
            });

            // Actualizar los datos filtrados
            setRecords(filteredInvoices);
        }
    }, [startDate, endDate, data]);

    console.log(records)

    const cards = [
        {
            title: "Total Generadas",
            value: `${records.filter((record: any) => record.status !== 2).length} fra.`,
            icon: <TbFileInvoice />,
            // addon: `Método: ${records.filter((record: any) => record.status !== 2).filter((record: any) => record.paymentMethod === "Tarjeta").length} tarjetas y ${records.filter((record: any) => record.status !== 2).filter((record: any) => record.paymentMethod === "Efectivo").length} efectivos`,
        },
        {
            title: "Total Facturado", // Calcula la suma del importe total (pendiente y pagado)
            value: `${records.filter((record: any) => (record.status === 1 || record.status === 0)).reduce((total, record: any) => total + parseFloat(record.amount), 0)}€`,
            icon: <TbMoneybag />,
            // addon: `${records.filter((record: any) => record.status === 0).reduce((total, record: any) => total + parseFloat(record.amount), 0)}€ (pendiente) y 
            //         ${records.filter((record: any) => record.status === 1).reduce((total, record: any) => total + parseFloat(record.amount), 0)}€ (pagado)`,
        },
        {
            title: "Impuestos IGIC",
            value: `${records.filter((record: any) => record.status !== 2).reduce((total, record: any) => total + parseFloat(record.services.map((service: any) => service.price * service.tax / 100)), 0).toFixed(2)}€`,
            icon: <TbReceiptTax />,
            // addon: `${records.filter((record: any) => record.status !== 2).length} facturas = ${records.map((record: any) => parseInt(record.amt_comission)).join('+')}`
        },
        // {
        //     title: "Total Comisiones",
        //     value: `${records.filter((record: any) => record.status !== 2).reduce((total, record: any) => total + parseFloat(record.amt_comission), 0).toFixed(2)}€`,
        //     icon: <TbReceiptTax />,
        //     // addon: `${records.filter((record: any) => record.status !== 2).length} facturas = ${records.map((record: any) => parseInt(record.amt_comission)).join('+')}`
        // },
        {
            title: "Total Cobrado (dr2u)", // Calcula la suma solo de los importes pagados
            value: `${records.filter((record: any) => record.status !== 2).reduce((total, record: any) => total + parseFloat(record.amt_received), 0).toFixed(2)}€`,
            icon: <TbPigMoney />,
            addon: "Tras quitar las comisiones",
        },
    ];

    const [selectionComplete, toggleSelectionComplete] = useState(false);

    const handleDateChange = (date: Date | null) => {
        if (!selectionComplete && !startDate) {
            setStartDate(date);
            return;
        }

        if (!selectionComplete && startDate && !endDate) {
            setEndDate(date);
            toggleSelectionComplete(true);

            // Perform your filtering logic here
            const sd = moment(startDate).format('YYYY-MM-DD');
            const ed = moment(date).format('YYYY-MM-DD');

            const filtered = data.filter((record: any) => {
                const recordDate = moment(record.createdAt).format('YYYY-MM-DD');
                return recordDate >= sd && recordDate <= ed;
            });
            setRecords(filtered);
            return;
        }

        if (selectionComplete && startDate && endDate) {
            setStartDate(date);
            setEndDate(undefined);
            toggleSelectionComplete(false);
            return;
        }
    };

    const handleSelectDate = (date: Date | null) => {
        if (date && !selectionComplete && startDate && !endDate && sameDay(date, startDate)) {
            handleDateChange(date);
        }
    };

    const handleShortcutChange = (value: string) => {
        const today = moment();
        let start: moment.Moment | null = null;
        let end: moment.Moment | null = null;

        switch (value) {
            case 'today':
                start = today.startOf('day');
                end = today.endOf('day');
                break;
            case 'week':
                start = today.clone().startOf('week').add(1, 'day');
                end = today.clone().endOf('week').add(1, 'day');
                break;
            case 'month':
                start = today.clone().startOf('month');
                end = today.clone().endOf('month');
                break;
            case 'year':
                start = today.clone().startOf('year');
                end = today.clone().endOf('year');
                break;
            default:
                break;
        }

        if (start && end) {
            setStartDate(start.toDate());
            setEndDate(end.toDate());
            toggleSelectionComplete(true);

            const sd = start.format('YYYY-MM-DD');
            const ed = end.format('YYYY-MM-DD');

            const filtered = data.filter((record: any) => {
                const recordDate = moment(record.createdAt).format('YYYY-MM-DD');
                return recordDate >= sd && recordDate <= ed;
            });
            setRecords(filtered);
        }
    };

    const sameDay = (d1: Date, d2: Date) => {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    };
    const isSameRange = (start: Date, end: Date, rangeStart: moment.Moment, rangeEnd: moment.Moment) => {
        return moment(start).isSame(rangeStart, 'day') && moment(end).isSame(rangeEnd, 'day');
    };

    return (
        <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>
            <Navbar OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className='resumen'>
                <div className='month-filter'>
                    <div>
                        <span>Intervalo de fechas: </span>
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            onSelect={handleSelectDate}
                            selectsEnd={Boolean(startDate)}
                            startDate={startDate}
                            endDate={endDate}
                            shouldCloseOnSelect={false}
                            dateFormat="dd/MM/yyyy"
                            locale="es"
                            customInput={<CustomDateInput startDate={startDate} endDate={endDate} />}
                        >
                            <div className="date-range">
                                {startDate ? moment(startDate).format('DD/MM/YYYY') : '??/??/????'} - {endDate ? moment(endDate).format('DD/MM/YYYY') : '??/??/????'}

                            </div>
                            <div className='datepicker-menu'>
                                <p>Atajo de fechas:</p>
                                <div className='date-option'>
                                    <input type="radio" id="today" name="date-option" value="Hoy" onChange={() => handleShortcutChange('today')} checked={startDate && moment(startDate).isSame(moment(), 'day')} />
                                    <label htmlFor="today">Hoy</label>

                                    <input type="radio" id="week" name="date-option" value="Semana" onChange={() => handleShortcutChange('week')} checked={startDate && endDate && isSameRange(startDate, endDate, moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day'))} />
                                    <label htmlFor="week">Semana</label>

                                    <input type="radio" id="month" name="date-option" value="Mes" onChange={() => handleShortcutChange('month')} checked={startDate && endDate && isSameRange(startDate, endDate, moment().startOf('month'), moment().endOf('month'))} />
                                    <label htmlFor="month">Mes</label>

                                    <input type="radio" id="year" name="date-option" value="Año" onChange={() => handleShortcutChange('year')} checked={startDate && endDate && isSameRange(startDate, endDate, moment().startOf('year'), moment().endOf('year'))} />
                                    <label htmlFor="year">Año</label>
                                </div>
                            </div>
                        </DatePicker>
                    </div>
                </div>

                <div className='main-cards'>
                    {cards.map((card) => (
                        <div className='kpi' key={card.title}>
                            <span className='kpi-title'>{card.title}</span>
                            <span className='kpi-value'>{card.value}</span>
                            <span className='kpi-addon'>{card.addon}</span>
                            <div className='kpi-icon'>{card.icon}</div>
                        </div>
                    ))}
                </div>
                <Fchart />
            </div>
        </div>
    )
}

export default Summary
